/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import { message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaArrowLeft, FaCircleCheck } from "react-icons/fa6";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactCodeInput from 'react-verification-code-input';

import {
    Card,
    Container,
    Form
} from "reactstrap";
import { createUser, forgotPassword, loginUserPhone, sendCode, verifyForgotEmail, verifyPhoneLogin } from "../api/signup";
import { cleaninglogo } from "../icons/icon";
import { setAccessToken, setCode, setLogin, setUserData, setUserType } from "../redux/loginForm";

const Verification = () => {
    const { state } = useLocation()
    const [verifyPhone, setverifyPhone] = useState(state?.verifyPhone || null)
    const [forgotData, setforgotData] = useState(state?.forgotData || null)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.auth?.userData)
    const usertype = useSelector((state) => state.auth?.userType)
    const verifyCode = useSelector((state) => state.auth?.code)
    const [modalOpen, setmodalOpen] = useState(false)
    const [timer, setTimer] = useState(50);
    const [showResendLink, setShowResendLink] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            setShowResendLink(true);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    const handleResendCode = async () => {
        if (forgotData) {
            await forgotPassword(forgotData?.phone)
                .then((res) => {
                    if (res.data?.success === true) {
                        message.success('Code sent successfully')
                        setforgotData({
                            ...forgotData,
                            token: res.data.token,
                            verificationCode: res.data.verificationCode
                        })
                        setTimer(50);
                        setShowResendLink(false);
                    }
                }).catch((err) => {
                    message.error(err.response.data.message);
                })
        } else if (verifyPhone) {
            const data = {
                phone: verifyPhone?.phone,
                type: verifyPhone?.type
            }
            await loginUserPhone({ data: data })
                .then((res) => {
                    if (res.success === true) {
                        message.success('Code sent successfully')
                        const updatedRes = {
                            ...res,
                            token: res.token,
                            verificationCode: res.verificationCode
                        };
                        setverifyPhone(prev => ({
                            ...prev,
                            res: updatedRes,
                        }));
                        setTimer(50);
                        setShowResendLink(false);
                    }
                }).catch((err) => {
                    message.error(err.response.data.message);
                })
        } else {
            await sendCode(userData?.phone).then((res) => {
                if (res.status === 200) {
                    message.success(res.data?.message)
                    dispatch(setCode(res.data?.verificationCode))
                    setTimer(50);
                    setShowResendLink(false);
                }
            }).catch((err) => {
                if (err.status === 409) {
                    message.error('Phone already registered');
                } else {
                    message.error(err.message);
                }
            })
        }
    }

    const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        if (verifyPhone) {
            setIsLoading(true)
            await verifyPhoneLogin(value, verifyPhone?.res?.token)
                .then((res) => {
                    if (res.data.success === true) {
                        dispatch(setLogin(true));
                        dispatch(setUserData(res.data?.user));
                        dispatch(setAccessToken(res.data?.token));
                        navigate('/')
                        message.success('Code Verified Successfully');
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                        message.error('Invalid verification code');
                    }
                }).catch((err) => {
                    setIsLoading(false)
                    message.error("Failed to verify code. Please try again later.");
                })
        } else if (forgotData) {
            if (forgotData.verificationCode === value) {
                setIsLoading(true)
                await verifyForgotEmail(forgotData?.verificationCode, forgotData?.token)
                    .then((res) => {
                        if (res.data.success === true) {
                            navigate('/reset-password', { state: { forgotData: forgotData } })
                            message.success(res.data.message);
                            setIsLoading(false)
                        } else {
                            setIsLoading(false)
                            message.error('Invalid verification code');
                        }
                    }).catch((err) => {
                        setIsLoading(false)
                        message.error("Failed to verify code. Please try again later.");
                    })
            } else {
                message.error("Invalid verification code");
                setIsLoading(false)
                return;
            }
        } else {
            setIsLoading(true)
            const data = {
                ...userData,
                code: value
            };
            await createUser({ data: data }, usertype)
                .then((res) => {
                    if (res.success === true) {
                        if (usertype === 'company') {
                            navigate('/pricing-info')
                        } else {
                            navigate('/personal-info')
                        }
                        dispatch(setUserData(res?.user))
                        dispatch(setUserType(res?.user?.type))
                        dispatch(setAccessToken(res?.token))
                        message.success("Sign Up successful!");
                        setIsLoading(false)
                    }
                }).catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    message.error("Failed to sign up. Please try again later.");
                })
        }
    };

    const handleConfirm = () => {
        setTimeout(() => {
            setIsLoading(false);
            dispatch(setLogin(true))
            navigate("/");
        }, 2000);
    }

    return (
        <React.Fragment>
            <Container fluid style={{ backgroundColor: '#F5F5F5', paddingTop: "5rem", paddingBottom: "1rem" }} className="flex justify-center min-vh-100 items-center w-full">
                <Card className="bg_white rounded-4 max-w-3xl relative d-flex align-items-center flex-column pt-3 pb-5 max-lg:px-8 lg:px-20">
                    <div className="absolute top-10" style={{ left: '25px', }}>
                        <Link to={-1}>
                            <div style={{ width: '50px', height: "50px" }} className="bg_light flex justify-center items-center text_primary rounded-5">
                                <FaArrowLeft size={24} />
                            </div>
                        </Link>
                    </div>
                    <img src={cleaninglogo} style={{ height: '150px', width: 'auto' }} alt="Cleaning Logo" />
                    <h1 className="plusJakara_semibold text-3xl lg:text-4xl text-center text_primary">Verification Code</h1>
                    <h6 className="text_secondary2 max-w-lg plusJakara_regular text-center">
                        We just sent you a verification code. Check your inbox to retrieve it.
                    </h6>
                    <Form
                        style={{ maxWidth: '600px', width: '100%' }}
                        onSubmit={handleVerificationSubmit}
                        className="mt-4 auth_form"
                    >
                        <div className="my-4 flex justify-center">
                            <ReactCodeInput
                                fields={6}
                                value={value}
                                onChange={(e) => setValue(e)}
                                className="w-100"
                            />
                        </div>

                        <div className="login_main p-4">
                            {!showResendLink &&
                                <h6 className="resend_buton text_secondary2 text-center">Resend Code in <span className="text_dark inter_medium"> {timer}s</span></h6>}
                            <div className="mt-3">
                                {showResendLink ? (
                                    <h6 className="counter_con text-center flex-wrap">
                                        Didn't get a code?
                                        <span
                                            className="text_primary2 underline ml-md-2 inter_medium"
                                            onClick={handleResendCode}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            Resend Code
                                        </span>
                                    </h6>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <div className="d-grid">
                            <button
                                className="py-3 rounded-5 bg_primary plusJakara_medium text_white"
                                disabled={isLoading}
                                type="submit"
                            >
                                {isLoading ? <Spinner size="sm" /> : "Continue"}
                            </button>
                        </div>
                    </Form>
                </Card>
            </Container>

            <Modal
                centered
                open={modalOpen}
                footer={null}
                closeIcon={false}
                width={400}
                onCancel={() => setmodalOpen(false)}
            >
                <div className="flex w-full gap-4 items-center flex-col">
                    <FaCircleCheck color="#00C566" className="mb-3" size={80} />
                    <h4 className="text_black text-center mb-0 plusJakara_semibold">Registration <br /> Successful!</h4>
                    <span className="text_secondary2 text-center plusJakara_regular">Add more details to help customers know you better.</span>
                    <button
                        onClick={handleConfirm}
                        className="py-3 rounded-5 w-full bg_primary plusJakara_medium text_white"
                        disabled={isLoading}
                    >
                        {isLoading ? <Spinner size="sm" /> : "Continue"}
                    </button>
                </div>
            </Modal>

        </React.Fragment>
    );
};

export default Verification;
