import { message, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa6';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
import moment from 'moment';
import { createOrder } from '../api/order';
import { CircularProgress } from '@mui/material';

const BookService = () => {
    const { state } = useLocation();
    const serviceDetail = state?.serviceDetail || null;
    const navigate = useNavigate();
    const [selectedDates, setSelectedDates] = useState([]);
    const [highlightedDate, setHighlightedDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const usertype = useSelector((state) => state.auth?.userData?.type);

    useEffect(() => {
        if (serviceDetail?.availablity) {
            const dates = serviceDetail.availablity.map(dateString => moment(dateString).toDate());
            setSelectedDates(dates);
        }
    }, [serviceDetail]);

    const handleDateSelect = (date) => {
        setHighlightedDate(date);
    };

    const customDayClassName = (date) => {
        const isAvailable = selectedDates.some(d => moment(d).isSame(date, 'day'));
        const isHighlighted = moment(date).isSame(highlightedDate, 'day');
        if (isHighlighted) {
            return 'react-datepicker__day--highlighted';
        }
        return isAvailable ? '' : 'react-datepicker__day--disabled';
    };

    const handleSubmit = async (values) => {
        const data = {
            bookingDate: new Date(highlightedDate).toISOString(),
            service: serviceDetail._id,
            type: 'order',
            to_id: serviceDetail?.user._id
        }
        setIsLoading(true)
        await createOrder({ data: data }).then((res) => {
            if (res.success === true) {
                message.success("Service booked successfully");
                setIsLoading(false)
                navigate('/orders');
            } else {
                message.error("Failed to create Order");
                setIsLoading(false)
            }
        }).catch((err) => {
            console.error("Error creating Order:", err);
            setIsLoading(false)
        })
    }

    return (
        <main className='pb-5 w-full'>
            {usertype !== 'company' &&
                <HeaderNavigation subPage='Book Order' subPageAvail={true} page={serviceDetail?.name} serviceName={`${serviceDetail?.name}`} />
            }
            <Container className='p-4 mt-4 bg_white rounded-4 w-full'>
                {usertype === 'company' &&
                    <div className="flex justify-between w-full">
                        <div className="flex gap-4 mb-5 items-center w-full">
                            <button
                                onClick={() => navigate(-1)}
                                className="bg_primary rounded-3 p-2"
                            >
                                <FaArrowLeft className='text_white' />
                            </button>
                            <h4 className="text_primary mb-0 plusJakara_semibold">Service Detail</h4>
                        </div>
                    </div>}
                <Row gutter={16} className='items-center flex-col gap-4 justify-center my-5'>
                    <DatePicker
                        selected={highlightedDate}
                        onChange={handleDateSelect}
                        includeDates={selectedDates}
                        inline
                        dayClassName={customDayClassName}
                        className="bg-gray-800 rounded-md"
                        calendarClassName="bg-gray-800 text-white"
                    />
                    {highlightedDate &&
                        <button disabled={isLoading} onClick={handleSubmit} style={{ padding: '16px 4rem', maxWidth: '500px', width: '100%' }} className="bg_primary text_white rounded-3 plusJakara_medium">{isLoading ? <CircularProgress size={16} style={{ color: 'white' }} /> : 'Book Now'}</button>}
                </Row>
            </Container>
        </main>
    );
};

export default BookService;