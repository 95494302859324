/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import { message } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import {
    Card,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row
} from "reactstrap";
import * as Yup from "yup";
import { loginUser, loginUserPhone } from "../api/signup";
import { cleaninglogo, continuelogo, googlelogo } from "../icons/icon";
import { setAccessToken, setLogin, setUserData } from "../redux/loginForm";

const LoginPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [phone, setPhone] = useState("1");
    const [isLoading, setisLoading] = useState(false)
    const [activeTab, setActiveTab] = useState("phone");
    const usertype = useSelector((state) => state.auth?.userType)
    const isMobileLogin = useSelector((state) => state.auth?.isMobileLogin)
    const [showPassword, setShowPassword] = useState(false);

    const validationSchema = Yup.object({
        phone: Yup.lazy((value, context) =>
            context.parent.activeTabs === "phone"
                ? Yup.string()
                    .required("Phone number is required")
                    .matches(/^\+?[0-9]{10,15}$/, "Phone number is not valid")
                    .min(10, "Phone number must be at least 10 digits")
                    .max(15, "Phone number must be at most 15 digits")
                : Yup.string().notRequired()
        ),
        email: Yup.lazy((value, context) =>
            context.parent.activeTabs === "email"
                ? Yup.string()
                    .required("Email is required")
                    .email("Enter a valid email")
                : Yup.string().notRequired()
        ),
        password: Yup.lazy((value, context) =>
            context.parent.activeTabs === "email"
                ? Yup.string()
                    .required("Please Enter Password")
                    .min(8, 'Password must be at least 8 characters')
                    .max(15, 'Maximum 15 characters allowed')
                : Yup.string().notRequired()
        ),
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            phone: "",
            password: "",
            activeTabs: "phone",
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoading(true)
            const data = {
                email: values.email,
                password: values.password,
                type: usertype,
            }
            const data2 = {
                phone: "+" + values.phone,
                type: usertype,
            }
            if (values.activeTabs === 'phone') {
                await loginUserPhone({ data: data2 })
                    .then((res) => {
                        if (res.success === true) {
                            setisLoading(false)
                            resetForm()
                            message.success('Verification Code sent successfully')
                            navigate('/code-verify', {
                                state: {
                                    verifyPhone: { ...data2, res },
                                }
                            });
                        }
                    }).catch((err) => {
                        setisLoading(false)
                        message.error('Invalid credientials')
                    })
            } else {
                await loginUser({ data: data })
                    .then((res) => {
                        if (res.success === true) {
                            setisLoading(false)
                            if (isMobileLogin) {
                                navigate('/business-profile?selected=subscription', { replace: true })
                            } else if (usertype === 'company' || usertype === 'employee') {
                                navigate('/dashboard')
                            } else {
                                navigate('/')
                            }
                            resetForm()
                            dispatch(setUserData(res.user))
                            dispatch(setAccessToken(res.token))
                            dispatch(setLogin(true))
                            message.success('Login successfuly')
                        }
                    }).catch((err) => {
                        setisLoading(false)
                        message.error(err.response.data.message)
                    })
            }
        }
    })

    const handlePhoneChange = (value) => {
        validation.setFieldValue('phone', value);
        validation.setFieldTouched('phone', true, true);
    };

    return (
        <React.Fragment >
            <Container fluid style={{ paddingTop: "6rem", paddingBottom: "2rem" }} className="flex auth_bg_img min-vh-100 justify-center items-center w-full">
                <Card className="bg_white rounded-4 max-w-xl d-flex align-items-center flex-column pt-3 pb-5 max-lg:px-8 lg:px-20">
                    <img src={cleaninglogo} style={{ height: '120px', width: 'auto' }} alt="" />
                    <h1 className="plusJakara_semibold text-2xl lg:text-3xl text-center text_primary">Hi, Welcome Back👋</h1>
                    <span className="text_secondary2 max-w-lg plusJakara_regular text-center">We're thrilled to have you here. Our platform is designed to help you manage and promote your services efficiently.  </span>
                    <Form
                        style={{ maxWidth: '600px', width: '100%' }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        className="mt-4 px-2 px-lg-0 auth_form">
                        <Row className="px-0">
                            <div className="flex w-full bg_light px-0 mb-3 justify-between rounded-5">
                                <button type="button" onClick={() => {
                                    setActiveTab('phone')
                                    validation.setFieldValue('activeTabs', 'phone')
                                }} className={`rounded-5 text-sm w-full py-3 plusJakara_medium text_secondary2 ${activeTab === 'phone' ? 'bg_primary text_white' : 'text_secondary2'} `}>Phone Number</button>
                                <button type="button" onClick={() => {
                                    setActiveTab('email')
                                    validation.setFieldValue('activeTabs', 'email')
                                }} className={`rounded-5 text-sm w-full py-3 plusJakara_medium text_secondary2 ${activeTab === 'email' ? 'bg_primary text_white' : 'text_secondary2'} `}>Email</button>
                            </div>
                            {activeTab === 'phone' && (
                                <div className="w-full mb-3 px-0">
                                    <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="phone">
                                        Phone Number
                                    </Label>
                                    <div className="d-flex tab-content w-full justify-content-center flex-column align-items-center gap-1">
                                        <PhoneInput
                                            country={"us"}
                                            placeholder="Phone Number"
                                            value={validation.values.phone}
                                            onChange={handlePhoneChange}
                                            onBlur={validation.handleBlur}
                                            className="phon_inp text_secondarydark plusJakara_medium"
                                            disableSearchIcon={true}
                                        />
                                        {validation.touched.phone && validation.errors.phone && (
                                            <FormFeedback type="invalid" className="d-block">{validation.errors.phone}</FormFeedback>
                                        )}
                                    </div>
                                </div>
                            )}
                            {activeTab === 'email' && (
                                <>
                                    <div className="mb-3 w-full px-0">
                                        <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="email">
                                            Email
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control rounded-3"
                                            name="email"
                                            id="email"
                                            style={{ padding: '12px 16px', }}
                                            placeholder="Enter your Email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.email || ""}
                                            invalid={
                                                validation.touched.email && validation.errors.email ? true : false
                                            }
                                        />
                                        {validation.touched.email && validation.errors.email && (
                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                        )}
                                    </div>
                                    <div className="mb-3 px-0 position-relative">
                                        <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="password">
                                            Password
                                        </Label>
                                        <span
                                            onClick={() => setShowPassword(!showPassword)}
                                            style={{
                                                position: 'absolute',
                                                right: '30px',
                                                top: '47px',
                                                zIndex: 99,
                                                cursor: "pointer"
                                            }}
                                        >
                                            {!showPassword ? <EyeOff color="#C4C4C4" size={20} /> : <Eye color="#C4C4C4" size={20} />}
                                        </span>
                                        <Input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control rounded-3"
                                            name="password"
                                            id="password"
                                            style={{ padding: '12px 16px', }}
                                            placeholder="Enter your password"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.password || ""}
                                            invalid={
                                                validation.touched.password && validation.errors.password ? true : false
                                            }
                                        />
                                        {validation.touched.password && validation.errors.password && (
                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                        )}
                                    </div>
                                </>
                            )}
                            <div className="flex w-full justify-end">
                                <button
                                    onClick={() => {
                                        navigate('/forgot-password')
                                    }}
                                    type="button"
                                    className="text_primary2 underline plusJakara_medium">Forgot Password</button>
                            </div>
                            <Col lg={12} className="px-0 my-3 ">
                                <div className="d-grid">
                                    <button className="py-3 rounded-5 bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                                        {isLoading ? <Spinner size="sm"></Spinner> : "Login"}
                                    </button>
                                </div>
                            </Col>
                            <div className="flex justify-center w-full gap-2">
                                <img src={continuelogo} style={{ height: "24px", width: 'auto' }} alt="" />
                            </div>
                            <div className="flex justify-center my-3 w-full gap-3">
                                <button type="button" className="flex gap-2 justify-center items-center rounded-5" style={{ padding: "12px 24px", border: '1px solid #d4d4d4' }}>
                                    <img src={googlelogo} style={{ height: "25px", width: 'auto' }} alt="" />
                                    <span className="text_secondary2 plusJakara_semibold">Continue with Google</span>
                                </button>
                            </div>
                            {/* <div className="flex justify-center w-full gap-2">
                                Or
                            </div>
                            <Col className="text-center mt-2">
                                <div className="d-flex flex-column gap-2 gap-sm-3">
                                    <div
                                        onClick={() => handleRegisterClick("customer")}
                                        className="bg_white rounded-4 border mb-0 w-100 d-flex align-items-center justify-content-between py-1 py-sm-2 px-2 px-sm-3 cursor-pointer"
                                    >
                                        <div className="d-flex align-items-center gap-2 justify-content-start">
                                            <div className="d-flex flex-column">
                                                <span className="text_dark text-sm plusJakara_regular">
                                                    Don't have an account
                                                </span>
                                                <button
                                                    type="button"
                                                    className="text-start text_primary plusJakara_semibold"
                                                >
                                                    Register here
                                                </button>
                                            </div>
                                        </div>
                                        <MdKeyboardArrowRight />
                                    </div>
                                    <div
                                        onClick={() => handleRegisterClick("provider")}
                                        className="bg_white cursor-pointer rounded-4 border w-100 d-flex align-items-center justify-content-between py-1 py-sm-2 px-2 px-sm-3"
                                    >
                                        <div className="d-flex align-items-center gap-2 justify-content-start">
                                            <div className="d-flex flex-column">
                                                <span className="text_dark text-sm plusJakara_regular">
                                                    Are you a Service Provider
                                                </span>
                                                <button
                                                    type="button"
                                                    className="text-start text_primary plusJakara_semibold"
                                                >
                                                    Join Now
                                                </button>
                                            </div>
                                        </div>
                                        <MdKeyboardArrowRight />
                                    </div>
                                </div> 
                                </Col> */}
                            <div className="flex gap-1 justify-center items-center w-full">
                                <h6 className="text_primary plusJakara_regular mb-0">You don't have an account yet?</h6>{" "}
                                <Link to="/signup" className="text_primary2 mb-0 plusJakara_medium">Sign Up</Link>
                            </div>
                        </Row>
                    </Form>
                </Card>
            </Container >
        </React.Fragment >
    );
};
export default LoginPage;
