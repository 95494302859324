/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CircularProgress } from '@mui/material';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Col, Modal, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaCircleCheck, FaStar } from 'react-icons/fa6';
import { FiPhoneCall } from 'react-icons/fi';
import { MdOutlineMailOutline } from 'react-icons/md';
import { SlLocationPin } from 'react-icons/sl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Autoplay, FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { avataruser, cleaning2, facebook, instagram, linkedin, marker } from '../icons/icon';
import axiosInstance from '../redux/axiosInstance';
import { encryptData } from '../redux/localStorageSecure';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
import ServiceItem2 from './serviceItem2';
import { getRating } from '../api/rating';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ServiceProvider = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [active, setActive] = useState('reviews');
    const { state } = useLocation()
    const navigate = useNavigate()
    const [rating, setrating] = useState([])
    const [ratingLoading, setratingLoading] = useState(false)
    const [availableDays, setavailableDays] = useState([])
    // const [portfolio, setportfolio] = useState(state?.portfolioData || null)
    const [companyServices, setCompanyServices] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef()
    const portfolio = useSelector((state) => state.generalRedux?.serviceProvider)
    const [show, setshow] = useState(false)
    const [selectedItem, setselectedItem] = useState(null)
    const [loading, setLoading] = useState(false)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
        libraries: ['places', "maps"]
    });

    useEffect(() => {
        if (portfolio) {
            // setportfolio(portfolio)
            const availableDays = portfolio?.availablity?.filter(day => day.status === true);
            setavailableDays(availableDays)
            axiosInstance.get(`service/company/${portfolio?._id}`).then((res) => {
                if (res?.data) {
                    setCompanyServices(res?.data?.services);
                    setLoading(false);
                } else {
                    setCompanyServices([]);
                    setLoading(false);
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err);
            })

        }
    }, [portfolio])
    const handleThumbnailClick = (item, index) => {
        setActiveIndex(index);
        swiperRef.current.slideTo(index);
    };
    const handleClickPortfolio = (row) => {
        setshow(true)
        setselectedItem(row)
    }

    const handleGetRating = async () => {
        setratingLoading(true)
        await getRating(portfolio?._id)
            .then((res) => {
                console.log(res);
                if (res.success === true) {
                    setratingLoading(false)
                    setrating(res?.ratings)
                } else {
                    setratingLoading(false)
                    setrating([])
                }
            }).catch((err) => {
                setratingLoading(false)
                console.log(err);

            })
    }

    const handleTab = (tab) => {
        const data = {
            selected: tab
        }
        setSearchParams(data)
    };

    useEffect(() => {
        if (active === 'reviews') {
            handleGetRating()
        }
    }, [active])

    useEffect(() => {
        if (searchParams.get('selected')) {
            setActive(searchParams.get('selected'))
        } else {
            const data = {
                selected: 'reviews'
            }
            setSearchParams(data)
        }
    }, [searchParams]);

    const handleNavigate = (item) => {
        const params = new URLSearchParams(searchParams)
        const data = {
            user: item,
        }
        params.set('detail-user', encryptData(data))
        navigate(`/chat?${params.toString()}`)
    }

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Service Provider' serviceName={portfolio?.name} />
            <Container className='mt-5 mb-4'>
                <Row gutter={16} className='justify-between'>
                    <Col xs={24} md={9} xl={8}>
                        <div style={{ backgroundColor: '#fff' }} className="flex gap-2 shadow-sm border rounded-3 p-3 flex-col">
                            <div className="flex justify-center flex-col gap-2 w-full items-center overflow-hidden relative">
                                <img src={portfolio?.profilePicture || avataruser} style={{ width: '150px', height: "150px", borderRadius: '50%', objectFit: 'cover', border: '11.57px solid #FFFFFF' }} alt="" />
                                <div className="flex gap-2 items-center">
                                    <h5 className="plusJakara_medium mb-0">{portfolio?.name}</h5>
                                    <FaCircleCheck color='#27C200' size={24} />
                                </div>
                                <div className="flex gap-1 items-center">
                                    <FaStar
                                        color={portfolio?.rating > 0 ? '#F6921E' : '#dcdcdc'}
                                        size={18}
                                        style={{
                                            background: `linear-gradient(90deg, #F6921E ${portfolio?.rating * 20}%, #dcdcdc ${portfolio?.rating * 20}%)`,
                                            WebkitBackgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent'
                                        }}
                                    />
                                    <span className="plusJakara_medium text_black">{portfolio?.rating} Star Rating</span>
                                </div>

                            </div>
                            <div className="flex gap-2 mt-2 w-full">
                                <button onClick={() => handleNavigate(portfolio)} className="bg_primary w-full text_white plusJakara_regular text-sm px-3 py-[12px] rounded-2">Message</button>
                            </div>
                            <hr style={{ color: '#EBEEF7' }} className='mb-0' />
                            <div className="flex justify-center lg:mb-2 flex-col w-full overflow-hidden relative">
                                <span className="text_secondary2 plusJakara_regular">CONTACT INFORMATION</span>
                                <div className="flex flex-col gap-3 mt-2">
                                    <div className="flex items-start gap-3">
                                        <div className="w-5">
                                            <FiPhoneCall className='text_primary2' size={20} />
                                        </div>
                                        <h6 className="text_secondary plusJakara_regular mb-0">{portfolio?.phone}</h6>
                                    </div>
                                    <div className="flex items-start gap-3">
                                        <div className="w-5">
                                            <MdOutlineMailOutline className='text_primary2' size={22} />
                                        </div>
                                        <h6 className="text_secondary plusJakara_regular mb-0">{portfolio?.email}</h6>
                                    </div>
                                    <div className="flex items-start gap-3">
                                        <div className="w-5">
                                            <SlLocationPin className='text_primary2' size={20} />
                                        </div>
                                        <h6 className="text_secondary plusJakara_regular mb-0">{portfolio?.address}</h6>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ color: '#EBEEF7' }} />
                            <div className="flex justify-center lg:mb-3 flex-col w-full overflow-hidden relative">
                                <span className="text_secondary2 plusJakara_regular">About</span>
                                <h6 className="text_secondary plusJakara_regular">{portfolio?.about}</h6>
                            </div>
                            <hr style={{ color: '#EBEEF7' }} />
                            <div className="flex justify-center lg:mb-4 flex-col items-center w-full overflow-hidden relative">
                                <span className="text_secondary2 mb-2 plusJakara_regular">Follow me on social media</span>
                                <div className="flex justify-center gap-2">
                                    <a href={portfolio?.fblink} target='__blank'>
                                        <img src={facebook} style={{ width: "38px", height: 'auto' }} alt="" />
                                    </a>
                                    <a href={portfolio?.linkedInlink} target='__blank'>
                                        <img src={linkedin} style={{ width: "38px", height: 'auto' }} alt="" />
                                    </a>
                                    <a href={portfolio?.instalink} target='__blank'>
                                        <img src={instagram} style={{ width: "38px", height: 'auto' }} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={15} xl={16}>
                        <div className="flex flex-col gap-4 w-full">
                            <div style={{ backgroundColor: '#fff' }} className="flex gap-4 items-center shadow-sm border rounded-2 p-3">
                                <div className="flex justify-center items-center" style={{ backgroundColor: "#FFF8E0", width: "80px", height: "80px", borderRadius: '5px' }}>
                                    <h3 className="text_black plusJakara_medium mb-0">{portfolio?.total_reviews}</h3>
                                </div>
                                <div className="flex flex-col items-start">
                                    <div className="flex gap-1 mb-2 items-center">
                                        {[...Array(5)].map((_, index) => (
                                            <FaStar
                                                key={index}
                                                color={index < portfolio?.rating ? '#F6921E' : '#dcdcdc'}
                                                size={20}
                                            />
                                        ))}
                                    </div>
                                    <h6 className="text_black plusJakara_medium mb-0">{portfolio?.rating} Star Average Rating</h6>
                                    <span className="text_secondary2 plusJakara_regular">{portfolio?.total_reviews} People Writed Review</span>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div style={{ backgroundColor: '#fff', maxHeight: "75vh", overflowY: 'hidden', height: '100%' }} className="flex gap-4 flex-col items-center shadow-sm border rounded-2 p-3">
                                    <div className="flex gap-3 items-center mb- w-full" style={{ borderBottom: '1px solid #EBEEF7' }}>
                                        <button onClick={() => handleTab('reviews')}
                                            style={{ borderBottom: active === 'reviews' ? '2px solid #F6921E' : '' }}
                                            className={`px-3 py-2 text-lg  plusJakara_medium ${active === 'reviews' ? 'text_primary2' : 'text_secondary2'} flex justify-center items-center gap-2`}>
                                            Reviews
                                        </button>
                                        <button onClick={() => handleTab('about')}
                                            style={{ borderBottom: active === 'about' ? '2px solid #F6921E' : '' }}
                                            className={`px-3 py-2 text-lg plusJakara_medium ${active === 'about' ? 'text_primary2' : 'text_secondary2'} flex items-center justify-center gap-2`}>
                                            About
                                        </button>
                                        <button onClick={() => handleTab('services')}
                                            style={{ borderBottom: active === 'services' ? '2px solid #F6921E' : '' }}
                                            className={`px-3 py-2 text-lg plusJakara_medium ${active === 'services' ? 'text_primary2' : 'text_secondary2'} flex items-center justify-center gap-2`}>
                                            Services
                                        </button>
                                        <button onClick={() => handleTab('portfolio')}
                                            style={{ borderBottom: active === 'portfolio' ? '2px solid #F6921E' : '' }}
                                            className={`px-3 py-2 text-lg plusJakara_medium ${active === 'portfolio' ? 'text_primary2' : 'text_secondary2'} flex items-center justify-center gap-2`}>
                                            Portfolio
                                        </button>
                                    </div>
                                    <div style={{ overflowY: 'auto' }} className="flex flex-col gap-4 items-start w-full">
                                        {active === 'reviews' &&
                                            (ratingLoading ? (
                                                <div className="flex my-4 w-full justify-center items-center">
                                                    <CircularProgress size={20} className="text_primary" />
                                                </div>
                                            ) : (
                                                <>
                                                    {!rating || rating?.length === 0 ? (
                                                        <div className="flex my-4 w-full justify-center items-center">
                                                            <span className="text_black plusJakara_medium">No Rating found</span>
                                                        </div>
                                                    ) : (
                                                        rating?.map((item, i) => (
                                                            <div key={i} className="flex items-start gap-3 border w-full rounded-4 p-3 justify-start flex-col lg:flex-row">
                                                                <div style={{ minWidth: '85px' }}>
                                                                    <img
                                                                        src={item?.user?.profilePicture || avataruser}
                                                                        style={{
                                                                            width: '80px',
                                                                            height: '80px',
                                                                            borderRadius: '50%',
                                                                            objectFit: 'cover',
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col gap-1 w-full">
                                                                    <div className="flex items-center gap-2">
                                                                        <div className="flex items-center">
                                                                            {[...Array(5)].map((_, i) => (
                                                                                <FaStar
                                                                                    key={i}
                                                                                    color={i < item?.rating ? '#F6921E' : '#d3d3d3'}
                                                                                    size={20}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center mb-2 gap-2">
                                                                        <h6 className="text_black plusJakara_medium mb-0 whitespace-nowrap">{item?.user?.name}</h6>
                                                                        <div className="flex gap-1 items-center">
                                                                            <div
                                                                                style={{
                                                                                    width: '4px',
                                                                                    height: '4px',
                                                                                    borderRadius: '50%',
                                                                                    backgroundColor: '#939AAD',
                                                                                }}
                                                                            ></div>
                                                                            <span className="text_secondary2 text-sm plusJakara_regular mb-0">Review at:</span>
                                                                        </div>
                                                                        <span className="text_secondary text-xs plusJakara_regular mb-0">
                                                                            {moment(item?.createdAt).format('MMM DD,YYYY')}
                                                                        </span>
                                                                    </div>
                                                                    <span className="text_secondary2 plusJakara_regular">
                                                                        {item?.review}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )}
                                                </>
                                            ))
                                        }
                                        {active === 'about' &&
                                            <>
                                                <span className="text_secondary2 plusJakara_regular">
                                                    {portfolio?.about} </span>
                                                <div className="flex flex-col gap-3 w-full">
                                                    <h6 className="text_primary mb-0 plusJakara_medium">Business Location</h6>
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        id="zipCode"
                                                        value={portfolio?.address}
                                                        readOnly
                                                        style={{ padding: '14px', }}
                                                        placeholder="Address Line 1"
                                                    />
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        id="zipCode"
                                                        readOnly
                                                        value={portfolio?.city}
                                                        style={{ padding: '14px', }}
                                                        placeholder="City"
                                                    />
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        id="zipCode"
                                                        readOnly
                                                        value={portfolio?.state}
                                                        style={{ padding: '14px', }}
                                                        placeholder="State"
                                                    />
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        id="zipCode"
                                                        readOnly
                                                        value={portfolio?.zipcode}
                                                        style={{ padding: '14px', }}
                                                        placeholder="Code"
                                                    />
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        id="zipCode"
                                                        readOnly
                                                        value={portfolio?.country}
                                                        style={{ padding: '14px', }}
                                                        placeholder="Country"
                                                    />
                                                </div>
                                                <div className="flex flex-col gap-3 w-full">
                                                    <h6 className="text_primary mb-0 plusJakara_medium">Business Location</h6>
                                                    {isLoaded ? (
                                                        <div style={{ maxWidth: '25rem', width: '100%', height: 'auto', overflow: 'hidden', borderRadius: "20px" }}>
                                                            <GoogleMap
                                                                mapContainerStyle={{ width: '100%', height: '10rem' }}
                                                                center={{
                                                                    lat: portfolio?.location?.coordinates[1],
                                                                    lng: portfolio?.location?.coordinates[0],
                                                                }}
                                                                zoom={16}
                                                                apiKey={process.env.REACT_APP_APIKEY}
                                                            >
                                                                <MarkerF
                                                                    icon={{
                                                                        url: marker,
                                                                        scaledSize: new window.google.maps.Size(35, 35),
                                                                        className: 'custom-marker-icon',
                                                                    }}
                                                                    position={{ lat: portfolio?.location?.coordinates[1], lng: portfolio?.location?.coordinates[0] }}
                                                                />
                                                            </GoogleMap>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="flex flex-col w-full gap-3">
                                                    <h6 className="text_primary mb-0 plusJakara_medium">Availability:</h6>
                                                    <div className="flex flex-col mb-3 gap-3 w-full">
                                                        {availableDays?.map((day, index) => (
                                                            <div
                                                                key={index}
                                                                style={{ backgroundColor: "#EBF0F5" }}
                                                                className="px-4 rounded-3 py-2 flex gap-5 justify-between"
                                                            >
                                                                <h6 style={{ maxWidth: '10rem', width: '100%' }} className="text_primary mb-0 plusJakara_medium">
                                                                    {day.day}
                                                                </h6>
                                                                <h6 style={{ whiteSpace: "nowrap" }} className="text_secondary2 mb-0 plusJakara_regular">
                                                                    {new Date(day.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(day.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                </h6>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full gap-3">
                                                    <h6 className="text_primary mb-0 plusJakara_medium">Insurance No:</h6>
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        id="zipCode"
                                                        readOnly
                                                        value={portfolio?.insurance}
                                                        style={{ padding: '14px', }}
                                                        placeholder="Txvnjkol788900"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full gap-3">
                                                    <h6 className="text_primary mb-0 plusJakara_medium">License No:</h6>
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        id="zipCode"
                                                        readOnly
                                                        value={portfolio?.license}
                                                        style={{ padding: '14px', }}
                                                        placeholder="Txvnjkol788900"
                                                    />
                                                </div>
                                                <div className="flex flex-col w-full gap-3">
                                                    <h6 className="text_primary mb-0 plusJakara_medium">Social Media:</h6>
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        value={portfolio?.fblink}
                                                        id="zipCode"
                                                        readOnly
                                                        style={{ padding: '14px', }}
                                                        placeholder="www.cleaningservice.com"
                                                    />
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        value={portfolio?.linkedInlink}
                                                        id="zipCode"
                                                        readOnly
                                                        style={{ padding: '14px', }}
                                                        placeholder="facebook.com"
                                                    />
                                                    <Input
                                                        type="text"
                                                        className="form-control rounded-2 text_secondary2 bg_light plusJakara_medium"
                                                        name="zipCode"
                                                        value={portfolio?.instalink}
                                                        id="zipCode"
                                                        readOnly
                                                        style={{ padding: '14px', }}
                                                        placeholder="facebook.com"
                                                    />
                                                </div>
                                            </>}
                                        {active === 'services' && (
                                            <div className="flex my-5 w-full justify-center">
                                                {loading ? (
                                                    <CircularProgress size={20} className='text_primary' />
                                                ) : (
                                                    <>
                                                        {!companyServices || companyServices?.length === 0 ? (
                                                            <span className="text_black plusJakara_medium">No Service Found</span>
                                                        ) : (
                                                            <div className="grid6">
                                                                {companyServices.map((item, i) => (
                                                                    <ServiceItem2 showView={false} key={i} category={item} portfolioPage={true} />
                                                                ))}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {active === 'portfolio' && (
                                            <>
                                                <div className="flex w-full flex-col">
                                                    <div className="grid6">
                                                        {portfolio?.portfolio?.map((item, i) => (
                                                            <div key={i} className="flex flex-col rounded-4 overflow-hidden border gap-3">
                                                                <div className="home_swiper" style={{ width: '100%', height: 'auto' }}>
                                                                    <Swiper
                                                                        freeMode={true}
                                                                        modules={[FreeMode, Autoplay, Navigation]}
                                                                        navigation={true}
                                                                        pagination={{ clickable: true }}
                                                                        loop={true}
                                                                        className="mySwiper d-flex w-100 gap-3 relative"
                                                                        slidesPerView={1}
                                                                    // onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                                                                    // initialSlide={activeIndex}
                                                                    >
                                                                        {item?.urls?.length > 0 ? (
                                                                            item.urls.map((cat, index) => (
                                                                                <SwiperSlide
                                                                                    key={index}
                                                                                    style={{ width: '100%', height: '10rem', overflow: 'hidden' }}
                                                                                >
                                                                                    {cat?.type === 'video' && cat?.url ? (
                                                                                        <video
                                                                                            src={cat.url}
                                                                                            alt="Preview"
                                                                                            style={{
                                                                                                height: '100%',
                                                                                                width: '100%',
                                                                                                cursor: 'pointer',
                                                                                                objectFit: 'cover',
                                                                                            }}
                                                                                            className="rounded-4 w-full bg_light object-cover"
                                                                                            controls
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => handleClickPortfolio(item, index)}
                                                                                            src={cat.url || cleaning2}
                                                                                            style={{
                                                                                                height: '100%',
                                                                                                width: '100%',
                                                                                                cursor: 'pointer',
                                                                                                objectFit: 'cover',
                                                                                            }}
                                                                                            alt="Project"
                                                                                        />
                                                                                    )}
                                                                                </SwiperSlide>
                                                                            ))
                                                                        ) : (
                                                                            <SwiperSlide
                                                                                style={{ width: '100%', height: '10rem', overflow: 'hidden' }}
                                                                            >
                                                                                <img
                                                                                    src={cleaning2}
                                                                                    onClick={() => handleClickPortfolio(item, 0)}
                                                                                    style={{
                                                                                        height: '100%',
                                                                                        width: '100%',
                                                                                        cursor: 'pointer',
                                                                                        objectFit: 'cover',
                                                                                    }}
                                                                                    alt="Default Project"
                                                                                />
                                                                            </SwiperSlide>
                                                                        )}
                                                                    </Swiper>
                                                                    <div onClick={() => handleClickPortfolio(item, 0)} className="p-2 flex flex-col cursor-pointer gap-2">
                                                                        <div className="flex justify-between gap-1 items-center">
                                                                            <h6 className="text_black plusJakara_semibold mb-0 whitespace-nowrap">{item.title}</h6>
                                                                        </div>
                                                                        <span className="text_secondary2 plusJakara_regular line-clamp-2">{item.description}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Modal
                    centered
                    open={show}
                    footer={null}
                    width={600}
                    zIndex={9999}
                    onCancel={() => setshow(false)}
                >
                    <div>
                        <h4 className="text_primary text-center mb-4 plusJakara_bold">View Portfolio Detail</h4>
                        <div className="flex flex-col w-full overflow-hidden gap-3">
                            <div className="home_swiper" style={{ width: '100%', height: 'auto' }}>
                                <Swiper
                                    freeMode={true}
                                    modules={[FreeMode, Autoplay, Navigation]}
                                    navigation={true}
                                    pagination={{ clickable: true }}
                                    loop={true}
                                    className="mySwiper d-flex w-full gap-3 relative"
                                    slidesPerView={1}
                                    onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                                    initialSlide={activeIndex}
                                >
                                    {selectedItem?.urls?.length > 0 ? (
                                        selectedItem.urls.map((cat, index) => (
                                            <SwiperSlide
                                                key={index}
                                                className='rounded-4'
                                                style={{ width: '100%', height: '18rem', overflow: 'hidden' }}
                                            >
                                                {cat?.type === 'video' && cat?.url ? (
                                                    <video
                                                        src={cat.url}
                                                        alt="Preview"
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            cursor: 'pointer',
                                                            objectFit: 'cover',
                                                        }}
                                                        className="rounded-4 w-full bg_light object-cover"
                                                        controls
                                                    />
                                                ) : (
                                                    <img
                                                        // onClick={() => handleClickPortfolio(cat, index)}
                                                        src={cat.url || cleaning2}
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            cursor: 'pointer',
                                                            objectFit: 'cover',
                                                        }}
                                                        alt="Project"
                                                    />
                                                )}
                                            </SwiperSlide>
                                        ))
                                    ) : (
                                        <SwiperSlide
                                            className='rounded-4'
                                            style={{ width: '100%', height: '18rem', overflow: 'hidden' }}
                                        >
                                            <img
                                                src={cleaning2}
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    cursor: 'pointer',
                                                    objectFit: 'cover',
                                                }}
                                                alt="Default Project"
                                            />
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                                <div className="thumbnails flex justify-start gap-2 mt-2">
                                    {selectedItem?.urls?.map((cat, index) => (
                                        <div
                                            key={index}
                                            className={`thumbnail relative cursor-pointer overflow-hidden rounded-2 ${activeIndex === index ? 'border-2 border-[#003F7D]' : 'border'}`}
                                            onClick={() => handleThumbnailClick(cat, index)}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                overflow: 'hidden',
                                                transition: 'border-color 0.3s ease',
                                            }}
                                        >
                                            {cat?.type === 'video' ?
                                                <video
                                                    src={cat.url}
                                                    alt="Preview"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        cursor: 'pointer',
                                                        objectFit: 'cover',
                                                    }}
                                                    controls
                                                /> :
                                                <img
                                                    src={cat.url || cleaning2} style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        cursor: 'pointer',
                                                        objectFit: 'cover',
                                                    }} alt="Project" />}
                                        </div>
                                    ))}
                                </div>
                                <div className="p-3 flex flex-col gap-2">
                                    <div className="flex justify-between gap-2 items-center">
                                        <h4 className="text_black plusJakara_bold mb-0 whitespace-nowrap">{selectedItem?.title}</h4>
                                    </div>
                                    <span className="text_secondary2 plusJakara_regular">{selectedItem?.description}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </Container>
        </ main >
    );
};

export default ServiceProvider;