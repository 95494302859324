/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
import InvoiceItem from './invoiceItem';

const AllInvoices = () => {
    const categories = [1, 2, 3, 4, 5, 6];

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Invoice' serviceName='All Invoices' />
            <Container className='py-5'>
                {/* <h6 className="text-3xl lg:text-4xl plusJakara_bold text_black">All <span className="text-3xl lg:text-4xl plusJakara_bold text_primary"> Estimates</span> </h6> */}
                <div className="grid7">
                    {categories?.map((item, i) => (
                        <InvoiceItem showView={true} key={i} category={item} />
                    ))}
                </div>
            </Container>
        </ main>
    );
};

export default AllInvoices;
