import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getBlogs } from '../api/blogs';
import '../style/swiper.css';
import BlogItem from './blogItem';
import HeaderNavigation from './common/headerNavigation';
import PostLoader2 from './common/PostLoader2';
import BlogCategories from './homeComponents2/blogCategories';

const BlogSection = () => {
    const [blogData, setblogData] = useState([]);
    const [loading, setloading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('all');

    const handleSelectCategory = (category) => {
        setSelectedCategory(category);
        setloading(true);
        getBlogs(category?._id, 1)
            .then((res) => {
                setloading(false);
                setblogData(res?.blogs || []);
            })
            .catch(() => {
                setloading(false);
                setblogData([]);
            });
    };

    useEffect(() => {
        handleSelectCategory({ _id: 'all', name: 'All' });
    }, []);

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Blogs' serviceName={`${selectedCategory?.name} Blogs`} />
            <BlogCategories
                onSelectCategory={handleSelectCategory}
                selectedCategory={selectedCategory}
            />
            <Container>
                {loading ? (
                    <PostLoader2 />
                ) : !blogData || blogData.length === 0 ? (
                    <div className='flex flex-col my-5 items-center'>
                        <span className='plusJakara_medium text_secondary2 md:text-[16px]'>No Blog Found</span>
                    </div>
                ) : (
                    <div className="grid5">
                        {blogData.map((item, i) => (
                            <BlogItem showView={false} key={i} category={item} />
                        ))}
                    </div>
                )}
            </Container>
        </main>
    );
};

export default BlogSection;
