/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../style/main.css';
import ServiceForm from './serviceForm';
import { useSelector } from 'react-redux';

const UpdateService = () => {
    const { state } = useLocation();
    const serviceData = state?.serviceData || null;
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const navigate = useNavigate();

    return (
        <main className='p-5 w-full'>
            <Container fluid={usertype === 'company'} className='p-4 bg_white rounded-4 w-full'>
                <div className="flex mb-4 flex-col gap-3 w-full">
                    <div className="flex gap-4 items-start w-full">
                        <button
                            onClick={() => navigate(-1)}
                            className="bg_primary rounded-3 p-2"
                        >
                            <FaArrowLeft className='text_white' />
                        </button>
                        <h4 className="text_primary mb-0 plusJakara_semibold">Update Service</h4>
                    </div>
                </div>
                <ServiceForm serviceData={serviceData} />
            </Container>
        </main>
    );
};

export default UpdateService;
