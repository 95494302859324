/* eslint-disable no-unused-vars */
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { LiaUserCheckSolid } from "react-icons/lia";
import { MdKeyboardArrowRight } from "react-icons/md";
import { PiMoneyBold, PiUsersThreeBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import StatisticsChart from "../common/statisticsChart";
import { GrProjects } from "react-icons/gr";
import { FaCashRegister, FaFileContract } from "react-icons/fa6";
import CreateContract from "./common/createContract";
import { TbShoppingBagCheck } from "react-icons/tb";
import Scheduling from "./scheduling";
import axiosInstance from "../redux/axiosInstance";
import { useSelector } from "react-redux";


const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.auth?.userData)
  const [categories, setCategories] = useState([]);
  const [modalOpen, setmodalOpen] = useState(false)

  const labelColor = '#6e6b7b';
  const gridLineColor = 'rgba(200, 200, 200, 0.2)';

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`users/company/dashboard`,);
      if (res?.data) {
        setCategories(res?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <main style={{ minHeight: '100%' }} className="container-fluid p-5 mx-auto">
      <Row gutter={16} className="">
        <Col xs={24} lg={12} style={{ minHeight: '88vh' }} className="border-r-2 border-r-gray-300">
          <div className="gridDashboard mb-3 w-100">
            <div className="sub_grid_dashboard w-100 p-3 rounded-4 gap-3 flex justify-between flex-col bg_white" style={{ minHeight: '120px', border: '1.5px solid #FFB7BF' }}>
              <div className="flex justify-between w-full">
                <h6 className="plusJakara_medium">Total Jobs</h6>
                <div className="d-flex justify-content-center rounded-3 align-items-center" style={{ width: '35px', height: '35px', backgroundColor: '#FFE2E5' }}>
                  <TbShoppingBagCheck size={18} />
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-end">
                  <h5 className="plusJakara_bold mb-0">{categories?.toatalJobs || 0}</h5>
                </div>
              </div>
            </div>
            <div className="sub_grid_dashboard w-100 p-3 rounded-4 gap-3 flex justify-between flex-col bg_white" style={{ minHeight: '120px', border: '1.5px solid #FCE0A9' }}>
              <div className="flex justify-between w-full">
                <h6 className="plusJakara_medium">Total Projects</h6>
                <div className="d-flex justify-content-center rounded-3 align-items-center" style={{ width: '35px', height: '35px', backgroundColor: '#FFF4DE' }}>
                  <GrProjects size={16} />
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-end">
                  <h5 className="plusJakara_bold mb-0">{categories?.totalProjects || 0}</h5>
                </div>
              </div>
            </div>
            <div className="sub_grid_dashboard w-100 p-3 rounded-4 gap-3 flex justify-between flex-col bg_white" style={{ minHeight: '120px', border: '1.5px solid #9BFFBD' }}>
              <div className="flex justify-between w-full">
                <h6 className="plusJakara_medium">Total Earnings</h6>
                <div className="d-flex justify-content-center rounded-3 align-items-center" style={{ width: '35px', height: '35px', backgroundColor: '#DCFCE7' }}>
                  <PiMoneyBold size={18} />
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-end">
                  <h5 className="plusJakara_bold mb-0">${categories?.totalEarnings || 0}</h5>
                </div>
              </div>
            </div>
            <div className="sub_grid_dashboard w-100 p-3 rounded-4 gap-3 flex justify-between flex-col bg_white" style={{ minHeight: '120px', border: '1.5px solid #F6FFA1' }}>
              <div className="flex justify-between w-full">
                <h6 className="plusJakara_medium">Total Employee</h6>
                <div className="d-flex justify-content-center rounded-3 align-items-center" style={{ width: '35px', height: '35px', backgroundColor: '#FBFFD4' }}>
                  <PiUsersThreeBold size={18} />
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-end">
                  <h5 className="plusJakara_bold mb-0">{categories?.totalEmployee || 0}</h5>
                </div>
              </div>
            </div>
            <div className="sub_grid_dashboard w-100 p-3 rounded-4 gap-3 flex justify-between bg_white flex-col" style={{ minHeight: '120px', border: '1.5px solid #E2C8FD' }}>
              <div className="flex justify-between w-full">
                <h6 className="plusJakara_medium">Pending Estimates</h6>
                <div className="d-flex justify-content-center rounded-3 align-items-center" style={{ width: '35px', height: '35px', backgroundColor: '#F3E8FF' }}>
                  <FaCashRegister size={18} />
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-end">
                  <h5 className="plusJakara_bold mb-0">{categories?.pendingEstimate || 0}</h5>
                </div>
              </div>
            </div>
            <div className="sub_grid_dashboard w-100 p-3 rounded-4 gap-3 flex justify-between flex-col bg_white" style={{ minHeight: '120px', border: '1.5px solid #B0DFFF' }}>
              <div className="flex justify-between w-full">
                <h6 className="plusJakara_medium">Total Contracts</h6>
                <div className="d-flex justify-content-center rounded-3 align-items-center" style={{ width: '35px', height: '35px', backgroundColor: '#E9F6FF' }}>
                  <FaFileContract size={18} />
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-end">
                  <h5 className="plusJakara_bold mb-0">{categories?.toatalContracts || 0}</h5>
                </div>
              </div>
            </div>
          </div>
          <StatisticsChart />
        </Col>
        <Col xs={24} lg={12}>
          <Scheduling />
        </Col>
      </Row>

      {/* <div className="flex gap-3 mb-4 items-center flex-wrap flex-lg-nowrap w-full">
        <ChartjsHorizontalBarChart
          info="#003F7D"
          labelColor={labelColor}
          warning="#FF8E00"
          gridLineColor={gridLineColor}
        />
        <StatisticsChart />
      </div>
      <OrderChart /> */}

      < CreateContract setmodalOpen={setmodalOpen} modalOpen={modalOpen} />
    </main >
  );
};

export default Dashboard;
