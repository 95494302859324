/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from "react-router-dom";
import ForgotPassword from '../auth/forgotPassword';
import LoginPage from '../auth/login';
import PersonalInformation from '../auth/personalInformation';
import ResetPassword from '../auth/resetPassword';
import SignupPage from '../auth/signup';
import UploadPortfolio from '../auth/uploadPortfolio';
import Verification from '../auth/verification';
import SidebarLayout from "../layout/sidebarLayout";
import About from '../pages/about';
import AllEstimates from '../pages/allEstimates';
import AllInvoices from '../pages/allInvoices';
import AllProposals from '../pages/allProposals';
import AllServices from '../pages/allServices';
import BlogSection from '../pages/blogSection';
import ChangePassword from '../pages/changePassword';
import ViewContract from '../pages/common/viewContract';
import Contact from '../pages/contact';
import Dashboard from '../pages/dashboard';
import AddEmployee from '../pages/employee/addEmployee';
import AllTaskss from '../pages/employee/allTasks';
import EmployeeDashboard from '../pages/employee/employeeDashboard';
import EmployeeDetail from '../pages/employee/employeeDetail';
import EmployeeList from '../pages/employee/employeeList';
import EmployeeServiceDetail from '../pages/employee/employeeServiceDetail';
import EstimateDetail from '../pages/estimateDetail';
import EstimateDetailProvider from '../pages/estimateDetailProvider';
import EstimateTable from '../pages/estimateTable';
import Home2 from '../pages/home2';
import InvoiceDetailProvider from '../pages/invoiceDetailProvider';
import InvoiceTable from '../pages/invoiceTable';
import NearbyServices from '../pages/nearbyServices';
import NotFound from '../pages/notFound';
import Orders from '../pages/order';
import OrderDetail from '../pages/orderDetail';
import Profile from '../pages/profile';
import ProposalDetail from '../pages/proposalDetail';
import ProposalDetailProvider from '../pages/proposalDetailProvider';
import ProposalTable from '../pages/proposalTable';
import SendEstimate from '../pages/sendEstimate';
import ServiceDetail from '../pages/serviceDetail';
import ServiceProvider from '../pages/serviceProvider';
import AddService from '../pages/services/addService';
import ServiceList from '../pages/services/serviceList';
import PrivateRoutes from '../redux/auth/privateRoutes';
import PublicRoutes from '../redux/auth/publicRoutes';
import PricingInfo from '../auth/pricingInfo';
import ServiceInfo from '../auth/serviceInfo';
import AvailabilityInfo from '../auth/availabilityInfo';
import PortfolioInfo from '../auth/portfolioInfo';
import UpdateService from '../pages/services/updateService';
import BookService from '../pages/bookService';
import Messages from '../pages/messages/message';
import CreateEstimate from '../pages/estimate/createEstimate';
import ContractTable from '../pages/contractTable';
import ContractDetailProvider from '../pages/contractDetailProvider';
import CreateInvoice from '../pages/createInvoice';
import InvoiceTablePaid from '../pages/invoiceTablePaid';
import Schedule from '../pages/schedule';
import ScheduleAdmin from '../pages/scheduleAdmin';
import TermsConditions from '../pages/termsConditions';
import PrivacyPolicy from '../pages/privacypolicy';
import OurTeam from '../pages/ourTeam';
import Faq from '../pages/faq';
import BusinessProfile from '../pages/businessProfile';
import SchedulingPage from '../pages/schedulingPage';
import EstimateStatic from '../pages/estimateStatic';
import ContractsStatic from '../pages/contractsStatic';
import InvoiceStatic from '../pages/invoiceStatic';
import BlogDetail from '../pages/BlogDetail';
import SuccessPage from '../pages/common/successPage';
import RedirectPage from '../pages/common/redirectPage';
import PricingPlans from '../pages/common/pricingPlan';
import SubscriptionList from '../pages/subscriptionList';

const Routing = () => {
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const isMobileLogin = useSelector((state) => state.auth?.isMobileLogin)
    const isLogin = useSelector((state) => state.auth?.isLogin)

    return (
        <Routes>
            {/* <Route index element={usertype === 'company' ? <Dashboard /> : usertype === 'employee' ? <EmployeeDashboard /> : (isGetstarted ? <Home2 /> : <Home />)} /> */}
            <Route index element={(usertype === 'company' && isLogin) ? <Dashboard /> : (usertype === 'employee' && isLogin) ? <EmployeeDashboard /> : <Home2 />} />
            <Route path="/" element={<Home2 />} />
            <Route path="/all-services" element={<AllServices />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="/scheduling-instruction" element={<SchedulingPage />} />
            <Route path="/estimate-instruction" element={<EstimateStatic />} />
            <Route path="/contract-instruction" element={<ContractsStatic />} />
            <Route path="/invoice-instruction" element={<InvoiceStatic />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/service-detail/:id" element={<ServiceDetail />} />
            <Route path="/services/:id" element={<ServiceProvider />} />
            <Route path="/all-estimates/:id" element={<EstimateDetail />} />
            <Route path="/all-proposals/:id" element={<ProposalDetail />} />
            <Route path="/nearby-services" element={<NearbyServices />} />
            <Route path="/send-estimate" element={<SendEstimate />} />
            <Route path="/all-estimates" element={<AllEstimates />} />
            <Route path="/all-blogs" element={<BlogSection />} />
            <Route path="/all-blogs/:id" element={<BlogDetail />} />
            <Route path="/all-invoices" element={<AllInvoices />} />
            <Route path="/all-proposals" element={<AllProposals />} />
            <Route element={<PrivateRoutes />}>
                <Route path="/sidebar-layout" element={<SidebarLayout />} />
                <Route path="/schedule" element={<Schedule />} />
                <Route path="/proposals" element={<ProposalTable />} />
                <Route path="/proposals/:id" element={<ProposalDetailProvider />} />
                <Route path="/orders/:id" element={<OrderDetail />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/estimates" element={<EstimateTable />} />
                <Route path="/schedule-admin" element={<ScheduleAdmin />} />
                <Route path="/paid-invoices" element={<InvoiceTablePaid />} />
                <Route path="/contracts" element={<ContractTable />} />
                <Route path="/contracts/:id" element={<ContractDetailProvider />} />
                <Route path="/create-estimate" element={<CreateEstimate />} />
                <Route path="/orders/invoice/:id" element={<CreateInvoice />} />
                <Route path="/estimates/:id" element={<EstimateDetailProvider />} />
                <Route path="/contracts/view-contract" element={<ViewContract />} />
                <Route path="/add-service" element={<AddService />} />
                <Route path="/service-list" element={<ServiceList />} />
                <Route path="/service-list/update/:id" element={<UpdateService />} />
                <Route path="/add-employee" element={<AddEmployee />} />
                <Route path="/employee-list" element={<EmployeeList />} />
                <Route path="/employee-list/:id" element={<EmployeeDetail />} />
                <Route path="/employee-list/:id/detail" element={<EmployeeServiceDetail />} />
                <Route path={usertype === 'company' ? `/employee-list/service/:id` : '/all-tasks/:id'} element={<EmployeeServiceDetail />} />
                <Route path="/invoices" element={<InvoiceTable />} />
                <Route path="/book-service" element={<BookService />} />
                <Route path="/invoices/:id" element={<InvoiceDetailProvider />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/all-tasks" element={<AllTaskss />} />
                <Route path="/dashboard" element={(usertype === 'company') ? <Dashboard /> : <EmployeeDashboard />} />
                <Route path="/subscription-history" element={<SubscriptionList />} />
                <Route path="/business-profile" element={<BusinessProfile />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/chat" element={<Messages />} />
            </Route>
            <Route element={<PublicRoutes />}>
                <Route path="login" element={<LoginPage />} />
                <Route path="signup" element={<SignupPage />} />
                <Route path="personal-info" element={<PersonalInformation />} />
                <Route path="pricing-info" element={<PricingInfo />} />
                <Route path="service-info" element={<ServiceInfo />} />
                <Route path="availability-info" element={<AvailabilityInfo />} />
                <Route path="portfolio-info" element={<PortfolioInfo />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="code-verify" element={<Verification />} />
            </Route>
            <Route path="*" element={<NotFound />} />
            <Route path="payment-successfuly" element={<SuccessPage />} />
            <Route path="redirect-page" element={<RedirectPage />} />
            <Route path="/subscription-plan" element={<PricingPlans />} />
        </Routes>
    );
};

export default Routing;