/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CircularProgress } from "@mui/material";
import { message } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { SlCloudUpload } from "react-icons/sl";
import { Calendar } from "react-multi-date-picker";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Col, Input, Label, Row } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
import * as Yup from "yup";
import { getCategory, getSubcat } from "../../api/category";
import { createService, updateService } from "../../api/service";
import { uploadFile } from "../../api/uploadFile";
import { calculateFinalPrice } from "../estimate/calculateFinalPrice";
import { locationData } from "../../common/cities";

const ServiceForm = ({ serviceData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [fileLoading, setFileLoading] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [unit, setUnit] = useState('');
    const [unitType, setUnitType] = useState('unit');
    const [citiesOptions, setCitiesOptions] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);
    const [categories, setcategories] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [features, setFeatures] = useState([]);
    const [subcategories, setsubcategories] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectFeature, setselectFeature] = useState(null)
    const [dates, setdates] = useState([]);
    const [initialValues, setInitialValues] = useState({
        name: "",
        images: [],
        totalPrice: '',
        dis_price: '',
        tax: '',
        category: null,
        subCategory: [],
        description: "",
    });

    useEffect(() => {
        if (serviceData) {
            setdates(serviceData?.availablity || [])
        }
    }, [serviceData])

    const handleDateChange = (dateArray) => {
        const formattedDates = Array.isArray(dateArray)
            ? dateArray.map((date) => new Date(date).toISOString())
            : [];
        setdates(formattedDates);
    }

    useEffect(() => {
        if (title && price && unit && unitType) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true);
        }
    }, [title, price, unit, unitType]);

    const calculateGrandTotal = () => {
        const total = features?.reduce((sum, feature) => sum + (feature.totalPrice || 0), 0);
        setGrandTotal(total);
    };

    useEffect(() => {
        calculateGrandTotal();
    }, [features]);

    const handleFetchCategories = async () => {
        await getCategory()
            .then((res) => {
                if (res.success === true) {
                    setcategories(res.categories)
                }
            })
            .catch((err) => {
            })
    };

    const handleStateChange = (selectedState, setFieldValue) => {
        const cities = locationData[selectedState?.label] || [];
        const cityOptions = cities.map((city) => ({ label: city, value: city }));
        setCitiesOptions(cityOptions); // Update cities dropdown based on selected state
        setFieldValue("state", selectedState);
        setFieldValue("cities", []); // Reset selected cities when state changes
    };

    const handleSave = () => {
        const calculatedTotalPrice = Number(price) * Number(unit);
        const feature = {
            title,
            price,
            unit,
            unitType,
            totalPrice: calculatedTotalPrice,
            id: selectFeature ? selectFeature.id : uuidv4(),
        };
        if (selectFeature) {
            handleUpdateFeature(feature);
        } else {
            handleSaveFeature(feature);
        }
        resetInputs();
        toggleModal()
    };

    const resetInputs = () => {
        setTitle('');
        setPrice('');
        setUnit('');
        setselectFeature(null);
    };

    const toggleModal = () => {
        resetInputs()
        setModalOpen(!modalOpen);
    };

    const handleSaveFeature = (newFeature) => {
        setFeatures([...features, newFeature]);
    };

    const handleUpdateFeature = (updatedFeature) => {
        setFeatures((prevFeatures) =>
            prevFeatures.map(feature =>
                feature.id === updatedFeature.id ? updatedFeature : feature
            )
        );
    };

    const handleRemoveFeature = (row) => {
        const updatedFeatures = features.filter(feature => feature.id !== row.id);
        setFeatures(updatedFeatures);
    };

    useEffect(() => {
        if (serviceData) {
            const category = {
                value: serviceData.category?._id,
                label: serviceData.category?.name,
            };
            const subcategories = serviceData.subcategory?.map((item) => ({
                value: item._id,
                label: item.name,
            })) || [];
            const state = serviceData.cities?.[0] ? {
                value: serviceData.cities[0].state,
                label: serviceData.cities[0].state,
            } : null;
            const cities = serviceData.cities?.map((item) => ({
                label: item.city,
                value: item.city,
            })) || [];

            setInitialValues({
                name: serviceData.name || "",
                images: serviceData.images || [],
                dis_price: serviceData.dis_price || '',
                tax: serviceData.tax || '',
                category,
                state: state,
                cities: cities,
                subCategory: subcategories,
                description: serviceData.description || "",
                totalPrice: serviceData.totalPrice || '',
            });
            setImageUrls(serviceData?.images)
            setCitiesOptions(cities);
        }
    }, [serviceData]);

    useEffect(() => {
        handleFetchCategories()
    }, [])

    const handlecategorySelect = async (row) => {
        await getSubcat(row?.value)
            .then((res) => {
                if (res.success === true) {
                    setsubcategories(res.subcategories)
                } else {
                    setsubcategories([])
                }
            })
            .catch((err) => {
            })
    }

    const handleSelectImages = (values, setFieldValue) => async (event) => {
        const selectedFiles = event.target.files;
        const updatedImageUrls = [...imageUrls];

        for (let i = 0; i < selectedFiles.length; i++) {
            const selectedFile = selectedFiles[i];
            const formData = new FormData();
            formData.append('image', selectedFile);
            setFileLoading(true);
            try {
                const res = await uploadFile({ data: formData });
                if (res.image) {
                    setFileLoading(false);
                    updatedImageUrls.push(res.image);
                    setFieldValue('images', [...values.images, res.image]);
                }
            } catch (err) {
                setFileLoading(false);
                console.error(`Error uploading file ${selectedFile.name}:`, err);
            }
        }
        setImageUrls(updatedImageUrls);
    };

    const removeImage = (index, setFieldValue) => {
        const updatedImages = imageUrls.filter((_, i) => i !== index);
        setImageUrls(updatedImages);
        setFieldValue('images', updatedImages);
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Service Name is required"),
        totalPrice: Yup.number().positive("Price must be in Positive").required("Service Price is required"),
        images: Yup.array().min(1, "Please upload at least one image"),
        category: Yup.object().required("Please select a Category"),
        state: Yup.object().required("Please select a state"),
        cities: Yup.array().min(1, "Please select at least one city"),
        subCategory: Yup.array().min(1, "Please select at least one Sub category"),
        description: Yup.string().min(5, "Description must be at least 5 characters").required("Description is required"),
    });

    const handleSubmit = async (values) => {
        const discountPrice = calculateFinalPrice(grandTotal,
            parseInt(values?.dis_price || 0),
            parseInt(values?.tax || 0))
        const data = {
            name: values.name,
            images: imageUrls,
            category: values.category?.value,
            state: values.state.label,
            cities: values.cities.map((city) => ({ city: city.label, state: values.state.label })),
            subcategory: values.subCategory?.map((item) => item.value),
            description: values.description,
            totalPrice: values?.totalPrice,
            dis_price: values.dis_price || 0,
            tax: values.tax || 0,
            // features: features,
            availablity: dates
        }
        setIsLoading(true)
        if (serviceData) {
            await updateService({ data: data }, serviceData?._id).then((res) => {
                if (res.success === true) {
                    message.success("Service update successfully");
                    setIsLoading(false)
                    navigate('/service-list');
                } else {
                    message.error("Failed to update service");
                    setIsLoading(false)
                }
            }).catch((err) => {
                console.error("Error creating service:", err);
                setIsLoading(false)
            })
        } else {
            await createService({ data: data }).then((res) => {
                if (res.success === true) {
                    message.success("Service created successfully");
                    setIsLoading(false)
                    navigate('/service-list');
                } else {
                    message.error("Failed to create service");
                    setIsLoading(false)
                }
            }).catch((err) => {
                console.error("Error creating service:", err);
                setIsLoading(false)
            })
        }
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, setFieldTouched, handleSubmit }) => (
                    <Form className="w-full">
                        <Row>
                            {/* {currentStep === 0 && <> */}
                            <div className="mb-4 position-relative">
                                <Label className="form-label text_secondary2 plusJakara_medium" htmlFor="portfolio">
                                    Services Images
                                </Label>
                                <div className="flex flex-col mb-3 gap-2">
                                    <div className="flex flex-wrap gap-3">
                                        {imageUrls.map((url, index) => (
                                            <div key={index} className="relative">
                                                <img
                                                    src={url}
                                                    alt="Preview"
                                                    style={{ width: "120px", height: "100px", objectFit: "cover" }}
                                                    className="rounded-4 object-cover relative"
                                                />
                                                <IoCloseSharp
                                                    onClick={() => removeImage(index, setFieldValue)}
                                                    style={{
                                                        position: "absolute",
                                                        top: "0px",
                                                        right: "0px",
                                                        cursor: "pointer",
                                                        backgroundColor: "white",
                                                        borderRadius: "3px",
                                                        padding: "2px",
                                                    }}
                                                    size={18}
                                                />
                                            </div>
                                        ))}
                                        <label
                                            style={{ cursor: "pointer" }}
                                            htmlFor="fileInput"
                                            className="cursor-pointer"
                                        >
                                            {fileLoading ? (
                                                <div
                                                    style={{ width: "120px", height: "100px" }}
                                                    className="border rounded-4 bg_light text_primary d-flex justify-content-center align-items-center"
                                                >
                                                    <CircularProgress size={20} />
                                                </div>
                                            ) : (
                                                <div
                                                    style={{ width: "120px", height: "100px" }}
                                                    className="border rounded-4 bg_light text_primary flex justify-center items-center"
                                                >
                                                    <SlCloudUpload size={40} />
                                                </div>
                                            )}
                                        </label>
                                        <Input
                                            type="file"
                                            multiple
                                            id="fileInput"
                                            className="visually-hidden"
                                            onChange={handleSelectImages(values, setFieldValue)}
                                        />
                                    </div>
                                    <ErrorMessage name={`images`} component="div" className="text-danger text-xs plusJakara_regular" />
                                </div>
                            </div>
                            <div className="flex gap-3 flex-wrap flex-md-nowrap w-full">
                                <div className="mb-3 w-full px-0">
                                    <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="category">
                                        Select your Service Category
                                    </Label>
                                    <div className="flex flex-col w-full">
                                        <Select
                                            name="category"
                                            id="category"
                                            placeholder="Select category"
                                            options={categories?.map((item) => ({
                                                label: item.name,
                                                value: item._id,
                                            }))}
                                            value={values.category}
                                            onChange={(selectedOption) => {
                                                handlecategorySelect(selectedOption);
                                                setFieldValue("subCategory", null);
                                                setFieldValue("category", selectedOption);
                                            }}
                                            onBlur={() => setFieldTouched("category", true)}
                                        />
                                        <ErrorMessage name={`category`} component="div" className="text-danger text-xs plusJakara_regular" />
                                    </div>
                                </div>
                                <div className="mb-3 w-full px-0">
                                    <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="subCategory">
                                        Select your Service Subcategories
                                    </Label>
                                    <div className="flex flex-col w-full">
                                        <Select
                                            name="subCategory"
                                            id="subCategory"
                                            isMulti
                                            placeholder="Select sub category"
                                            options={subcategories?.map((item) => ({
                                                label: item.name,
                                                value: item._id,
                                            }))}
                                            value={values.subCategory}
                                            onBlur={() => setFieldTouched("subCategory", true)}
                                            onChange={(selectedOption) => {
                                                setFieldValue("subCategory", selectedOption);
                                            }}
                                        />
                                        <ErrorMessage name={`subCategory`} component="div" className="text-danger text-xs plusJakara_regular" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex mb-4 gap-3 flex-wrap items-start flex-md-nowrap w-full">
                                <div className="mb-3 w-full">
                                    <Label
                                        className="form-label text_secondary2 plusJakara_medium"
                                        htmlFor="state"
                                    >
                                        Select State
                                    </Label>
                                    <Select
                                        name="state"
                                        options={Object.keys(locationData).map((state) => ({
                                            label: state,
                                            value: state,
                                        }))}
                                        value={values.state} // Use `values` from Formik
                                        onChange={(selectedState) => handleStateChange(selectedState, setFieldValue)}
                                    />

                                    <ErrorMessage name="state" component="div" className="text-danger text-xs" />
                                </div>
                                <div className="mb-3 w-full">
                                    <Label
                                        className="form-label text_secondary2 plusJakara_medium"
                                        htmlFor="cities"
                                    >
                                        Select Cities
                                    </Label>
                                    <Select
                                        name="cities"
                                        isMulti
                                        options={citiesOptions}
                                        value={values.cities}
                                        onChange={(selectedCities) => setFieldValue("cities", selectedCities)}
                                    />
                                    <ErrorMessage name="cities" component="div" className="text-danger text-xs" />
                                </div>
                            </div>
                            <div className="flex mb-4 gap-3 flex-wrap items-start flex-md-nowrap w-full">
                                <div className="w-full">
                                    <Label
                                        className="form-label text_secondary2 plusJakara_medium"
                                        htmlFor="name"
                                    >
                                        Service Name
                                    </Label>
                                    <Field
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="name"
                                        id="name"
                                        style={{ padding: '12px' }}
                                        placeholder="Service Name"
                                    />
                                    <ErrorMessage name={`name`} component="div" className="text-danger text-xs plusJakara_regular" />
                                </div>
                                <div className="w-full">
                                    <Label
                                        className="form-label text_secondary2 plusJakara_medium"
                                        htmlFor="totalPrice"
                                    >
                                        Service Price
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="totalPrice"
                                        id="totalPrice"
                                        style={{ padding: '12px' }}
                                        placeholder="$"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        }}
                                    />
                                    <ErrorMessage name={`totalPrice`} component="div" className="text-danger text-xs plusJakara_regular" />
                                </div>
                            </div>
                            <div className="flex mb-4 gap-3 flex-wrap items-start flex-md-nowrap w-full">
                                <div className="w-full">
                                    <Label
                                        className="form-label text_secondary2 plusJakara_medium"
                                        htmlFor="dis_price"
                                    >
                                        Discount ($)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="dis_price"
                                        id="dis_price"
                                        style={{ padding: '12px' }}
                                        placeholder="$"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            const discount = parseFloat(e.target.value || 0);
                                            const servicePrice = parseFloat(document.getElementById('totalPrice').value || 0);
                                            if (discount > servicePrice) {
                                                e.target.value = servicePrice;
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <Label
                                        className="form-label text_secondary2 plusJakara_medium"
                                        htmlFor="tax"
                                    >
                                        Tax (%)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="tax"
                                        id="tax"
                                        style={{ padding: '12px' }}
                                        placeholder="%"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            if (parseFloat(e.target.value) > 100) {
                                                e.target.value = 100;
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mb-4 w-full">
                                <Label className="form-label text_secondary2 plusJakara_medium" htmlFor="description">
                                    Service Description
                                </Label>
                                <Field
                                    as="textarea"
                                    className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                    name="description"
                                    id="description"
                                    style={{ padding: "12px", minHeight: '120px' }}
                                    placeholder="Description"
                                />
                                <ErrorMessage name={`description`} component="div" className="text-danger text-xs plusJakara_regular" />
                            </div>
                            <div className="flex flex-col w-full">
                                <label className="form-label text_secondary2 text-lg mb-3 plusJakara_medium">
                                    Select Working Days
                                </label>
                                <Calendar
                                    numberOfMonths={1}
                                    disableMonthPicker
                                    value={dates?.map((date) => new Date(date))}
                                    multiple
                                    disableYearPicker
                                    onChange={handleDateChange}
                                    minDate={new Date()}
                                />

                                {/* <DatePicker
                                        minDate={new Date()}
                                        multiple
                                        inputMode="none"
                                        value={dates?.map((date) => new Date(date))}
                                        placeholder="Select multiple Working Dates"
                                        className="form-control text_secondary2 plusJakara_medium w-full"
                                        style={{
                                            height: 'auto', // Allow the height to adjust
                                            padding: '10px',
                                            width: '100%',
                                            borderRadius: '8px',
                                            whiteSpace: 'normal', // Allow the text to wrap normally
                                            wordWrap: 'break-word', // Break words if necessary
                                            overflow: 'visible', // Ensure the content is fully visible
                                        }}
                                        onChange={handleDateChange}
                                        plugins={[<DatePanel />]}
                                    /> */}

                            </div>
                            {/* <div className="flex flex-col gap-2 w-full">
                                <Label className="form-label text_secondary2 plusJakara_medium" htmlFor="description">
                                    Whats Offering in this service?
                                </Label>
                                <Col xs='12' md='6' className="mb-4">
                                    <div className="flex gap-2 relative items-center w-full">
                                        <button
                                            type="button"
                                            onClick={toggleModal}
                                            className="border rounded-3 flex w-full items-center justify-between"
                                            style={{ padding: "6px" }}
                                        >
                                            <span className="plusJakara_medium text_secondary2">Floor Cleaning, Kitchen Cleaning, etc...</span>
                                            <button type="button" className="plusJakara_medium text_white bg_primary p-2 rounded-2"
                                                onClick={toggleModal}>
                                                <Plus size={18} />
                                            </button>
                                        </button>
                                    </div>
                                </Col>
                                {features.length > 0 &&
                                    <div className="w-full flex flex-col gap-3 mb-4">
                                        <DTables columns={columns} data={features} />
                                        <div className="flex flex-col gap-2 items-end justify-end w-full">
                                            <div className="flex w-full justify-end">
                                                <div className="flex justify-between" style={{ width: '250px' }}>
                                                    <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                                    <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                        ${grandTotal}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="flex w-full justify-end">
                                                <div className="flex justify-between" style={{ width: '250px' }}>
                                                    <h6 className="text_secondary2 mb-0 plusJakara_semibold">Discount ({values?.dis_price || 0}%)</h6>
                                                    <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                        $
                                                        {grandTotal * (parseInt(values?.dis_price) / 100) || 0}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="flex w-full justify-end">
                                                <div className="flex justify-between" style={{ width: '250px' }}>
                                                    <h6 className="text_secondary2 mb-0 plusJakara_semibold">Tax ({values?.tax || 0}%)</h6>
                                                    <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                        $
                                                        {grandTotal * (parseInt(values?.tax) / 100) || 0}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="flex mt-3 w-full justify-end">
                                                <div className="flex justify-between" style={{ width: '250px' }}>
                                                    <h5 className="text_primary mb-0 plusJakara_semibold">Total Price </h5>
                                                    <h5 className="text_black plusJakara_semibold mb whitespace-nowrap-0">
                                                        ${calculateFinalPrice(grandTotal,
                                                            parseInt(values?.dis_price || 0),
                                                            parseInt(values?.tax || 0)
                                                        )}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div> */}
                            <Col lg={12} className="mb-0 mt-4">
                                <div className={`flex ${currentStep === 0 ? 'justify-end' : 'justify-between'} w-full`}>
                                    <div className="flex justify-end w-full">
                                        <button
                                            className="w-fit rounded-2 bg_primary plusJakara_medium text_white"
                                            disabled={isLoading || fileLoading}
                                            style={{ width: '150px', padding: '12px' }}
                                            type="submit"
                                        >
                                            {isLoading ? <Spinner size="sm"></Spinner> : "Submit"}
                                        </button>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
            {/* <Modal size="lg" style={{ width: "700px", zIndex: 99999999 }} centered isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    <span className="plusJakara_semibold text_primary text-lg">Offering Services</span>
                </ModalHeader>
                <ModalBody className="flex flex-col gap-3 w-full">
                    <div className="flex flex-col gap-2 w-full">
                        <span className="plusJakara_medium text_secondary">Service Name</span>
                        <Input
                            style={{ padding: '10px' }}
                            className="form-control rounded-3 text_secondary2 plusJakara_medium"
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="relative flex justify-between gap-3 w-full">
                        <div className="flex flex-col gap-2 w-full">
                            <span className="plusJakara_medium text_secondary">Unit type</span>
                            <Input
                                style={{ padding: '10px' }}
                                className="rounded-3 text_secondary2 w-full plusJakara_medium"
                                type="select"
                                value={unitType}
                                onChange={(e) => setUnitType(e.target.value)}
                            >
                                <option value="unit">Unit</option>
                                <option value="sqrft">Sqrft</option>
                                <option value="inch">Inch</option>
                            </Input>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <span className="plusJakara_medium text_secondary">{unitType === 'sqrft' ? 'Sqrft' : unitType === 'unit' ? 'Unit' : 'Inch'}</span>
                            <Input
                                style={{ padding: '10px' }}
                                className="form-control rounded-3 w-full text_secondary2 plusJakara_medium"
                                type="number"
                                value={unit}
                                onChange={(e) => setUnit(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex justify-between gap-2 w-full">
                        <div className="flex flex-col gap-2 w-full">
                            <span className="plusJakara_medium text_secondary">Per {unitType === 'sqrft' ? 'Sqrft' : unitType === 'unit' ? 'Unit' : 'Inch'}</span>
                            <Input
                                style={{ padding: '10px' }}
                                className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                type="number"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <span className="plusJakara_medium text_secondary">Total Price</span>
                            <Input
                                style={{ padding: '10px' }}
                                className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                type="text"
                                placeholder="Total Price"
                                value={price * unit}
                                readOnly
                            />
                        </div>
                    </div>
                    <button
                        className={`plusJakara_medium py-[12px] w-full rounded-3 ${isDisabled ? 'bg_secondary2 text_white' : 'bg_primary text_white'}`}
                        onClick={handleSave}
                        disabled={isDisabled}
                    >
                        Save
                    </button>
                </ModalBody>
            </Modal> */}
        </>
    );
};

export default ServiceForm;