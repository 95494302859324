import axiosInstance from "../redux/axiosInstance";

export const createOrder = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`order/create`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const createSchedule = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`schedule/create`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const updateSchedule = async (id) => {
    try {
        const res = await axiosInstance.put(`schedule/change/complete/${id}`, {});
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getOrders = async (type, status) => {
    try {
        const res = await axiosInstance.get(`order/${type}/all/${status}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getSpeceficDetail = async (type, id) => {
    try {
        const res = await axiosInstance.get(`${type}/detail/${id}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getInvoices = async (status, type, invoiceType) => {
    try {
        const res = await axiosInstance.get(`order/${status}/${type}/${invoiceType}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const createInvoiceOrder = async ({ data }, id) => {
    try {
        const res = await axiosInstance.put(`order/employee/send-invoice/${id}`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const completeOrder = async (id) => {
    try {
        const res = await axiosInstance.put(`order/customer/update-order/${id}/complete`, {});
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const payInvoice = async (id) => {
    try {
        const res = await axiosInstance.put(`order/customer/pay-invoice/${id}`, {});
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};