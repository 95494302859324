/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import { CircularProgress } from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { message, Modal } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import Autocomplete from 'react-google-autocomplete';
import { FaUserLarge } from "react-icons/fa6";
import { LuUploadCloud } from "react-icons/lu";
import { MdOutlineEdit } from "react-icons/md";
import { TbCurrentLocation } from "react-icons/tb";
import "react-phone-input-2/lib/style.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Form,
    FormFeedback,
    Input,
    Label,
    Row
} from "reactstrap";
import * as Yup from "yup";
import { updateUser } from "../../api/signup";
import { uploadFile } from "../../api/uploadFile";
import { mapmarker } from "../../icons/icon";
import { setLogin, setUserData } from "../../redux/loginForm";


const PersonalInformationForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isLogin = useSelector((state) => state.auth?.isLogin)
    const [fileLoading2, setFileLoading2] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [fileLoading, setFileLoading] = useState(false);
    const [imageUrlCover, setImageUrlCover] = useState(null);
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const userData = useSelector((state) => state.auth?.userData)
    const [isLoading, setisLoading] = useState(false)
    const [latLng, setLatLng] = useState(null);
    const [location, setLocation] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [map, setMap] = useState(null);
    const autocompleteRef2 = useRef()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
        libraries: ["places", "maps"]
    });

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFileLoading(true)
            const formData = new FormData();
            formData.append('image', selectedFile);
            try {
                const res = await uploadFile({ data: formData })
                if (res.image) {
                    setImageUrl(res.image);
                    setFileLoading(false);
                }
            } catch (error) {
                message.error('Failed to upload file');
                setFileLoading(false);
            }
        }
    };

    const handleFileChange2 = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFileLoading2(true)
            const formData = new FormData();
            formData.append('image', selectedFile);
            try {
                const res = await uploadFile({ data: formData })
                if (res.image) {
                    setImageUrlCover(res.image);
                    setFileLoading2(false);
                }
            } catch (error) {
                message.error('Failed to upload file');
                setFileLoading2(false);
            }
        }
    };


    const handlePlaceSelected = (place) => {
        if (place && place.formatted_address) {
            const { city, state, postalCode, country } = extractAddressComponents(place);
            const lat = place.geometry?.location.lat();
            const lng = place.geometry?.location.lng();

            if (!isNaN(lat) && !isNaN(lng)) {
                validation.setFieldValue('address', place.formatted_address);
                validation.setFieldValue('lat', lat);
                validation.setFieldValue('lng', lng);
                validation.setFieldValue('city', city);
                validation.setFieldValue('state', state);
                validation.setFieldValue('country', country);
                validation.setFieldValue('postalCode', postalCode);
            } else {
                validation.setFieldError('address', 'Invalid coordinates');
            }
        } else {
            validation.setFieldError('address', 'Please select a valid address');
        }
    };

    const extractAddressComponents = (place) => {
        let city = '';
        let state = '';
        let country = '';
        let postalCode = '';

        place.address_components.forEach(component => {
            if (component.types.includes("locality")) {
                city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name;
            }
            if (component.types.includes("postal_code")) {
                postalCode = component.long_name;
            }
            if (component.types.includes("country")) {
                country = component.long_name;
            }
        });

        return { city, state, postalCode, country };
    };

    const handleSetLocation = async (latLng) => {
        if (!latLng) return;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const place = results[0];
                const { city, state, postalCode, country } = extractAddressComponents(place);
                validation.setFieldValue('address', place.formatted_address);
                setLocation(place?.formatted_address);
                validation.setFieldValue('lat', latLng.lat);
                validation.setFieldValue('lng', latLng.lng);
                validation.setFieldValue('city', city);
                validation.setFieldValue('state', state);
                validation.setFieldValue('country', country);
                validation.setFieldValue('postalCode', postalCode);
                setShowModal(false);
            } else {
                console.error(`Geocode failed: ${status}`);
                validation.setFieldError('address', 'Failed to retrieve address from coordinates');
            }
        });
    };

    const loadCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLatLng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setLatLng(userLatLng);
                    if (map) {
                        map.panTo(userLatLng);
                    }
                },
                (error) => {
                    console.error("Geolocation failed: ", error);
                }
            );
        }
    };

    const onLoad = (mapInstance) => {
        setMap(mapInstance);
        loadCurrentLocation();
    };

    useEffect(() => {
        if (userData) {
            setLocation(userData?.address)
            setImageUrlCover(userData?.coverPic)
            setImageUrl(userData?.profilePicture)
            validation.setFieldValue('address', userData?.address);
            validation.setFieldValue('name', userData?.about);
            validation.setFieldValue('lat', userData?.location?.coordinates[1]);
            validation.setFieldValue('lng', userData?.location?.coordinates[0]);
            validation.setFieldValue('city', userData?.city);
            validation.setFieldValue('state', userData?.state);
            validation.setFieldValue('postalCode', userData?.zipcode);
            validation.setFieldValue('country', userData?.country);
            if (isLoaded && map) {
                map.panTo({ lat: userData?.location?.coordinates[1], lng: userData?.location?.coordinates[0] });
            }
        }
    }, [userData])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            address: "",
            lat: null,
            lng: null,
            state: "",
            postalCode: "",
            country: "",
        },
        validationSchema: Yup.object().shape({
            name: usertype === 'company'
                ? Yup.string()
                    .min(10, "About must be at least 10 characters")
                    .required("About is required")
                : Yup.string(),
            address: Yup.string()
                .required("Address is required"),
            city: Yup.string()
                .required("City is required"),
            state: Yup.string()
                .required("State is required"),
            postalCode: Yup.string()
                .required("Zip Code is required"),
            country: Yup.string()
                .required("Country is required"),
        }),

        onSubmit: async (values, { resetForm }) => {
            const data = {
                profilePicture: imageUrl,
                coverPic: imageUrlCover,
                address: values.address,
                city: values.city,
                state: values.state,
                location: {
                    type: 'Point',
                    coordinates: [values?.lng, values?.lat],
                },
                zipcode: values.postalCode,
                country: values.country,
                about: values.name
            };
            if (!values?.lat && !values?.lng) {
                message.error('Please select a valid address');
                return;
            }
            setisLoading(true);
            await updateUser({ data: data }).then((res) => {
                if (res.data.success === true) {
                    setisLoading(false);
                    dispatch(setUserData(res.data.user))
                    if (isLogin) {
                        message.success('Profile updated successfully');
                        navigate('/business-profile?selected=overview', { replace: true })
                    } else {
                        if (usertype === 'company') {
                            navigate('/service-info', { replace: true })
                        } else {
                            dispatch(setLogin(true))
                            message.success('Profile updated successfully');
                            navigate('/', { replace: true })
                        }
                    }
                }
            }).catch((err) => {
                message.error('Failed to update profile');
                setisLoading(false);
            })
        }
    });

    return (
        <React.Fragment>
            <Form
                style={{ maxWidth: '800px', width: '100%' }}
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
                className="auth_form">
                <Row className="px-0">
                    <div className="mb-4 position-relative">
                        <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="password">
                            Choose Logo/Profile Image
                        </Label>
                        <div className="flex flex-col items-start mb-3 mt-2 gap-2">
                            <div>
                                <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor="fileInput"
                                    className="cursor-pointer"
                                >
                                    {fileLoading ? (
                                        <div
                                            style={{ width: "120px", height: "120px" }}
                                            className="border rounded-4 bg_light text_primary d-flex justify-content-center align-items-center"
                                        >
                                            <CircularProgress size={20} />
                                        </div>
                                    ) : imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="Preview"
                                            style={{
                                                width: "120px",
                                                height: "120px",
                                                objectFit: "cover",
                                            }}
                                            className="rounded-4 bg_light object-cover"
                                        />
                                    ) : (
                                        <div className="relative w-full">
                                            <div
                                                style={{ width: "120px", height: "120px", backgroundColor: "#eeeeee", border: '1px solid #d3d3d3' }}
                                                className="rounded-4 text_secondary2 relative flex justify-center items-center"
                                            >
                                                <FaUserLarge size={50} />
                                            </div>
                                            <div className="absolute -bottom-1 right-0">
                                                <button type="button" className="bg_primary text_white rounded-3 p-1">
                                                    <MdOutlineEdit size={20} />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </label>
                                <Input
                                    type="file"
                                    accept="image/*"
                                    // required
                                    id="fileInput"
                                    className="visually-hidden"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                    </div>
                    {usertype === 'company' &&
                        <div className="mb-4 position-relative w-100">
                            <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="password">
                                Choose Cover Image
                            </Label>
                            <div className="flex flex-col items-center w-100 mb-3 gap-2">
                                <label
                                    style={{ cursor: "pointer", width: "100%", }}
                                    htmlFor="fileInput2"
                                    className="cursor-pointer"
                                >
                                    {fileLoading2 ? (
                                        <div
                                            style={{ height: '10rem', width: '100%', backgroundColor: "#eeeeee" }}
                                            className="border rounded-4 w-100 text_primary d-flex justify-content-center align-items-center"
                                        >
                                            <CircularProgress size={20} />
                                        </div>
                                    ) : imageUrlCover ? (
                                        <img
                                            src={imageUrlCover}
                                            alt="Preview"
                                            style={{
                                                height: '10rem',
                                                width: '100%',
                                                objectFit: "cover",
                                            }}
                                            className="rounded-4 object-cover"
                                        />
                                    ) : (
                                        <div
                                            style={{ height: '10rem', width: '100%', backgroundColor: "#eeeeee", border: '1px solid #d3d3d3' }}
                                            className="border rounded-4 w-100 flex flex-col justify-center items-center"
                                        >
                                            <LuUploadCloud size={40} />
                                            <span className="text_black plusJakara_medium"><span className="text_primary">Choose file</span> to upload</span>
                                        </div>
                                    )}
                                </label>
                                <Input
                                    type="file"
                                    accept="image/*"
                                    // required
                                    id="fileInput2"
                                    className="visually-hidden"
                                    onChange={handleFileChange2}
                                />
                            </div>
                        </div>}
                    {(usertype === 'company' || usertype === 'employee') && <>
                        <div className="mb-4">
                            <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="name">
                                About
                            </Label>
                            <Input
                                type="textarea"
                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                name="name"
                                id="name"
                                style={{ padding: '14px 24px', height: '150px' }}
                                placeholder="About"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div> </>}
                    <div className="mb-3">
                        <div className="w-full px-0">
                            <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="address">
                                {usertype === 'company' ? 'Business Location' : 'Address'}
                            </Label>
                            <div className="flex justify-between items-center flex-wrap flex-md-nowrap w-full gap-2">
                                {isLoaded && (!location ?
                                    <>
                                        <Autocomplete
                                            id="address"
                                            name="address"
                                            apiKey={process.env.REACT_APP_APIKEY}
                                            className="w-full border search_input rounded-lg plusJakara_regular ps-3 py-[14px]"
                                            ref={autocompleteRef2}
                                            placeholder="Address here..."
                                            options={{
                                                types: ['address'],
                                            }}
                                            onPlaceSelected={(place) => handlePlaceSelected(place)}
                                            onBlur={validation.handleBlur}
                                        />
                                        {validation.touched.address && validation.errors.address && (
                                            <FormFeedback type="invalid" className="plusJakara_regular text-xs">
                                                {validation.errors.address}
                                            </FormFeedback>
                                        )}
                                    </> :
                                    <Input
                                        type="text"
                                        className="form-control w-full rounded-3 text_secondary2 text-sm plusJakara_medium"
                                        name="country"
                                        id="country"
                                        onChange={(e) => setLocation(e.target.value)}
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Country"
                                        value={location}
                                    />)}
                                <button
                                    type="button"
                                    style={{ border: '1.5px solid #FF8E00' }}
                                    className="rounded-3 plusJakara_medium text_primary2 w-full py-[12px] flex justify-center gap-2"
                                    onClick={() => setShowModal(true)}
                                >
                                    <TbCurrentLocation size={20} color='#F6921E' className="input-icon" />
                                    Set Location on Map
                                </button>
                            </div>
                        </div>
                        <div className="flex gap-2 mt-3 items-center flex-wrap flex-lg-nowrap mb-3">
                            <div className="flex flex-col w-full">
                                <Input
                                    type="text"
                                    className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                    name="country"
                                    id="country"
                                    style={{ padding: '14px 16px', }}
                                    placeholder="Country"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.country || ""}
                                    invalid={
                                        validation.touched.country && validation.errors.country ? true : false
                                    }
                                />
                                {validation.touched.country && validation.errors.country ? (
                                    <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                ) : null}
                            </div>
                            <div className="flex flex-col w-full">
                                <Input
                                    type="text"
                                    className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                    name="city"
                                    id="city"
                                    style={{ padding: '14px 16px', }}
                                    placeholder="City"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.city || ""}
                                    invalid={
                                        validation.touched.city && validation.errors.city ? true : false
                                    }
                                />
                                {validation.touched.city && validation.errors.city ? (
                                    <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex gap-2 items-center flex-wrap flex-lg-nowrap mb-3">
                            <div className="flex flex-col w-full">
                                <Input
                                    type="text"
                                    className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                    name="state"
                                    id="state"
                                    style={{ padding: '14px 16px', }}
                                    placeholder="State"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.state || ""}
                                    invalid={
                                        validation.touched.state && validation.errors.state ? true : false
                                    }
                                />
                                {validation.touched.state && validation.errors.state ? (
                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                ) : null}
                            </div>
                            <div className="flex flex-col w-full">
                                <Input
                                    type="text"
                                    className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                    name="postalCode"
                                    id="postalCode"
                                    style={{ padding: '14px 16px', }}
                                    placeholder="Zip"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.postalCode || ""}
                                    invalid={
                                        validation.touched.postalCode && validation.errors.postalCode ? true : false
                                    }
                                />
                                {validation.touched.postalCode && validation.errors.postalCode ? (
                                    <FormFeedback type="invalid">{validation.errors.postalCode}</FormFeedback>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-center">
                        <button className="py-3 rounded-3 w-full bg_primary plusJakara_medium text_white" disabled={isLoading || fileLoading || fileLoading2} type="submit">
                            {isLoading ? <Spinner size="sm" /> :
                                "Submit"}
                        </button>
                    </div>
                </Row>
            </Form>

            <Modal
                zIndex={999}
                width={600}
                open={showModal}
                footer={null}
                onCancel={() => setShowModal(false)}
                centered
            >
                <h5 className="plusJakara_medium mt-3 mb-2 text_primary">Select Location</h5>

                {isLoaded && (
                    <div className="rounded-3 overflow-hidden" style={{ width: '100%', height: '400px' }}>
                        <GoogleMap
                            center={{ lat: latLng?.lat, lng: latLng?.lng }}
                            zoom={10}
                            mapContainerStyle={{ width: '100%', height: '100%' }}
                            onLoad={onLoad}
                        >
                            {latLng && (
                                <Marker
                                    icon={{
                                        url: mapmarker,
                                        scaledSize: new window.google.maps.Size(35, 35),
                                    }}
                                    position={{ lat: latLng.lat, lng: latLng.lng }}
                                    draggable={true}
                                    onDragEnd={(e) => {
                                        const draggedLatLng = {
                                            lat: e.latLng.lat(),
                                            lng: e.latLng.lng(),
                                        };
                                        setLatLng(draggedLatLng);
                                    }}
                                />
                            )}
                        </GoogleMap>
                    </div>
                )}

                <div className="mt-4">
                    <button
                        className="py-3 px-4 rounded-5 bg_primary text_white plusJakara_medium w-full"
                        onClick={() => handleSetLocation(latLng)}
                    >
                        Set Location
                    </button>
                </div>
            </Modal>



        </React.Fragment >
    );
};
export default PersonalInformationForm;
