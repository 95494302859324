/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Check, X } from 'react-feather'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { setAccessToken, setLogin, setUserData, setUserType } from '../../redux/loginForm'

const PricingCard = ({
    title, subTitle, monthly = true, list, price, selected = false
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleProviderSignup = () => {
        dispatch(setLogin(false))
        dispatch(setUserData(null))
        dispatch(setUserType('company'))
        navigate('/login', { replace: true });
        dispatch(setAccessToken(null))
    }

    return (
        <div className={`flex flex-col h-100 ${selected && "selected-package"} max-w-[20rem] min-w-[15rem] mx-auto justify-between rounded-3xl border border-solid border-subtle px-[23px] pb-[23px] pt-[21px] shadow-[0px_1px_2px_-1px_rgba(28,_40,_64,_0.08),_0px_2px_4px_0px_rgba(28,_40,_64,_0.04)]`}>
            <div className="flex flex-col">
                <header className="text-xl text_secondary plusJakara_bold">
                    {title}
                </header>
                <span className='text_secondary2 plusJakara_medium text-sm'>
                    {subTitle}
                </span>
                <div className="mt-4 lg:mt-8">
                    <div className="flex items-start gap-2">
                        <h3 className='text_primary plusJakara_semibold'>{price}</h3>
                    </div>
                    <div className="mt-0.5 text-xs plusJakara_medium text-accent">
                        Per user/month, billed{' '}
                        <span className="inline-grid">
                            <span className={`col-start-1 row-start-1 transition-opacity ease-in-out duration-150 ${monthly ? "opacity-100" : "opacity-0"} `}>
                                monthly
                            </span>
                            <span className={`col-start-1 row-start-1 ${monthly ? "opacity-0" : "opacity-100"}  transition-opacity ease-in-out duration-150`}>
                                annually
                            </span>
                        </span>
                    </div>
                </div>
                <div className="mt-4 text-sm plusJakara_semibold text_secondary lg:mt-8">For very small teams</div>
                <ul className="mt-2.5 px-0 flex flex-col gap-y-2.5">
                    {list?.map((item, index) => (
                        <li className="flex items-start gap-2" key={index}>
                            <div className="mt-px h-[24px] plusJakara_medium flex justify-center items-center w-[24px] shrink-0 rounded-md bg-light p-0.5 text-accent">
                                {item.check === 0 && <X size={14} />}
                                {item.check === 1 && <Check size={16} />}
                            </div>
                            <div className="text-sm plusJakara_medium" aria-label="Real-time contact syncing">
                                {item.item}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mt-1 flex flex-col items-stretch">
                <button
                    onClick={handleProviderSignup}
                    className="justify-center items-center gap-x-2 inline-flex bg_primary text_white rounded-[10px] py-[10px] text-sm pl-[15px] pr-[15px]"
                >
                    Get Started
                </button>
            </div>
        </div >
    )
}

export default PricingCard