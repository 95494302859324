/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { FiSearch } from "react-icons/fi";
import { SlLocationPin } from "react-icons/sl";
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'reactstrap';
import { cleaning1, dots } from '../../icons/icon';
import { Col } from 'antd';

const HomeHeader2 = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate()

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        navigate('/nearby-services', { state: { searchQuery: searchQuery } })
    };
    return (
        <div className='bg_primary relative cleaning_header2 mb-5'>
            <Container className='flex relative justify-between flex-wrap flex-lg-nowrap items-center'>
                <div className="absolute hidden xl:flex flex-col gap-2 left-10 top-2">
                    <img src={dots} style={{ height: '50px', width: 'auto' }} alt="" />
                    <img src={dots} style={{ height: '50px', width: 'auto' }} alt="" />
                </div>
                <Col xs={24} lg={12}>
                    <div className="flex flex-col max-w-2xl gap-4 items-start">
                        <h1 className="plusJakara_semibold text-2xl sm:text-2xl md:text-3xl mt-4 xl:text-5xl mb-0 text_white">Specialized, efficient, and thorough Request services</h1>
                        <h6 style={{ color: '#f4f4f4' }} className="plusJakara_regular text-sm xl:text-lg">Experience top-notch home cleaning that saves you time, energy, and money. Our expert teams ensures a spotless, refreshed home with minimal effort. Enjoy home service and comfortable space without the hassle or high costs.</h6>
                        {/* <button className="bg_white px-4 py-3 rounded-2 text_primary plusJakara_medium">Contact Us</button> */}
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <div className="relative rounded-full m-auto image_header" style={{ backgroundColor: "#DDAE02" }}>
                        <img src={cleaning1} className='relative w-100 h-100' style={{ backgroundColor: "#DDAE02", borderRadius: '50%', objectFit: 'cover', zIndex: 1, }} alt="" />
                        <div className="absolute bottom-10">
                            <img src={dots} style={{ height: '60px', width: 'auto' }} alt="" />
                        </div>
                    </div>
                </Col>
                <div className="absolute -bottom-10 w-full flex justify-center z-50">
                    <Form onSubmit={handleSearchSubmit} className="bg_white rounded-3 justify-center border stroke-gray-900 max-w-2xl flex gap-3 p-2 items-center w-full">
                        <div className="input-wrapper">
                            <SlLocationPin size={20} color='#F6921E' className="input-icon" />
                            <Input
                                required
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ padding: "12px 24px 12px 40px" }}
                                placeholder='Search by name, address, city, state etc'
                                className='plusJakara_regular text_secondary2'
                            />
                        </div>
                        {/* <div className="input-wrapper">
                            <TbCurrentLocation size={20} color='#F6921E' className="input-icon" />
                            <Input
                                required
                                style={{ padding: "12px 24px 12px 40px" }}
                                placeholder='Locate me'
                                className='plusJakara_regular text_secondary2'
                            />
                        </div> */}
                        <button type='submit' style={{ padding: "12px 16px" }} className="bg_primary text-center max-md:text-sm text_white plusJakara_medium flex gap-2 items-center rounded-2">
                            <FiSearch size={18} color='#fff' /> <span className="plusJakara_medium hidden md:block">Search</span>
                        </button>
                    </Form>
                </div>
            </Container>
        </div>
    )
}

export default HomeHeader2;