/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";

import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import HeaderNavigation from "./common/headerNavigation";

const BlogDetail = () => {
  const { id } = useParams()
  const { state } = useLocation()
  const blogData = state?.blogData || null
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  return (
    <main className='pb-5'>
      <HeaderNavigation mainPage='/all-blogs' subPage="Blog Detail"
        subPageAvail={true}
        page="Blogs"
        serviceName={`${blogData?.title}`} />
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col lg={12} md={12}>
            <img src={blogData?.image} style={{ height: "25rem", width: '100%', objectFit: 'cover', borderRadius: '20px' }} alt="" />
            <div className="text-center section-title">
              <div className="my-4">
                <h3 className="title plusJakara_medium ">
                  {blogData?.title}
                </h3>
                <h6 className="text-end text-muted">Last updated on {moment(blogData?.createdAt).format('ddd DD MMM YYYY')}</h6>
              </div>
              <Row className="justify-content-center ">
                <Col lg={24} className="section-title">
                  <div dangerouslySetInnerHTML={{ __html: blogData?.description }} className="mt-3 plusJakara_regular blogContent"></div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

      </Container>
    </main>
  );
}
export default BlogDetail
