import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Autoplay, FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getBlogCategory } from '../../api/blogs';
import '../../style/swiper.css';

const BlogCategories = ({ onSelectCategory, selectedCategory }) => {
    const [loading, setloading] = useState(false);
    const [categories, setcategories] = useState([]);

    const handleFetchCategories = async () => {
        setloading(true);
        await getBlogCategory()
            .then((res) => {
                setloading(false);
                const fetchedCategories = res.success ? res.categories : [];
                setcategories([{ _id: 'all', name: 'All' }, ...fetchedCategories]);
            })
            .catch(() => setloading(false));
    };

    useEffect(() => {
        handleFetchCategories();
    }, []);

    return (
        <Container style={{ paddingTop: '2rem' }}>
            {loading ? (
                <div className='my-5 flex justify-center w-full items-center'>
                    <CircularProgress size={24} style={{ color: "#000" }} />
                </div>
            ) : (
                categories.length === 0 ? (
                    <div className='flex justify-center items-center w-full my-5'>
                        <span className="plusJakara_medium md:text-lg">No Category Found</span>
                    </div>
                ) : (
                    <div className="items_swiper p-2 relative">
                        <h6 className="text-2xl lg:text-3xl w-fit absolute mb-0 plusJakara_bold text_primary">Categories</h6>
                        <Swiper
                            freeMode={true}
                            modules={[FreeMode, Autoplay, Navigation]}
                            navigation={true}
                            spaceBetween={10}
                            pagination={{ clickable: true }}
                            className="mySwiper d-flex w-100 gap-3 relative"
                            slidesPerView={'auto'}
                        >
                            {categories.map((category) => (
                                <SwiperSlide
                                    key={category._id}
                                    style={{
                                        border: selectedCategory?._id === category._id ? '1.5px solid #003F7D' : '0.25px solid #ccc',
                                        width: 'fit-content'
                                    }}
                                    className='pe-4 relative gap-2 cursor-pointer whitespace-nowrap w-fit rounded-2 flex justify-center px-4 py-2 plusJakara_medium text_primary'
                                    onClick={() => onSelectCategory(category)}
                                >
                                    {category.name}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>)
            )}
        </Container>
    );
};

export default BlogCategories;
