/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Col, message, Modal, Row } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import Autocomplete from 'react-google-autocomplete';
import { TbCurrentLocation } from 'react-icons/tb';
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FormFeedback, Input, Label } from 'reactstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import * as Yup from "yup";
import { mapmarker } from '../icons/icon';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
import { useSelector } from "react-redux";

const SendEstimates = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const userData = useSelector((state) => state.auth?.userData)
    const navigate = useNavigate()
    const { state } = useLocation()
    const serviceData = state?.serviceDetail || null
    const [latLng, setLatLng] = useState(null);
    const [location, setLocation] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [map, setMap] = useState(null);
    const autocompleteRef2 = useRef()
    const libraries = ["places", "maps"]
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
        libraries
    });

    useEffect(() => {
        if (userData) {
            setLocation(userData?.address)
            validation.setFieldValue('address', userData?.address);
            validation.setFieldValue('lat', userData?.location?.coordinates[1]);
            validation.setFieldValue('lng', userData?.location?.coordinates[0]);
            validation.setFieldValue('city', userData?.city);
            validation.setFieldValue('state', userData?.state);
            validation.setFieldValue('postalCode', userData?.zipcode);
            validation.setFieldValue('fname', userData?.name);
            validation.setFieldValue('phone', userData?.phone);
            validation.setFieldValue('country', userData?.country);
            validation.setFieldValue('country', userData?.country);
            if (isLoaded && map) {
                map.panTo({ lat: userData?.location?.coordinates[1], lng: userData.location?.coordinates[0] });
            }
        }
    }, [userData])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            service: '',
            to_id: '',
            category: '',
            fname: '',
            lname: '',
            phone: '',
            location: {
                lat: '',
                lng: '',
                address: '',
                city: '',
                zip: '',
                state: '',
                country: ''
            },
            date: '',
            time: '',
            services: [],
            description: '',
            note: ''
        },
        validationSchema: Yup.object({
            service: Yup.string().required('Service is required'),
            fname: Yup.string().required('First name is required'),
            lname: Yup.string().required('Last name is required'),
            location: Yup.object({
                lat: Yup.number().required('Latitude is required'),
                lng: Yup.number().required('Longitude is required'),
                address: Yup.string().required('Address is required'),
                city: Yup.string().required('City is required'),
                zip: Yup.string().required('Zip is required'),
                state: Yup.string().required('State is required'),
                country: Yup.string().required('Country is required')
            }),
            date: Yup.string().required('Date is required'),
            time: Yup.string().required('Time is required'),
            services: Yup.array().of(Yup.string()).min(1, 'At least one service is required'),
            description: Yup.string()
                .min(10, 'Description must be at least 10 characters')
                .required('Description is required'),
            note: Yup.string()
                .min(10, 'Note must be at least 10 characters')
                .required('Note is required')
        }),
        onSubmit: async (values, { resetForm }) => {
            const data = {
                ...values
            };
            message.success('Estimate sent successfully');
            navigate('/orders', { replace: true });
        }
    });


    const extractAddressComponents = (place) => {
        let city = '';
        let state = '';
        let country = '';
        let postalCode = '';

        place.address_components.forEach(component => {
            if (component.types.includes("locality")) {
                city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name;
            }
            if (component.types.includes("postal_code")) {
                postalCode = component.long_name;
            }
            if (component.types.includes("country")) {
                country = component.long_name;
            }
        });

        return { city, state, postalCode, country };
    };

    const handlePlaceSelected = (place) => {
        if (place && place.formatted_address) {
            const { city, state, postalCode, country } = extractAddressComponents(place);
            const lat = place.geometry?.location.lat();
            const lng = place.geometry?.location.lng();

            if (!isNaN(lat) && !isNaN(lng)) {
                validation.setFieldValue('address', place.formatted_address);
                validation.setFieldValue('lat', lat);
                validation.setFieldValue('lng', lng);
                validation.setFieldValue('city', city);
                validation.setFieldValue('state', state);
                validation.setFieldValue('country', country);
                validation.setFieldValue('postalCode', postalCode);
            } else {
                validation.setFieldError('address', 'Invalid coordinates');
            }
        } else {
            validation.setFieldError('address', 'Please select a valid address');
        }
    };


    const handleSetLocation = async (latLng) => {
        if (!latLng) return;

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const place = results[0];
                const { city, state, postalCode, country } = extractAddressComponents(place);
                validation.setFieldValue('address', place.formatted_address);
                setLocation(place?.formatted_address);
                validation.setFieldValue('lat', latLng.lat);
                validation.setFieldValue('lng', latLng.lng);
                validation.setFieldValue('city', city);
                validation.setFieldValue('state', state);
                validation.setFieldValue('country', country);
                validation.setFieldValue('postalCode', postalCode);
                setShowModal(false);
            } else {
                console.error(`Geocode failed: ${status}`);
                validation.setFieldError('address', 'Failed to retrieve address from coordinates');
            }
        });
    };

    const loadCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLatLng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setLatLng(userLatLng);
                    if (map) {
                        map.panTo(userLatLng);
                    }
                },
                (error) => {
                    console.error("Geolocation failed: ", error);
                }
            );
        }
    };

    const onLoad = (mapInstance) => {
        setMap(mapInstance);
        loadCurrentLocation();
    };

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Send Estimate' serviceName={`${serviceData?.name}`} />
            <Container className='py-5'>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <Row gutter={16} className='w-full justify-between'>
                        <Col xs={24} md={12} lg={11}>
                            <div className="mb-4 w-full">
                                <h5 className="text_primary plusJakara_semibold">Personal Information</h5>
                                <div className="mb-3">
                                    <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="fname">
                                        First Name
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="fname"
                                        id="fname"
                                        style={{ padding: '12px 16px', }}
                                        placeholder='First Name'
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.fname || ""}
                                        invalid={
                                            validation.touched.fname && validation.errors.fname ? true : false
                                        }
                                    />
                                    {validation.touched.fname && validation.errors.fname ? (
                                        <FormFeedback type="invalid">{validation.errors.fname}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="lname">
                                        Last name
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="lname"
                                        id="lname"
                                        style={{ padding: '12px 16px', }}
                                        placeholder="Enter Last Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.lname || ""}
                                        invalid={
                                            validation.touched.lname && validation.errors.lname ? true : false
                                        }
                                    />
                                    {validation.touched.lname && validation.errors.lname ? (
                                        <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="phone">
                                        Phone
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="phone"
                                        readOnly
                                        id="phone"
                                        style={{ padding: '12px 16px', }}
                                        placeholder="Enter Last Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.phone || ""}
                                        invalid={
                                            validation.touched.phone && validation.errors.phone ? true : false
                                        }
                                    />
                                    {validation.touched.phone && validation.errors.phone ? (
                                        <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={11}>
                            <div className="w-full px-0">
                                <h5 className="text_primary plusJakara_semibold">Project / Estimate Location</h5>
                                <button
                                    type="button"
                                    style={{ border: '2px solid #FF8E00' }}
                                    className="rounded-5 my-3 plusJakara_medium text_primary2 w-full py-[12px] flex justify-center gap-2"
                                    onClick={() => setShowModal(true)}
                                >
                                    <TbCurrentLocation size={24} color='#F6921E' className="input-icon" />
                                    Set Location on Map
                                </button>
                                {location ?
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                        name="country"
                                        id="country"
                                        onChange={(e) => setLocation(e.target.value)}
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Country"
                                        value={location}
                                    /> :
                                    <>
                                        <Autocomplete
                                            id="address"
                                            name="address"
                                            apiKey={process.env.REACT_APP_APIKEY}
                                            className="w-full border search_input rounded-lg plusJakara_regular ps-3 py-[14px]"
                                            ref={autocompleteRef2}
                                            defaultValue={''}
                                            placeholder="Address here..."
                                            options={{
                                                types: ['address'],
                                            }}
                                            onPlaceSelected={(place) => handlePlaceSelected(place)}
                                            onBlur={validation.handleBlur}
                                        />
                                        {validation.touched.address && validation.errors.address && (
                                            <FormFeedback type="invalid" className="plusJakara_regular text-xs">
                                                {validation.errors.address}
                                            </FormFeedback>
                                        )}
                                    </>}
                            </div>
                            <div className="flex gap-2 mt-3 items-center flex-wrap flex-lg-nowrap mb-3">
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                        name="country"
                                        id="country"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Country"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.country || ""}
                                        invalid={
                                            validation.touched.country && validation.errors.country ? true : false
                                        }
                                    />
                                    {validation.touched.country && validation.errors.country ? (
                                        <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                        name="city"
                                        id="city"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="City"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.city || ""}
                                        invalid={
                                            validation.touched.city && validation.errors.city ? true : false
                                        }
                                    />
                                    {validation.touched.city && validation.errors.city ? (
                                        <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex gap-2 items-center flex-wrap flex-lg-nowrap mb-3">
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                        name="state"
                                        id="state"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="State"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.state || ""}
                                        invalid={
                                            validation.touched.state && validation.errors.state ? true : false
                                        }
                                    />
                                    {validation.touched.state && validation.errors.state ? (
                                        <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                        name="postalCode"
                                        id="postalCode"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Zip"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.postalCode || ""}
                                        invalid={
                                            validation.touched.postalCode && validation.errors.postalCode ? true : false
                                        }
                                    />
                                    {validation.touched.postalCode && validation.errors.postalCode ? (
                                        <FormFeedback type="invalid">{validation.errors.postalCode}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Col xs={24} md={12} lg={11}>
                        <div className="flex gap-3 mb-3 flex-col">
                            <h5 className="text_primary mb-0 plusJakara_semibold">Select Service</h5>
                            <div className="flex flex-col gap-2 w-full">
                                {serviceData?.features?.map((item, i) => (
                                    <div className="border bg_light rounded-4 p-3 flex justify-between items-center">
                                        <h6 className="text_secondary mb-0 plusJakara_regular">{item?.title}</h6>
                                        <Input type='checkbox' />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Row gutter={16} className='w-full justify-between'>
                        <Col xs={24} md={12} lg={11}>
                            <div className="flex gap-3 flex-col">
                                <h5 className="text_primary mb-0 plusJakara_semibold">Description</h5>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="textarea"
                                        className="form-control rounded-4 text_secondary2 plusJakara_medium"
                                        name="description"
                                        id="description"
                                        style={{ padding: '16px 24px', height: '200px', border: '0.25px solid #D4D4D4' }}
                                        placeholder="description"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.description || ""}
                                        invalid={
                                            validation.touched.description && validation.errors.description ? true : false
                                        }
                                    />
                                    {validation.touched.description && validation.errors.description ? (
                                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={11}>
                            <div className="flex gap-3 flex-col">
                                <h5 className="text_primary mb-0 plusJakara_semibold">Additional Notes</h5>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="textarea"
                                        className="form-control rounded-4 text_secondary2 plusJakara_medium"
                                        name="note"
                                        id="note"
                                        style={{ padding: '16px 24px', height: '200px', border: '0.25px solid #D4D4D4' }}
                                        placeholder="Note"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.note || ""}
                                        invalid={
                                            validation.touched.note && validation.errors.note ? true : false
                                        }
                                    />
                                    {validation.touched.note && validation.errors.note ? (
                                        <FormFeedback type="invalid">{validation.errors.note}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="flex justify-center md:justify-end mt-4 w-full">
                        <button type='submit' className="bg_primary py-3 rounded-5 px-20 plusJakara_medium text_white">Submit for Estimate</button>
                    </div>
                </Form>
            </Container>

            <Modal
                zIndex={999}
                width={600}
                open={showModal}
                footer={null}
                onCancel={() => setShowModal(false)}
                centered
            >
                <h5 className="plusJakara_medium mt-3 mb-2 text_primary">Select Location</h5>

                {isLoaded && (
                    <div className="rounded-3 overflow-hidden" style={{ width: '100%', height: '400px' }}>
                        <GoogleMap
                            center={{ lat: latLng?.lat, lng: latLng?.lng }}
                            zoom={10}
                            mapContainerStyle={{ width: '100%', height: '100%' }}
                            onLoad={onLoad}
                        >
                            {latLng && (
                                <Marker
                                    icon={{
                                        url: mapmarker,
                                        scaledSize: new window.google.maps.Size(30, 30),
                                    }}
                                    position={{ lat: latLng.lat, lng: latLng.lng }}
                                    draggable={true}
                                    onDragEnd={(e) => {
                                        const draggedLatLng = {
                                            lat: e.latLng.lat(),
                                            lng: e.latLng.lng(),
                                        };
                                        setLatLng(draggedLatLng);
                                    }}
                                />
                            )}
                        </GoogleMap>
                    </div>
                )}

                <div className="mt-4">
                    <button
                        className="py-3 px-4 rounded-5 bg_primary text_white plusJakara_medium w-full"
                        onClick={() => handleSetLocation(latLng)}
                    >
                        Set Location
                    </button>
                </div>
            </Modal>

        </ main >
    );
};

export default SendEstimates;
