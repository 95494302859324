/* eslint-disable no-unused-vars */
import { message, Modal } from 'antd';
import React, { useState } from 'react';
import { Plus } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../style/swiper.css';
// import * as Yup from "yup"
import { CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import { FaEye } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import {
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Row
} from "reactstrap";
import * as Yup from 'yup';
import DTables from "../DataTable/DTable";
import HeaderNavigation from './common/headerNavigation';


const ProposalTable = ({ showButtons = true }) => {
    const { state } = useLocation()
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [modalOpen, setmodalOpen] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [selectedItem, setselectedItem] = useState(null)
    const [showNotifyModal, setshowNotifyModal] = useState(false)
    const navigate = useNavigate();

    const handleCancel = () => {
        message.success('Estimate confirmed Successfully')
        setmodalOpen(false)
        navigate(-1)
    }

    const handleSendNotify = (row) => {
        setshowNotifyModal(true)
        setselectedItem(row)
    };


    const validationSchema = Yup.object().shape({
        notification: Yup.string()
            .required("Please enter a Message")
    });
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            notification: "",
        },
        validationSchema,

        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            navigate("/reset-password", { state: { data: "true" } });
            setisLoading(false);
        },
    });


    const handleConfirm = () => {
        message.success('Contract sent Successfully')
        setmodalOpen(false)
        navigate(-1)
    }

    const columns = [
        {
            name: 'Proposal #',
            sortable: true,
            cell: row => (
                <button className='hover:text-blue-300 hover:underline' onClick={() => navigate('/proposals/987382982', { state: { proposalData: row } })}>
                    {row?.orderId}
                </button>
            ),
            minWidth: '120px'
        },
        {
            name: 'Date/Time',
            sortable: true,
            selector: row => row.dateTime,
            minWidth: '150px'
        },
        {
            name: usertype === 'company' ? 'Customer' : 'Service Provider',
            sortable: true,
            selector: row => row.customerName,
            minWidth: '150px'
        },
        {
            name: 'Status',
            sortable: true,
            selector: row => (
                <div className="status-badge px-2 py-1 text-center rounded-2" style={{ backgroundColor: '#E6E9F4', color: '#5A607F', minWidth: '100px' }}>
                    {usertype === 'company' ? (row?.isCreated ? 'Sent to Customer' : row.status) : "Waiting for Estimate"}
                </div>
            ),
            minWidth: '120px'
        },
        ...(usertype === 'company' ? [
            {
                name: 'Amount',
                sortable: true,
                selector: row => row.isCreated ? ('$' + row?.amount) : '-',
                minWidth: '100px'
            },
            {
                name: 'Notification',
                sortable: false,
                cell: row => (
                    <div className="flex">
                        <button className="flex justify-center gap-1 items-center bg_primary rounded-2 px-2 py-2" onClick={() => handleSendNotify(row)}>
                            <span className="text_white whitespace-nowrap plusJakara_regular">Send Notification</span>
                        </button>
                    </div >
                ),
                minWidth: '150px'
            },
            {
                name: 'Action',
                sortable: false,
                cell: row => (
                    <div className="flex">
                        {row.isCreated ? (
                            <button onClick={() => handleViewDetails(row)} style={{ backgroundColor: '#f4f4f4', padding: '6px 8px', minWidth: '150px' }} className="flex justify-center gap-1 items-center rounded-2">
                                <span className="text_black whitespace-nowrap plusJakara_regular">View Proposal</span>
                            </button>
                        ) : (
                            <button style={{ backgroundColor: '#F6921E2B', padding: '4px 8px', minWidth: '150px' }} className="flex justify-center gap-1 items-center rounded-2" onClick={() => handleViewDetails(row)}>
                                <div className="bg_white p-1 rounded-5">
                                    <Plus className='text_primary2' size={16} />
                                </div>
                                <span className="text_secondary whitespace-nowrap plusJakara_regular">Create Estimate</span>
                            </button>
                        )}
                    </div>
                ),
                minWidth: '150px'
            }
        ] : []),
        {
            name: 'View Proposal',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '50px'
        }
    ];

    const data = [
        { orderId: '#12512B', dateTime: 'May 5, 4:20 PM', customerName: 'Tom Anderson', status: 'Pending', isCreated: false, amount: '49.90' },
        { orderId: '#12523C', dateTime: 'May 5, 4:15 PM', customerName: 'Jayden Walker', status: 'Pending', isCreated: false, amount: '34.36' },
        { orderId: '#51232A', dateTime: 'May 5, 4:15 PM', customerName: 'Inez Kim', status: 'Pending', isCreated: true, amount: '5.51' },
        { orderId: '#23534D', dateTime: 'May 5, 4:12 PM', customerName: 'Francisco Henry', status: 'Pending', isCreated: false, amount: '29.74' },
        { orderId: '#51232C', dateTime: 'May 5, 4:12 PM', customerName: 'Violet Phillips', status: 'Pending', isCreated: true, amount: '23.06' },
        { orderId: '#35622A', dateTime: 'May 5, 4:12 PM', customerName: 'Rosetta Becker', status: 'Pending', isCreated: false, amount: '87.44' },
        { orderId: '#34232D', dateTime: 'May 5, 4:10 PM', customerName: 'Dean Love', status: 'Pending', isCreated: false, amount: '44.55' },
        { orderId: '#56212D', dateTime: 'May 5, 4:08 PM', customerName: 'Nettie Tyler', status: 'Pending', isCreated: true, amount: '36.79' },
        { orderId: '#76543E', dateTime: 'May 5, 4:08 PM', customerName: 'Lora Weaver', status: 'Pending', isCreated: false, amount: '28.78' }
    ];

    const handleViewDetails = (row) => {
        navigate('/proposals/987382982', { state: { proposalData: row } })
    };

    return (
        <main className={`${usertype === 'company' ? 'p-5' : 'pb-5'} w-full`}>
            {usertype !== 'company' &&
                <HeaderNavigation page='Proposal' serviceName='All Proposals' />}
            <Container className={`${usertype === 'company' ? '' : 'mt-5'}`}>
                <div className="flex w-full flex-col gap-4">
                    {usertype === 'company' &&
                        <div className="flex gap-3 items-center justify-between w-full">
                            <h4 className="text_black plusJakara_semibold">All Proposals</h4>
                        </div>}
                    <DTables columns={columns} data={data} />
                </div>
            </Container>
            <Modal
                centered
                open={modalOpen}
                footer={null}
                closeIcon={false}
                width={400}
                onCancel={() => setmodalOpen(false)}
            >
                <div className="flex w-full gap-2 items-center flex-col">
                    <h4 className="text_primary text-center mb-0 plusJakara_bold">Create Contract</h4>
                    <h5 className="text_secondary2 text-center plusJakara_regular">Do you want to create the Contract for this estimate</h5>
                    <div className="flex gap-3 mt-2 items-center justify-between w-full">
                        <button
                            onClick={handleCancel}
                            style={{ border: '2.06px solid #003F7D' }}
                            className="py-3 rounded-5 w-full bg_white plusJakara_medium text_primary"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleConfirm}
                            className="py-3 rounded-5 w-full bg_primary plusJakara_medium text_white"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                open={showNotifyModal}
                footer={null}
                maskClosable={false}
                // width={800}
                zIndex={9999}
                onCancel={() => setshowNotifyModal(false)}
            >
                <div>
                    <h4 className="text_primary text-center mb-4 plusJakara_bold">Send Notification</h4>
                    <Form
                        style={{ maxWidth: '600px', width: '100%' }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        className=" mt-4 auth_form">
                        <Row>
                            <div className="my-3 px-0">
                                {/* <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="notification">
                                    Notification
                                </Label> */}
                                <Input
                                    type="text"
                                    className="form-control rounded-3"
                                    name="notification"
                                    id="notification"
                                    style={{ padding: '12px 16px', }}
                                    placeholder="Enter your notification"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.notification || ""}
                                    invalid={
                                        validation.touched.notification && validation.errors.notification ? true : false
                                    }
                                />
                                {validation.touched.notification && validation.errors.notification ? (
                                    <FormFeedback type="invalid">{validation.errors.notification}</FormFeedback>
                                ) : null}
                            </div>
                            <Col lg={12} className="mb-0 mt-3 px-0">
                                <div className="d-grid">
                                    <button className="py-3 rounded-5 bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                                        {isLoading ? <CircularProgress size={20} className='text_white' /> :
                                            "Send"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>


        </ main >
    );
};
export default ProposalTable;
