/* eslint-disable no-unused-vars */
import { message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'reactstrap';
import { getMyEmployee } from '../../api/employee';
import { assignOrder } from '../../api/estimateApi';
import EmployeeCard from './employeeCard';
import { CircularProgress } from '@mui/material';

const AssignEmployee = ({ setmodalOpen, modalOpen, assignUser }) => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [employee, setEmployee] = useState([]);
    const [selectEmployees, setSelectEmployees] = useState([]);
    const userData = useSelector((state) => state.auth?.userData);
    const navigate = useNavigate();

    const fetchEmployee = async () => {
        setLoading2(true);
        try {
            const res = await getMyEmployee();
            if (res.success) {
                setEmployee(res.users);
            } else {
                setEmployee([]);
            }
        } catch (err) {
            setEmployee([]);
        } finally {
            setLoading2(false);
        }
    };

    useEffect(() => {
        fetchEmployee();
    }, []);

    const handleCloseModal = () => {
        setmodalOpen(false);
        setSelectEmployees([]);
    }

    const handleSelectEmployee = (employee, isChecked, task) => {
        setSelectEmployees((prevState) => {
            const filteredEmployees = prevState.filter(emp => emp.user !== employee._id);
            if (isChecked) {
                if (task.length > 0) {
                    return [...filteredEmployees, { user: employee._id, task }];
                }
            }
            return filteredEmployees;
        });
    };
    console.log(employee);

    const allEmployeesHaveTasks = selectEmployees.length > 0 && selectEmployees.every(emp => emp.task.length > 0);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            employees: selectEmployees
        }
        try {
            const res = await assignOrder({ data: data }, assignUser?._id);
            if (res.success) {
                setmodalOpen(false);
                navigate('/orders');
                setSelectEmployees([]);
                message.success('Employees Assign successfully')
            } else {
                message.error("Failed to Assign Employees");
                setSelectEmployees([]);
            }
        } catch (err) {
            message.error(err.response.data.message);
            setSelectEmployees([]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            centered
            open={modalOpen}
            footer={null}
            maskClosable={false}
            width={650}
            zIndex={9999}
            onCancel={handleCloseModal}
        >
            <div className="flex w-full gap-2 items-center flex-col" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                <h4 className="text_primary text-center mb-4 plusJakara_bold">Select Employees for Assign Order</h4>
                <Form className='w-full' onSubmit={handleSubmit}>
                    <div className="flex p-2 flex-col gap-3 w-full">
                        {!employee || employee.length === 0 ?
                            <div className='my-3 flex justify-center items-center w-full'>
                                <h5 className="text_black text-center mb-4 plusJakara_semibold">No Employee Found</h5>
                            </div> :
                            employee?.map((item, i) => (
                                <EmployeeCard
                                    key={i}
                                    item={item}
                                    labor={assignUser?.estimate?.labour || []}
                                    handleSelectEmployee={handleSelectEmployee}
                                />
                            ))}
                    </div>
                    <div className="flex justify-end mt-4 w-full">
                        <button
                            disabled={loading || !allEmployeesHaveTasks}
                            type='submit'
                            className="bg_primary py-2 rounded-3 px-5 plusJakara_medium text_white"
                        >
                            {loading ? <Spinner size='sm' color='#fff' /> : 'Assign'}
                        </button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default AssignEmployee;
