/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CircularProgress } from '@mui/material';
import { DatePicker, Modal } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Row
} from "reactstrap";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import * as Yup from "yup";
import { getContract } from '../api/estimateApi';
import DTables from "../DataTable/DTable";
import { avataruser } from '../icons/icon';
import '../style/swiper.css';
import CreateContract from './common/createContract';
import HeaderNavigation from './common/headerNavigation';
import StripeConnectError from './common/stripeConnectError';
import UpdrageModal from './common/upgradeModal';
import { calculateFinalPrice } from './estimate/calculateFinalPrice';
import { useSocket } from './messages/socketProvider';
const { RangePicker } = DatePicker;

const ContractTable = () => {
    const navigate = useNavigate()
    const [modalOpen, setmodalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setselectedItem] = useState(null)
    const [showNotifyModal, setshowNotifyModal] = useState(false)
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [loading, setloading] = useState([])
    const [contracts, setContracts] = useState([])
    const socket = useSocket();
    const [modal, setmodal] = useState(false)
    const userData = useSelector((state) => state.auth?.userData)

    useEffect(() => {
        if ((userData?.sub_type === 'free' || userData?.sub_type === 'basic') && usertype === 'company') {
            setmodal(true)
        } else {
            setmodal(false)
        }
    }, [userData])

    useEffect(() => {
        if (socket) {
            socket.emit('seen-contract', {});
        }
    }, [socket])

    const fetchContracts = async () => {
        setloading(true)
        await getContract(usertype === 'company' ? 'employee' : 'customer')
            .then((res) => {
                if (res.success === true) {
                    setContracts(res.orders)
                    setloading(false)
                } else {
                    setloading(false)
                    setContracts([])
                }
            }).catch((err) => {
                setloading(false)
                setContracts([])
            })
    }

    useEffect(() => {
        fetchContracts()
    }, [])

    const handleViewContract = (row) => {
        setmodalOpen(true)
        setselectedItem(row)
    }

    const validationSchema = Yup.object().shape({
        notification: Yup.string()
            .required("Please enter a Message")
    });
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            notification: "",
        },
        validationSchema,
    });

    const handleViewDetails = (row) => {
        navigate(`/contracts/${row?._id}`, { state: { contractData: row } })
    };

    const columns = [
        {
            name: usertype === 'company' ? 'Customer' : 'Service Provider',
            sortable: true,
            minWidth: '220px',
            cell: (row) => (
                <div className="flex gap-2 items-center">
                    <div style={{ minWidth: '40px' }}>
                        <img src={(row?.user?.profilePicture || row?.to_id?.profilePicture) || avataruser} style={{ width: '35px', height: '35px', borderRadius: '50%', objectFit: 'cover' }} alt="" />
                    </div>
                    <span style={{ fontSize: '14px' }} className="plusJakara_semibold">{row.user?.name || row.to_id?.name}</span>
                </div>
            )
        },
        {
            name: 'Project Start Date',
            sortable: true,
            selector: row => moment(row?.date).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Project End Date',
            sortable: true,
            selector: row => moment(row?.endDate).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Booking Date',
            sortable: true,
            selector: row => moment(row?.bookingDate).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        // {
        //     name: 'Status',
        //     // sortable: true,
        //     cell: row => (
        //         <div className="px-2 py-1 w-fit text-center rounded-2" style={{ backgroundColor: '#E6E9F4', color: '#5A607F' }}>
        //             {usertype === 'company' ? 'Waiting for Customer Approval' : 'Pending'}
        //         </div>
        //     ),
        //     // minWidth: '200px'
        // },
        {
            name: 'Description',
            sortable: true,
            cell: row => (
                <span className='plusJakara_medium line-clamp-2'>{row?.description}
                </span>
            ),
            minWidth: '100px'
        },
        {
            name: 'Amount',
            sortable: true,
            cell: row => (
                <span className='plusJakara_medium '>${calculateFinalPrice(parseInt(row?.estimate?.totalPrice),
                    parseInt(row?.estimate?.dis_price || 0),
                    parseInt(row?.estimate?.tax || 0))}
                </span>
            ),
            minWidth: '100px'
        },
        {
            name: 'Action',
            sortable: false,
            cell: row => (
                <div className="flex">
                    {usertype === 'company' &&
                        (row?.sign_employee ?
                            <button
                                className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                style={{ minWidth: '120px' }}
                                onClick={() => navigate('/contracts/view-contract', {
                                    state: {
                                        viewContract: row,
                                        additionalKey: 'viewContract'
                                    }
                                })}
                            >
                                <span className="text_white whitespace-nowrap plusJakara_regular">View Contract</span>
                            </button> :
                            <button className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2" style={{ minWidth: '120px' }} onClick={() => handleViewContract(row)}>
                                <span className="text_white whitespace-nowrap plusJakara_regular"> Create Contract</span>
                            </ button>)}
                    {usertype === 'customer' &&
                        row?.sign_employee ?
                        (row?.sign_customer ?
                            <button
                                className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                style={{ minWidth: '120px' }}
                                onClick={() => navigate('/contracts/view-contract', {
                                    state: {
                                        viewContract: row,
                                        additionalKey: 'viewContract'
                                    }
                                })}
                            >
                                <span className="text_white whitespace-nowrap plusJakara_regular">View Contract</span>
                            </button> : <button
                                className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                style={{ minWidth: '120px' }}
                                onClick={() => navigate('/contracts/view-contract', {
                                    state: {
                                        viewContract: row,
                                    }
                                })}>
                                <span className="text_white whitespace-nowrap plusJakara_regular">Create Signature</span>
                            </button>)
                        : (usertype === 'customer' && <button
                            className="flex gap-1 items-center justify-center rounded-2 px-2 py-2"
                            style={{ minWidth: '120px', backgroundColor: "#f4f4f4" }}
                            disabled
                        >
                            <span className="text_black plusJakara_medium">Waiting For Service Provider</span>
                        </button>)
                    }
                </div>
            ),
            minWidth: '150px'
        },
        {
            name: 'View Detail',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '50px'
        }
    ];

    return (
        <>
            {usertype === 'company' &&
                <StripeConnectError />}
            <main className={`${usertype === 'company' ? ((!userData?.accountId_verified && (userData?.sub_type !== 'free' && userData?.sub_type !== 'basic')) ? 'px-5 py-2' : 'p-5') : 'pb-5'} w-full`}>
                {usertype !== 'company' &&
                    <HeaderNavigation page='Contract' serviceName='All Contracts' />}
                <Container fluid={usertype === 'company'} className={`${modal && 'blur-sm'} ${usertype === 'company' ? '' : 'mt-5'}`}>
                    <div className="flex w-full flex-col gap-4">
                        {usertype === 'company' &&
                            <div className="flex gap-3 items-center justify-between w-full">
                                <h4 className="text_black plusJakara_semibold">All Contracts</h4>
                            </div>}
                        <div className="flex w-full flex-col">
                            {loading ? (
                                <div className='my-5 flex justify-center w-full items-center'>
                                    <CircularProgress size={24} className='text_primary' style={{ color: "#000" }} />
                                </div>) :
                                (!contracts || contracts.length === 0) ? (
                                    <div className='flex justify-center items-center w-full my-5'>
                                        <span className="plusJakara_medium md:text-lg">No Contract Found</span>
                                    </div>
                                ) : <DTables columns={columns} data={contracts} />}
                        </div>
                    </div>
                </Container>

                <Modal
                    centered
                    open={showNotifyModal}
                    footer={null}
                    maskClosable={false}
                    zIndex={9999}
                    onCancel={() => setshowNotifyModal(false)}
                >
                    <div>
                        <h4 className="text_primary text-center mb-4 plusJakara_bold">Send Notification</h4>
                        <Form
                            style={{ maxWidth: '600px', width: '100%' }}
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                            className=" mt-4 auth_form">
                            <Row>
                                <div className="my-3 px-0">
                                    {/* <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="notification">
                                    Notification
                                </Label> */}
                                    <Input
                                        type="text"
                                        className="form-control rounded-3"
                                        name="notification"
                                        id="notification"
                                        style={{ padding: '12px 16px', }}
                                        placeholder="Enter your notification"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.notification || ""}
                                        invalid={
                                            validation.touched.notification && validation.errors.notification ? true : false
                                        }
                                    />
                                    {validation.touched.notification && validation.errors.notification ? (
                                        <FormFeedback type="invalid">{validation.errors.notification}</FormFeedback>
                                    ) : null}
                                </div>
                                <Col lg={12} className="mb-0 px-0">
                                    <div className="d-grid">
                                        <button className="py-[12px] rounded-3 bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                                            {isLoading ? <CircularProgress size={20} className='text_white' /> :
                                                "Send"}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>

                <CreateContract setmodalOpen={setmodalOpen} modalOpen={modalOpen} viewContract={selectedItem} />
                <UpdrageModal setmodalOpen={setmodal} modalOpen={modal} />
            </ main >
        </>
    );
};
export default ContractTable;