/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Form, FormFeedback, Input, Label } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DatePicker, message, Modal } from 'antd';
import dayjs from 'dayjs';
import { CircularProgress } from '@mui/material';
import Autocomplete from 'react-google-autocomplete';
import { useJsApiLoader } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateContractSign } from '../../api/estimateApi';
import { Spinner } from 'react-bootstrap';
const { RangePicker } = DatePicker;

const CreateContract = ({ setmodalOpen, modalOpen, viewContract }) => {
    const [loading, setLoading] = useState(false);
    const autocompleteRef2 = useRef()
    const userData = useSelector((state) => state.auth?.userData)
    const [location, setLocation] = useState(null);
    const navigate = useNavigate()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
        libraries: ["places", "maps"]
    });

    const handlePlaceSelected = (place) => {
        if (place && place.formatted_address) {
            validation.setFieldValue('address', place.formatted_address);
        } else {
            validation.setFieldValue('address', 'Please select a valid address');
        }
    };

    useEffect(() => {
        if (userData) {
            validation.setFieldValue('insurance', userData.insurance)
            validation.setFieldValue('address', userData.address)
            setLocation(userData.address)
        }
    }, [userData])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            description: '',
            insurance: '',
            address: ''
        },

        validationSchema: Yup.object({
            description: Yup.string()
                .min(10, "Description must be at least 10 characters")
                .required('Description is required'),
            insurance: Yup.string()
                .required('Insurance # is required'),
            address: Yup.string()
                .required('Address is required')
        }),
        onSubmit: async (values, { resetForm }) => {
            const body = {
                description: values?.description,
                insurance: values?.insurance,
                address: values?.address || location,
            };
            setLoading(true)
            await updateContractSign({ data: body }, viewContract?._id, 'empolyee')
                .then((res) => {
                    if (res.success === true) {
                        setmodalOpen(false);
                        setLoading(false)
                        navigate('/contracts/view-contract', { state: { viewContract: viewContract, ...res.order } });
                    } else {
                        setLoading(false)
                        message.error("Failed to sign contract");
                    }
                }).catch((err) => {
                    message.error(err.response.data.message)
                    setLoading(false)
                })
        }
    })

    return (
        <Modal
            centered
            open={modalOpen}
            footer={null}
            maskClosable={false}
            width={800}
            zIndex={9999}
            onCancel={() => setmodalOpen(false)}
        >
            <div className="flex w-full gap-2 items-center flex-col" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                <h4 className="text_primary text-center mb-4 plusJakara_bold">Create Contract</h4>
                <Form
                    className='w-full'
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <div className="flex gap-2 justify-between w-full flex-wrap flex-md-nowrap">
                        <div className="mb-3 w-full">
                            <Label className="form-label text_secondary plusJakara_regular" htmlFor="insurance">
                                Insurance #
                            </Label>
                            <Input
                                type="text"
                                className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                name="insurance"
                                id="insurance"
                                style={{ padding: '12px' }}
                                placeholder="Insurance #"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.insurance || ""}
                                invalid={validation.touched.insurance && validation.errors.insurance}
                            />
                            {validation.touched.insurance && validation.errors.insurance ? (
                                <FormFeedback type="invalid">{validation.errors.insurance}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3 w-full">
                            <Label className="form-label text_secondary plusJakara_regular" htmlFor="address">
                                Address
                            </Label>
                            {location ? (
                                <Input
                                    type="text"
                                    className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                    name="address"
                                    id="address"
                                    style={{ padding: '12px' }}
                                    placeholder="Address"
                                    onChange={(e) => setLocation(e.target.value)}
                                    value={location}
                                />
                            ) : (
                                <>
                                    <Autocomplete
                                        id="address"
                                        name="address"
                                        apiKey={process.env.REACT_APP_APIKEY}
                                        className="w-full border search_input rounded-lg plusJakara_regular ps-3 py-[12px]"
                                        ref={autocompleteRef2}
                                        defaultValue={''}
                                        placeholder="Address here..."
                                        options={{ types: ['address'] }}
                                        onPlaceSelected={(place) => handlePlaceSelected(place)}
                                    />
                                    {validation.touched.address && validation.errors.address ? (
                                        <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>

                    {/* Contract Description */}
                    <Label className="form-label text_secondary plusJakara_regular" htmlFor="description">
                        Contract Notes
                    </Label>
                    <div className="flex flex-col w-full">
                        <Input
                            type="textarea"
                            className="form-control rounded-3 text_secondary2 plusJakara_medium"
                            name="description"
                            id="description"
                            style={{ padding: '16px', height: '200px', border: '0.25px solid #D4D4D4' }}
                            placeholder="Contract Notes..."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={validation.touched.description && validation.errors.description}
                        />
                        {validation.touched.description && validation.errors.description ? (
                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                        ) : null}
                    </div>
                    <div className="flex justify-end mt-4 w-full">
                        <button disabled={loading} type='submit' className="bg_primary py-2 rounded-3 px-5 plusJakara_medium text_white">{loading ? <Spinner size='sm' color='#fff' /> : 'Create'}</button>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}


export default CreateContract;