/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { allfaqs } from '../api/category';
import HeaderNavigation from './common/headerNavigation';

const Faq = () => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastId, setLastId] = useState(1);
    const [count, setCount] = useState(0);
    const [openIndex, setOpenIndex] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await allfaqs(lastId);
            if (res?.data) {
                if (lastId === 1) {
                    setFaqs(res?.data?.Faqs);
                } else {
                    setFaqs((prevFaqs) => [...prevFaqs, ...res?.data?.Faqs]);
                }
                setCount(res?.data?.count?.totalPage || 0);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const handleShowMore = () => {
        setLastId(lastId + 1);
    };

    useEffect(() => {
        fetchData();
    }, [lastId]);

    const toggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <main className='pb-5'>
            <HeaderNavigation page="FAQ's" serviceName="FAQ's" />
            <Container className='py-5'>
                <h1 className="plusJakara_semibold text-4xl lg:text-5xl text-center text_dark">Frequently Asked Questions</h1>
                <div className="flex flex-col items-center gap-1 my-5 w-full justify-center">
                    <div style={{ border: '1.13px solid #F45734', width: '100px', height: '2px' }}></div>
                    <div style={{ border: '1.13px solid #F45734', width: '100px', height: '2px' }}></div>
                </div>
                {loading ? (
                    <div className="my-5 d-flex justify-center">
                        <CircularProgress size={24} />
                    </div>
                ) : faqs.length === 0 ? (
                    <div className="my-5 d-flex justify-center">
                        <span>No FAQ Found</span>
                    </div>
                ) : (
                    <div className='flex flex-col gap-3 w-full lg:w-4/5 mx-auto'>
                        {faqs.map((faq, index) => (
                            <div key={index} className='border-b bg_light rounded-3 shadow-sm border-gray-200'>
                                <button
                                    onClick={() => toggle(index)}
                                    className="w-full text-lg flex justify-between items-center bg_primary rounded-lg text_white p-[20px] plusJakara_semibold focus:outline-none"
                                >
                                    {faq.title}
                                    <FontAwesomeIcon
                                        icon={openIndex === index ? faChevronUp : faChevronDown}
                                        className="ml-2 transition-transform duration-300"
                                    />
                                </button>
                                <div
                                    className={`transition-all duration-700 ease-in-out overflow-hidden ${openIndex === index ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                                        }`}
                                >
                                    <div className="p-3">
                                        <div
                                            className="text_black plusJakara_medium"
                                            dangerouslySetInnerHTML={{ __html: faq.description }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {lastId < count && (
                    <div className="flex justify-center w-full mt-5">
                        <button
                            onClick={handleShowMore}
                            className="bg-blue-500 py-2 px-4 rounded text-white font-semibold"
                        >
                            Show More
                        </button>
                    </div>
                )}
            </Container>
        </main>
    );
};

export default Faq;
