/* eslint-disable no-unused-vars */
import { Col, message, Modal, Row } from 'antd';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Trash, Trash2 } from 'react-feather';
import { SlLocationPin } from 'react-icons/sl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Label } from "reactstrap";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
// import * as Yup from "yup"
import * as Yup from 'yup';
import DTables from "../DataTable/DTable";
import { avatar1, providerlogo } from "../icons/icon";
import { FaLocationDot, FaPhone } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { useSelector } from 'react-redux';
// import { Button } from 'reactstrap';

const validationSchema = Yup.object({
    serviceName: Yup.string().required('Service name is required'),
    entries: Yup.array().of(
        Yup.object().shape({
            labourName: Yup.string().required('Labour Name is required'),
            labourUnit: Yup.number().required('Unit is required').positive('Must be positive'),
            labourPrice: Yup.number().required('Price is required').positive('Must be positive'),
            labourTotal: Yup.number().required('Total is required').positive('Must be positive'),
            materialName: Yup.string().required('Material name is required'),
            materialUnit: Yup.number().required('Unit is required').positive('Must be positive'),
            materialPrice: Yup.number().required('Price is required').positive('Must be positive'),
            materialTotal: Yup.number().required('Total is required').positive('Must be positive'),
        })
    ),
});

const EstimateDetail = ({ showButtons = true }) => {
    const { state } = useLocation()
    const estimateDetail = state?.estimateDetail
    const [loading, setLoading] = useState(false);
    const [lastId, setLastId] = useState(1);
    const [count, setcount] = useState(0);
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [modalOpen, setmodalOpen] = useState(false)
    const categories = [{ name: 'John Doe', unit: 2, price: 50, total: 100 }, { name: 'Jane Smith', unit: 1, price: 100, total: 100 }]; // Sample labor data
    const navigate = useNavigate();

    const initialValues = {
        serviceName: '',
        entries: [{ serviceName: '', labourName: '', labourUnit: '', labourPrice: '', labourTotal: '', materialName: '', materialUnit: '', materialPrice: '', materialTotal: '' }],
    };
    const handleSubmit = (values) => {
        console.log('Submitted Values:', values);
    };

    const handleCancel = () => {
        message.success('Estimate confirmed Successfully')
        setmodalOpen(false)
        navigate(-1)
    }
    const handleCancel2 = () => {
        message.error('Estimate Cancelled Successfully')
        setmodalOpen(false)
        navigate(-1)
    }
    const handleConfirm = () => {
        message.success('Contract sent Successfully')
        setmodalOpen(false)
        navigate(-1)
    }

    const columns = [
        {
            name: 'Service name',
            sortable: true,
            minWidth: '250px',
            selector: row => row.name
        },
        {
            name: 'Labour Name',
            sortable: true,
            minWidth: '220px',
            selector: row => row.labour
        },
        {
            name: 'Unit',
            sortable: true,
            // maxWidth: '250px',
            selector: row => row.unit
        },
        {
            name: 'Price',
            sortable: true,
            // maxWidth: '250px',
            selector: row => '$ ' + row.price
        },
        {
            name: 'Material Name',
            sortable: true,
            minWidth: '220px',
            selector: row => row.labour
        },
        {
            name: 'Unit',
            sortable: true,
            // maxWidth: '250px',
            selector: row => row.unit
        },
        {
            name: 'Price',
            sortable: true,
            // maxWidth: '250px',
            selector: row => '$ ' + row.price
        },
    ]
    const data = [
        {
            name: 'Cleaning',
            labour: 'John dev',
            unit: 5,
            price: 50,
        },
        {
            name: 'Plumbing',
            labour: 'John dev',
            unit: 5,
            price: 50,
        },
        {
            name: 'Plumbing',
            labour: 'John dev',
            unit: 5,
            price: 50,
        },
        {
            name: 'Plumbing',
            labour: 'John dev',
            unit: 5,
            price: 50,
        },
        {
            name: 'Senatory',
            labour: 'John dev',
            unit: 5,
            price: 50,
        }
    ]

    return (
        <main className='pb-5 w-full'>
            <HeaderNavigation mainPage={'/estimates'} subPage={`${estimateDetail ? 'Estimate' : 'Proposal'} Detail`} subPageAvail={true} page={`${estimateDetail ? 'Estimate' : 'Proposal'}`} serviceName='House Cleaning Service' />
            <Container className='py-5 w-full'>
                <div className="flex gap-4 items-center justify-between w-full mb-4">
                    <div className="flex flex-col gap-3 w-full">
                        <h4 className="text_primary mb-3 plusJakara_semibold">{usertype === 'company' ? 'Customer' : "Company"} Detail</h4>
                        <div className="flex gap-3 items-start w-full">
                            <img src={providerlogo} style={{ width: '120px', height: 'auto', objectFit: 'cover', borderRadius: '12px' }} alt="" />
                            <div className="flex flex-col w-full">
                                <h5 className="text_black plusJakara_semibold">{usertype === 'customer' ? 'Golder Construction Center' : 'John dev'}</h5>
                                <div className="flex gap-2 items-center">
                                    <FaLocationDot className='text_primary' />
                                    <span className="text_secondary2 plusJakara_medium">1995 Broadway, New York</span>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <FaPhone className='text_primary' />
                                    <span className="text_secondary2 plusJakara_medium">+55 234 234 22</span>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <MdEmail className='text_primary' />
                                    <span className="text_secondary2 plusJakara_medium">{usertype === 'customer' ? 'cleaning.company@gmail.com' : 'johndev@gmail.com'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {usertype === 'customer' &&
                        <div className="flex gap-3 items-center justify-end w-full">
                            <button style={{ maxWidth: '150px', width: '100%', padding: '10px 24px' }} className="bg_primary flex justify-center  text_white rounded-3 plusJakara_medium">Confirm</button>
                            <button style={{ maxWidth: '150px', width: '100%', padding: '10px 24px' }} className="bg_primary2 flex justify-center  text_white rounded-3 plusJakara_medium">Cancel</button>
                        </div>
                    }
                </div>
                <hr style={{ color: "#828282" }} />
                <div className="flex mb-5 mt-4 flex-wrap gap-3 flex-col flex-md-nowrap justify-between w-full">
                    <div className="flex gap-3 items-center justify-between w-full">
                        <Col xs={24} md={11} xl={14}>
                            <h5 className="text_primary mb-3 plusJakara_semibold">Estimate Detail</h5>
                            <div className="flex justify-between gap-5 items-center flex-wrap">
                                <div className="flex flex-col gap-2">
                                    <h6 className="text_secondary2 plusJakara_semibold">Estimate Number</h6>
                                    <span className="text_black plusJakara_bold">#987483</span>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <h6 className="text_secondary2 plusJakara_semibold">Estimate Status</h6>
                                    <span className="text_black plusJakara_bold">Pending</span>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <h6 className="text_secondary2 plusJakara_semibold">Estimate Date</h6>
                                    <span className="text_black plusJakara_bold">20 May, 2021</span>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <h6 className="text_secondary2 plusJakara_semibold">Time</h6>
                                    <span className="text_black plusJakara_bold">09:00 AM</span>
                                </div>
                            </div>
                        </Col>
                    </div>
                    <hr style={{ color: "#828282" }} />
                    <Col xs={24} md={11} xl={18}>
                        <div className="flex flex-col">
                            <h5 className="text_primary mb-3 plusJakara_semibold">Description</h5>
                            {/* <h5 className="text_black plusJakara_medium">Description</h5> */}
                            <h6 className="text_secondary plusJakara_regular">Our Deep Cleaning Service provides an extensive and thorough cleaning of your home or office. We go beyond regular cleaning to ensure every nook and cranny is spotless. Our Deep Cleaning Service provides an extensive and thorough cleaning of your home or office. We go beyond regular cleaning to ensure every nook and cranny is spotless. </h6>
                        </div>
                    </Col>

                </div>
                {usertype === 'company' &&
                    <div className="flex mb-4 flex-wrap gap-3 rounded-3 flex-md-nowrap justify-between w-full">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <FieldArray name="entries">
                                        {({ push, remove }) => (
                                            <>
                                                {values.entries.map((entry, index) => (
                                                    <Row
                                                        style={{ backgroundColor: "#f4f4f4", border: '1px solid #d3d3d3' }}
                                                        gutter={16}
                                                        className="row w-full relative shadow-sm p-3 rounded-3 mb-3 items-center justify-between"
                                                        key={index}
                                                    >
                                                        {/* Remove Button */}
                                                        {index > 0 && (
                                                            <div className="absolute -top-2 flex justify-end -right-4 w-full">
                                                                <button
                                                                    type="button"
                                                                    className="bg-red-500 text-white rounded-5 p-1"
                                                                    onClick={() => remove(index)}
                                                                >
                                                                    <Trash2 size={18} />
                                                                </button>
                                                            </div>
                                                        )}

                                                        <Col xs={24} md={7}>
                                                            <Label className="plusJakara_medium">Service Name</Label>
                                                            <div className="form-group">
                                                                <Field
                                                                    type="text"
                                                                    name="serviceName"
                                                                    className="form-control"
                                                                    placeholder="Service Name"
                                                                />
                                                                <ErrorMessage name="serviceName" component="div" className="text-danger text-xs plusJakara_regular" />
                                                            </div>
                                                        </Col>

                                                        <Col xs={24} md={8}>
                                                            <div className="flex flex-col items-start">
                                                                <Label className="plusJakara_medium">Labour</Label>
                                                                <div className="w-full">
                                                                    <Field
                                                                        name={`entries[${index}].labourName`}
                                                                        className="form-control w-full"
                                                                        placeholder="Labour Name"
                                                                    />
                                                                    <ErrorMessage name={`entries[${index}].labourName`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                </div>

                                                                <div className="flex mt-2 gap-2">
                                                                    <div>
                                                                        <Field
                                                                            name={`entries[${index}].labourUnit`}
                                                                            className="form-control"
                                                                            placeholder="Unit"
                                                                            type="number"
                                                                            onChange={(e) => {
                                                                                setFieldValue(`entries[${index}].labourUnit`, e.target.value);
                                                                                setFieldValue(`entries[${index}].labourTotal`, e.target.value * values.entries[index].labourPrice || 0);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`entries[${index}].labourUnit`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </div>

                                                                    <div>
                                                                        <Field
                                                                            name={`entries[${index}].labourPrice`}
                                                                            className="form-control"
                                                                            placeholder="Price"
                                                                            type="number"
                                                                            onChange={(e) => {
                                                                                setFieldValue(`entries[${index}].labourPrice`, e.target.value);
                                                                                setFieldValue(`entries[${index}].labourTotal`, e.target.value * values.entries[index].labourUnit || 0);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`entries[${index}].labourPrice`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </div>

                                                                    <div>
                                                                        <Field
                                                                            name={`entries[${index}].labourTotal`}
                                                                            className="form-control"
                                                                            placeholder="Total"
                                                                            type="number"
                                                                            disabled
                                                                        />
                                                                        <ErrorMessage name={`entries[${index}].labourTotal`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        {/* Material Fields */}
                                                        <Col xs={24} md={8}>
                                                            <div className="flex flex-col items-start">
                                                                <Label className="plusJakara_medium">Material</Label>
                                                                <div className="w-full">
                                                                    <div>
                                                                        <Field
                                                                            name={`entries[${index}].materialName`}
                                                                            className="form-control"
                                                                            placeholder="Material Name"
                                                                        />
                                                                        <ErrorMessage name={`entries[${index}].materialName`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </div>
                                                                </div>

                                                                <div className="flex mt-2 gap-2">
                                                                    <div>
                                                                        <Field
                                                                            name={`entries[${index}].materialUnit`}
                                                                            className="form-control"
                                                                            placeholder="Unit"
                                                                            type="number"
                                                                            onChange={(e) => {
                                                                                setFieldValue(`entries[${index}].materialUnit`, e.target.value);
                                                                                setFieldValue(`entries[${index}].materialTotal`, e.target.value * values.entries[index].materialPrice || 0);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`entries[${index}].materialUnit`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </div>

                                                                    <div>
                                                                        <Field
                                                                            name={`entries[${index}].materialPrice`}
                                                                            className="form-control"
                                                                            placeholder="Price"
                                                                            type="number"
                                                                            onChange={(e) => {
                                                                                setFieldValue(`entries[${index}].materialPrice`, e.target.value);
                                                                                setFieldValue(`entries[${index}].materialTotal`, e.target.value * values.entries[index].materialUnit || 0);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`entries[${index}].materialPrice`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </div>

                                                                    <div>
                                                                        <Field
                                                                            name={`entries[${index}].materialTotal`}
                                                                            className="form-control"
                                                                            placeholder="Total"
                                                                            type="number"
                                                                            disabled
                                                                        />
                                                                        <ErrorMessage name={`entries[${index}].materialTotal`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}

                                                <button
                                                    className="text_primary px-3 py-2 rounded-3 plusJakara_medium"
                                                    type="button"
                                                    style={{ border: '1px solid #003F7D' }}
                                                    onClick={() =>
                                                        push({
                                                            labourName: '',
                                                            labourUnit: '',
                                                            labourPrice: '',
                                                            labourTotal: '',
                                                            materialName: '',
                                                            materialUnit: '',
                                                            materialPrice: '',
                                                            materialTotal: ''
                                                        })
                                                    }
                                                >
                                                    Add Labour & Material
                                                </button>
                                            </>
                                        )}
                                    </FieldArray>

                                    {/* Submit Button */}
                                    <div className="mt-4 flex justify-end w-full">
                                        <button
                                            style={{ maxWidth: '150px', width: '100%', padding: '10px 24px' }}
                                            className="bg_lightprimary whitespace-nowrap rounded-3 text_white plusJakara_medium" type="submit">
                                            Send Estimate
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>}

                {usertype === 'customer' &&
                    <div className="flex w-full flex-col gap-4">
                        <DTables columns={columns} data={data} />
                        <div className="flex flex-col gap-2 items-end justify-start" style={{ width: '93%' }}>
                            <div className="flex gap-24 items-center">
                                <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                <h6 className="text_black plusJakara_semibold mb-0">$300</h6>
                            </div>
                            <div className="flex gap-24 items-center">
                                <h6 className="text_secondary2 mb-0 plusJakara_semibold">Discount &ensp;(0%) </h6>
                                <h6 className="text_black plusJakara_semibold mb-0">$0.00</h6>
                            </div>
                            <div className="flex gap-24 items-center">
                                <h6 className="text_secondary2 mb-0 plusJakara_semibold">Tax &ensp; (5%) </h6>
                                <h6 className="text_black plusJakara_semibold mb-0">$300</h6>
                            </div>
                            <div className="flex gap-24 mt-4 items-center">
                                <h5 className="text_primary mb-0 plusJakara_bold">Total Estimate </h5>
                                <h5 className="text_black plusJakara_semibold mb-0">$253</h5>
                            </div>
                        </div>
                    </div>
                }

            </Container>

            <Modal
                centered
                open={modalOpen}
                footer={null}
                closeIcon={false}
                width={400}
                onCancel={() => setmodalOpen(false)}
            >
                <div className="flex w-full gap-2 items-center flex-col">
                    <h4 className="text_primary text-center mb-0 plusJakara_bold">Create Contract</h4>
                    <h5 className="text_secondary2 text-center plusJakara_regular">Do you want to create the Contract for this estimate</h5>
                    <div className="flex gap-3 mt-2 items-center justify-between w-full">
                        <button
                            onClick={handleCancel}
                            style={{ border: '2.06px solid #003F7D' }}
                            className="py-3 rounded-5 w-full bg_white plusJakara_medium text_primary"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleConfirm}
                            className="py-3 rounded-5 w-full bg_primary plusJakara_medium text_white"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>

        </ main >
    );
};
export default EstimateDetail;
