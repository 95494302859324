import { createSlice } from '@reduxjs/toolkit';

export const serviceSlice = createSlice({
  name: 'services',
  initialState: {
    serviceData: {},
    categories: [],
    invoiceData: {},
    pagesCount: {},
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    setInvoiceData: (state, action) => {
      state.invoiceData = action.payload
    },
    setServiceData: (state, action) => {
      state.serviceData = action.payload
    },
    setPagesCount: (state, action) => {
      state.pagesCount = action.payload
    }
  },
});

export const { setServiceData, setInvoiceData, setPagesCount, serviceData, categories, setCategories, } = serviceSlice.actions;
export default serviceSlice.reducer;