/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CircularProgress } from '@mui/material';
import { message, Modal } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { SlLocationPin } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Row
} from "reactstrap";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import * as Yup from "yup";
import DTables from "../DataTable/DTable";
import { avatar1 } from '../icons/icon';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
import { completeOrder, getOrders } from '../api/order';
import moment from 'moment';
import { calculateFinalPrice } from './estimate/calculateFinalPrice';
import { useSocket } from './messages/socketProvider';
import AssignEmployee from './common/assignEmployee';
import { createRating } from '../api/rating';
import { FaStar } from 'react-icons/fa6';
import StripeConnectError from './common/stripeConnectError';

const Orders = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [modalOpen, setmodalOpen] = useState(false)
    const [selectedItem, setselectedItem] = useState(null)
    const [isLoading, setisLoading] = useState(false)
    const [showNotifyModal, setshowNotifyModal] = useState(false)
    const [showModalReview, setshowModalReview] = useState(false)
    const [reviewRating, setreviewRating] = useState(null)
    const [ratingLoading, setratingLoading] = useState(false)
    const [ratingMessage, setratingMessage] = useState('')
    const [completeLoading, setcompleteLoading] = useState(false)
    const [rating, setRating] = useState(0); // For storing the rating value
    const [hover, setHover] = useState(null);
    const [statusId, setstatusId] = useState(null)
    const navigate = useNavigate();
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const userData = useSelector((state) => state.auth?.userData)
    const [loading, setloading] = useState([])
    const [active, setactive] = useState('all')
    const [orders, setorders] = useState([])
    const socket = useSocket();

    useEffect(() => {
        if (socket) {
            socket.emit('seen-order', {});
        }
    }, [socket])

    const handleTab = (tab) => {
        const data = {
            selected: tab
        }
        setactive(tab)
        setSearchParams(data)
    };

    const fetchorders = async () => {
        setloading(true)
        await getOrders(usertype === 'company' ? 'employee' : 'customer', active)
            .then((res) => {
                if (res.success === true) {
                    setorders(res.orders)
                    setloading(false)
                } else {
                    setloading(false)
                    setorders([])
                }
            }).catch((err) => {
                setloading(false)
                setorders([])
            })
    }

    useEffect(() => {
        fetchorders()
    }, [active])

    const handleShowReview = (row) => {
        setshowModalReview(true)
        setreviewRating(row)
    }

    const handleAddReview = async (row) => {
        const dataToPost = {
            to_id: row?.to_id?._id,
            order: row?._id,
            rating: rating,
            review: ratingMessage?.trim(),
        };
        setratingLoading(true)
        await createRating({ data: dataToPost })
            .then((res) => {
                if (res.success === true) {
                    message.success("Review created successfully");
                    setratingLoading(false)
                    setshowModalReview(false)
                    setreviewRating(null)
                    setratingMessage('')
                    setRating(0)
                    fetchorders()
                } else {
                    setratingLoading(false)
                    setshowModalReview(false)
                    message.error("Failed to create review");
                }
            }).catch((err) => {
                setshowModalReview(false)
                message.error("Failed to create review");
                setratingLoading(false)
            })
    }

    const handleViewDetails = (row) => {
        navigate(`/orders/${row?._id}`, { state: { orderData: row } })
    };
    const handleCreateInvoice = (row) => {
        navigate(`/orders/invoice/${row?._id}`, { state: { invoiceData: row } })
    };

    const validationSchema = Yup.object().shape({
        notification: Yup.string()
            .required("Please enter a Message")
    });
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            notification: "",
        },
        validationSchema,

        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            navigate("/reset-password", { state: { data: "true" } });
            setisLoading(false);
        },
    });

    useEffect(() => {
        if (searchParams.get('selected')) {
            setactive(searchParams.get('selected'))
        } else {
            const data = {
                selected: 'all'
            }
            setSearchParams(data)
        }
    }, [searchParams]);


    const handleAssignOrder = (row) => {
        setmodalOpen(true)
        setselectedItem(row)
    }

    const calculateTotal = (row) => {
        const tax = (row?.totalPrice * Number(row?.tax)) / 100;
        const taxedPrice = row?.totalPrice + tax;
        return (taxedPrice - row?.dis_price)?.toFixed(2);
    };


    const handleSubmit = async (row) => {
        setstatusId(row?._id)
        setcompleteLoading(true)
        await completeOrder(row?._id).then((res) => {
            if (res.success === true) {
                message.success("Order completed successfully");
                setcompleteLoading(false)
                fetchorders()
            } else {
                message.error("Failed to Order completiing");
                setcompleteLoading(false)
            }
        }).catch((err) => {
            console.error("Error Order completiing:", err);
            message.error(err.response.data.message)
            setcompleteLoading(false)
        })
    }


    const columns = [
        // {
        //     name: '#',
        //     sortable: true,
        //     cell: (row, index) => (
        //         <h6 className="plusJakara_semibold">{index + 1}</h6>
        //         // <button className='hover:text-blue-300 hover:underline' onClick={() => navigate('/orders/987382982', { state: { orderData: row } })}>{row?.orderId}</button>
        //     ),
        //     width: '80px'
        // },
        {
            name: 'Order',
            sortable: true,
            cell: row => (
                <div className="flex gap-2 p-1 items-center w-full">
                    <img src={row?.service?.images[0] || row?.estimate?.images[0]} style={{ width: '55px', height: '60px', borderRadius: '8px', objectFit: 'cover' }} alt="" />
                    <div className="flex gap-1 my-2 flex-col items-start">
                        <h6 className="plusJakara_semibold mb-0">{row?.service?.name || row?.estimate?.name}</h6>
                        <div className="flex gap-1 items-start">
                            {/* <div className="w-4">
                                <SlLocationPin size={16} />
                            </div> */}
                            <span className="text_secondary text-xs plusJakara_medium">{(row?.service?.address ? row?.service?.address : row?.user?.address) || row?.estimate?.location?.address}</span>
                        </div>
                        <button onClick={() => handleViewDetails(row)} className='text-sm plusJakara_semibold hover:underline whitespace-nowrap text_primary'>Order# {row?.order_id}</button>
                    </div>
                </div>
            ),
            minWidth: '350px'
        },
        {
            name: usertype === 'company' ? 'Customer' : 'Service Provider',
            sortable: true,
            cell: row => (
                <div className="flex gap-2 items-center">
                    <div style={{ minWidth: '40px' }}>
                        <img src={(row?.user?.profilePicture || row?.to_id?.profilePicture) || avatar1} style={{ width: '35px', height: '35px', borderRadius: '50%', objectFit: 'cover' }} alt="" />
                    </div>
                    <span style={{ fontSize: '14px' }} className="plusJakara_semibold">{row.user?.name || row.to_id?.name}</span>
                </div>
            ),
            minWidth: '180px'
        },
        {
            name: 'Date/Time',
            sortable: true,
            selector: row => moment(row?.bookingDate).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        // {
        //     name: 'Status',
        //     sortable: true,
        //     selector: row => (
        //         <div className="status-badge px-2 text-xs py-1 rounded-3" style={{ backgroundColor: '#E6E9F4', color: '#5A607F' }}>
        //             {row.status === 'pending' ? 'On Going' : row.status}
        //         </div>
        //     ),
        //     minWidth: '100px'
        // },
        {
            name: 'Amount',
            sortable: true,
            cell: row => (
                <div className="flex my-1 flex-col items-start">
                    {row?.estimate ?
                        <span className='plusJakara_medium '>${calculateFinalPrice(parseInt(row?.estimate?.totalPrice || 0) || parseInt(row?.service?.totalPrice || 0),
                            parseInt(row?.estimate?.dis_price || 0) || parseInt(row?.service?.dis_price || 0),
                            parseInt(row?.estimate?.tax || 0) || parseInt(row?.service?.tax || 0))}
                        </span> :
                        <span className='plusJakara_medium'>
                            ${calculateTotal(row?.service)}
                        </span>}
                </div>
            ),
            minWidth: '100px'
        },
        ...(usertype === 'customer' ? [
            {
                name: 'Rating',
                cell: row => (
                    <>
                        {row?.status === 'complete' ? (row?.rating ?
                            <button
                                disabled
                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                style={{ minWidth: '120px' }}
                            >
                                <span className="whitespace-nowrap text-xs plusJakara_regular">Already Added</span>
                            </button > :
                            <button
                                className="flex gap-1 items-center justify-center rounded-2 bg_primary text_white px-2 py-2"
                                style={{ minWidth: '120px' }}
                                onClick={() => handleShowReview(row)}
                            >
                                <span className="whitespace-nowrap text-xs plusJakara_regular">Add Review</span>
                            </button>) : <button
                                disabled
                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                style={{ minWidth: '120px' }}
                            >
                            <span className="whitespace-nowrap text-xs plusJakara_regular">Wating for Complete</span>
                        </button >}
                    </>
                ),
                minWidth: '180px'
            },
        ] : []),
        {
            name: 'View Order',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '120px'
        },
        ...(usertype === 'company' ? [
            {
                name: 'Action',
                minWidth: '200px',
                sortable: false,
                cell: row => (
                    <div className="flex">
                        {userData?.sub_type !== 'free' ? (
                            userData?.sub_type === 'basic' ? (
                                row?.status === 'complete' ? (
                                    <button
                                        disabled
                                        className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
                                        style={{ minWidth: '120px' }}
                                    >
                                        <span className="text_black whitespace-nowrap text-xs plusJakara_regular">Complete</span>
                                    </button>
                                ) : (
                                    <button
                                        className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                        style={{ minWidth: '120px' }}
                                        onClick={() => handleCreateInvoice(row)}
                                    >
                                        <span className="text_white whitespace-nowrap text-xs plusJakara_regular">Create Invoice</span>
                                    </button>
                                )
                            ) : userData?.sub_type === 'standard' ? (
                                row?.status === 'complete' ? (
                                    <button
                                        disabled
                                        className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
                                        style={{ minWidth: '120px' }}
                                    >
                                        <span className="text_black whitespace-nowrap text-xs plusJakara_regular">Complete</span>
                                    </button>
                                ) : row?.invoice ? (
                                    <button
                                        disabled
                                        className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                        style={{ minWidth: '120px' }}
                                    >
                                        <span className="whitespace-nowrap text-xs plusJakara_regular">Invoice Sent</span>
                                    </button>) : (
                                    <button
                                        className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                        style={{ minWidth: '120px' }}
                                        onClick={() => handleCreateInvoice(row)}
                                    >
                                        <span className="text_white whitespace-nowrap text-xs plusJakara_regular">Create Invoice</span>
                                    </button>
                                )
                            ) : (
                                row?.status === 'complete' ? (
                                    <button
                                        disabled
                                        className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
                                        style={{ minWidth: '120px' }}
                                    >
                                        <span className="text_black whitespace-nowrap text-xs plusJakara_regular">Complete</span>
                                    </button>
                                ) : (
                                    row?.estimate ? (
                                        row?.assign_users.length === 0 ? (
                                            <button
                                                className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                                onClick={() => handleAssignOrder(row)}
                                            >
                                                <span className="text_white whitespace-nowrap text-xs plusJakara_regular">Assign Order</span>
                                            </button>
                                        ) : row?.invoice ? (
                                            <button
                                                disabled
                                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <span className="whitespace-nowrap text-xs plusJakara_regular">Invoice Sent</span>
                                            </button>
                                        ) : row?.assign_users.find((item) => item.status === 'pending') ? (
                                            <button
                                                disabled
                                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <span className="text_black text-xs plusJakara_regular">Employees are working on Order</span>
                                            </button>
                                        ) : (
                                            <button
                                                className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                                onClick={() => handleCreateInvoice(row)}
                                            >
                                                <span className="text_white whitespace-nowrap text-xs plusJakara_regular">Create Invoice</span>
                                            </button>
                                        )
                                    ) : row?.invoice ? (
                                        <button
                                            disabled
                                            className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                            style={{ minWidth: '120px' }}
                                        >
                                            <span className="whitespace-nowrap text-xs plusJakara_regular">Invoice Sent</span>
                                        </button>
                                    ) : (
                                        <button
                                            className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                            style={{ minWidth: '120px' }}
                                            onClick={() => handleCreateInvoice(row)}
                                        >
                                            <span className="text_white whitespace-nowrap text-xs plusJakara_regular">Create Invoice</span>
                                        </button>
                                    )
                                )
                            )
                        ) : (
                            <button
                                disabled
                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
                                style={{ minWidth: '120px' }}
                            >
                                <span className="text_black whitespace-nowrap text-xs plusJakara_regular">
                                    {row?.status === 'pending' ? 'Waiting for approval' : row?.status}
                                </span>
                            </button>
                        )}
                    </div>
                ),
            }
        ] : []),
        ...(usertype === 'customer' ? [
            {
                name: 'Action',
                minWidth: '200px',
                sortable: false,
                cell: row => (
                    <div className="flex">
                        {(row?.to_id?.sub_type === 'free') ? (
                            row?.status !== 'complete' ? (
                                <button
                                    disabled={completeLoading}
                                    onClick={() => handleSubmit(row)}
                                    className="flex gap-1 items-center justify-center rounded-2 bg_primary px-3 py-2"
                                >
                                    <span className="text_white whitespace-nowrap plusJakara_regular">
                                        {row?._id === statusId && completeLoading ? (
                                            <CircularProgress size={12} className="text_white" style={{ color: 'white' }} />
                                        ) : (
                                            'Complete Order'
                                        )}
                                    </span>
                                </button>
                            ) : (
                                <button
                                    disabled
                                    className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                    style={{ minWidth: '120px' }}
                                >
                                    <span className="whitespace-nowrap text-xs plusJakara_regular">Order completed</span>
                                </button>
                            )
                        ) : <button
                            disabled
                            className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                            style={{ minWidth: '120px' }}
                        >
                            <span className="whitespace-nowrap text-xs plusJakara_regular">{row?.status === 'pending' ? 'Waiting for Provider' : row?.status}</span>
                        </button>}
                    </div>
                ),
            }
        ] : []),
    ];

    return (
        <>
            {usertype === 'company' &&
                <StripeConnectError />}
            <main className={`${usertype === 'company' ? ((!userData?.accountId_verified && (userData?.sub_type !== 'free' && userData?.sub_type !== 'basic')) ? 'px-5 py-2' : 'p-5') : 'pb-5'} w-full`}>
                {usertype !== 'company' &&
                    <HeaderNavigation page='Orders' serviceName='All Orders' />}
                <Container fluid={usertype === 'company'} className={`${usertype === 'company' ? '' : 'mt-5'}`}>
                    {usertype === 'company' && <div className="flex gap-3 mb-3 items-center justify-between w-full">
                        <h4 className="text_black plusJakara_semibold">All Orders</h4>
                    </div>}
                    <div className={`flex mb-4 overflow-auto rounded-3 ${usertype !== 'company' ? 'bg_light' : 'bg_white'} max-w-md`}>
                        <button onClick={() => handleTab('all')}
                            className={`px-4 py-[10px] w-full  plusJakara_medium ${active === 'all' ? 'bg_primary text_white ' : 'text_secondary2'} flex justify-center items-center gap-2`}>
                            All
                        </button>
                        <button onClick={() => handleTab('pending')}
                            className={`px-4 py-[10px] w-full  plusJakara_medium ${active === 'pending' ? 'bg_primary text_white ' : 'text_secondary2'} flex justify-center items-center gap-2`}>
                            In Progress
                        </button>
                        <button onClick={() => handleTab('complete')}
                            className={`px-4 py-[10px] w-full  plusJakara_medium ${active === 'complete' ? 'bg_primary text_white ' : 'text_secondary2'} flex justify-center items-center gap-2`}>
                            Completed
                        </button>
                    </div>

                    <div className="flex w-full flex-col">
                        {loading ? (
                            <div className='my-5 flex justify-center w-full items-center'>
                                <CircularProgress size={24} className='text_primary' style={{ color: "#000" }} />
                            </div>) :
                            (!orders || orders.length === 0) ? (
                                <div className='flex justify-center items-center w-full my-5'>
                                    <span className="plusJakara_medium md:text-lg">No Order Found</span>
                                </div>
                            ) : <DTables columns={columns} data={orders} />}
                    </div>
                </Container>


                <Modal
                    centered
                    open={showModalReview}
                    footer={null}
                    maskClosable={false}
                    // width={800}
                    zIndex={9999}
                    onCancel={() => setshowModalReview(false)}
                >
                    <div>
                        <h4 className="text_primary text-center mb-4 plusJakara_bold">Add Review</h4>
                        <Form
                            style={{ maxWidth: '600px', width: '100%' }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleAddReview(reviewRating);
                            }}
                            className=" mt-4 auth_form">
                            <Row>
                                <div className="flex gap-2 w-full items-center justify-center">
                                    {[...Array(5)].map((_, i) => {
                                        const starValue = i + 1;
                                        return (
                                            <label key={i}>
                                                <input
                                                    type="radio"
                                                    name="rating"
                                                    value={starValue}
                                                    onClick={() => setRating(starValue)}
                                                    className="hidden"
                                                />
                                                <FaStar
                                                    size={24}
                                                    color={starValue <= (hover || rating) ? '#FF8A00' : '#ddd'}
                                                    onMouseEnter={() => setHover(starValue)}
                                                    onMouseLeave={() => setHover(null)}
                                                    className="cursor-pointer"
                                                />
                                            </label>
                                        );
                                    })}
                                </div>
                                <div className="my-3 px-0">
                                    <Input
                                        type="textarea"
                                        className="form-control w-full rounded-3"
                                        name="reminder"
                                        id="reminder"
                                        rows='4'
                                        style={{ padding: "12px 16px" }}
                                        placeholder="Write your review here..."
                                        onChange={(e) => setratingMessage(e.target.value)}
                                        value={ratingMessage}
                                    />
                                </div>
                                <Col lg={12} className="mb-0 px-0">
                                    <div className="d-grid">
                                        <button className="py-[12px] rounded-2 bg_primary plusJakara_medium text_white" disabled={ratingLoading} type="submit">
                                            {ratingLoading ? <CircularProgress size={16} style={{ color: '#fff' }} className='text_white' /> :
                                                "Create"}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>

                <AssignEmployee setmodalOpen={setmodalOpen} modalOpen={modalOpen} assignUser={selectedItem} />
            </ main >
        </>
    );
};
export default Orders;
