/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CircularProgress } from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Col, message, Modal, Row } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import Autocomplete from 'react-google-autocomplete';
import { FaUserLarge } from 'react-icons/fa6';
import { MdOutlineEdit } from 'react-icons/md';
import { TbCurrentLocation } from 'react-icons/tb';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, FormFeedback, Input, Label, Spinner } from 'reactstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import * as Yup from "yup";
import { uploadFile } from '../api/uploadFile';
import { mapmarker } from '../icons/icon';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
import { updateUser } from '../api/signup';
import { setUserData } from '../redux/loginForm';

const Profile = () => {
    const [location, setLocation] = useState(null);
    const libraries = ["places", "maps"];
    const [map, setMap] = useState(null);
    const dispatch = useDispatch()
    const autocompleteRef2 = useRef()
    const [isLoading, setisLoading] = useState(false)
    const [latLng, setLatLng] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [fileLoading, setFileLoading] = useState(false);
    const userData = useSelector((state) => state.auth?.userData)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
        libraries: libraries,
    });

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFileLoading(true)
            const formData = new FormData();
            formData.append('image', selectedFile);
            try {
                const res = await uploadFile({ data: formData })
                if (res.image) {
                    setImageUrl(res.image);
                    setFileLoading(false);
                }
            } catch (error) {
                message.error('Failed to upload file');
                setFileLoading(false);
            }
        }
    };

    const navigate = useNavigate()
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            address: "",
            city: "",
            email: '',
            state: "",
            lat: '',
            lng: '',
            fullName: '',
            zipCode: "",
            phone: '',
            country: "",
        },
        validationSchema: Yup.object({
            // email: Yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
            fullName: Yup.string()
                .required("Full Name is required"),
            // phone: Yup.string()
            //     .matches(/^\+?[0-9]{10,15}$/, "Phone number is not valid")
            //     .min(10, "Phone number must be at least 10 digits")
            //     .max(15, "Phone number must be at most 15 digits"),
            address: Yup.string()
                .required("Address is required"),
            city: Yup.string()
                .required("City is required"),
            state: Yup.string()
                .required("State is required"),
            zipCode: Yup.string()
                // .matches(/^[0-9]{5}$/, "Zip Code must be exactly 5 digits")
                .required("Zip Code is required"),
            country: Yup.string()
                .required("Country is required"),
        }),

        onSubmit: async (values, { resetForm }) => {
            const data = {
                profilePicture: imageUrl,
                address: values.address,
                city: values.city,
                state: values.state,
                location: {
                    type: 'Point',
                    coordinates: [values?.lng, values?.lat],
                },
                zipcode: values.zipCode,
                country: values.country,
                name: values.fullName
            };
            setisLoading(true);
            await updateUser({ data: data }).then((res) => {
                if (res.data.success === true) {
                    setisLoading(false);
                    dispatch(setUserData(res.data.user))
                    resetForm()
                    message.success('Profile updated successfully');
                    navigate('/', { replace: true })
                }
            }).catch((err) => {
                message.error('Failed to update profile');
                setisLoading(false);
            })
        }
    });

    const handlePlaceSelected = (place) => {
        if (place && place.formatted_address) {
            const { city, state, zipCode, country } = extractAddressComponents(place);
            const lat = place.geometry?.location.lat();
            const lng = place.geometry?.location.lng();

            if (!isNaN(lat) && !isNaN(lng)) {
                validation.setFieldValue('address', place.formatted_address);
                validation.setFieldValue('lat', lat);
                validation.setFieldValue('lng', lng);
                validation.setFieldValue('city', city);
                validation.setFieldValue('state', state);
                validation.setFieldValue('country', country);
                validation.setFieldValue('zipCode', zipCode);
            } else {
                validation.setFieldError('address', 'Invalid coordinates');
            }
        } else {
            validation.setFieldError('address', 'Please select a valid address');
        }
    };

    const extractAddressComponents = (place) => {
        let city = '';
        let state = '';
        let country = '';
        let zipCode = '';

        place.address_components.forEach(component => {
            if (component.types.includes("locality")) {
                city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name;
            }
            if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
            }
            if (component.types.includes("country")) {
                country = component.long_name;
            }
        });

        return { city, state, zipCode, country };
    };

    const handleSetLocation = async (latLng) => {
        if (!latLng) return;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const place = results[0];
                const { city, state, zipCode, country } = extractAddressComponents(place);
                validation.setFieldValue('address', place.formatted_address);
                setLocation(place?.formatted_address);
                validation.setFieldValue('lat', latLng.lat);
                validation.setFieldValue('lng', latLng.lng);
                validation.setFieldValue('city', city);
                validation.setFieldValue('state', state);
                validation.setFieldValue('country', country);
                validation.setFieldValue('zipCode', zipCode);
                setShowModal(false);
            } else {
                console.error(`Geocode failed: ${status}`);
                validation.setFieldError('address', 'Failed to retrieve address from coordinates');
            }
        });
    };

    const loadCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLatLng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setLatLng(userLatLng);
                    if (map) {
                        map.panTo(userLatLng);
                    }
                },
                (error) => {
                    console.error("Geolocation failed: ", error);
                }
            );
        }
    };

    const onLoad = (mapInstance) => {
        setMap(mapInstance);
        loadCurrentLocation();
    };

    useEffect(() => {
        if (userData) {
            setLocation(userData?.address)
            setImageUrl(userData?.profilePicture)
            validation.setFieldValue('address', userData?.address);
            validation.setFieldValue('fullName', userData?.name);
            validation.setFieldValue('email', userData?.email);
            validation.setFieldValue('phone', userData?.phone);
            validation.setFieldValue('lat', userData?.location?.coordinates[1]);
            validation.setFieldValue('lng', userData?.location?.coordinates[0]);
            validation.setFieldValue('city', userData?.city);
            validation.setFieldValue('state', userData?.state);
            validation.setFieldValue('zipCode', userData?.zipcode);
            validation.setFieldValue('country', userData?.country);
            if (isLoaded && map) {
                map.panTo({ lat: userData?.location?.coordinates[1], lng: userData?.location?.coordinates[0] });
            }
        }
    }, [userData])

    const handlePhoneChange = (value) => {
        validation.setFieldValue('phone', value);
        validation.setFieldTouched('phone', true, true);
    };

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Profile' serviceName={userData?.name} />
            <Container className='py-5'>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <div className="mb-4 position-relative">
                        <h5 className="text_primary mb-3 plusJakara_semibold" htmlFor="password">
                            Choose Logo/Profile Image
                        </h5>
                        <div className="flex flex-col items-start mb-3 gap-2">
                            <div>
                                <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor="fileInput"
                                    className="cursor-pointer"
                                >
                                    {fileLoading ? (
                                        <div
                                            style={{ width: "120px", height: "120px" }}
                                            className="border rounded-3 bg_light text_primary d-flex justify-content-center align-items-center"
                                        >
                                            <CircularProgress size={20} />
                                        </div>
                                    ) : imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="Preview"
                                            style={{
                                                width: "120px",
                                                height: "120px",
                                                objectFit: "cover",
                                            }}
                                            className="rounded-3 bg_light object-cover"
                                        />
                                    ) : (
                                        <div className="relative w-full">
                                            <div
                                                style={{ width: "120px", height: "120px", backgroundColor: "#eeeeee", border: '1px solid #d3d3d3' }}
                                                className="rounded-3 text_secondary2 relative flex justify-center items-center"
                                            >
                                                <FaUserLarge size={50} />
                                            </div>
                                            <div className="absolute -bottom-1 right-0">
                                                <button type="button" className="bg_primary text_white rounded-3 p-1">
                                                    <MdOutlineEdit size={20} />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </label>
                                <Input
                                    type="file"
                                    // required
                                    accept='image/*'
                                    id="fileInput"
                                    className="visually-hidden"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                    </div>
                    <Row gutter={16}>
                        <Col xs={24} lg={12}>
                            <div className="flex gap-3 flex-col">
                                <h5 className="text_primary plusJakara_semibold">Project /Estiamate Location</h5>
                                <div className="flex justify-between items-center flex-wrap flex-md-nowrap w-full gap-2">
                                    {!location ?
                                        <>
                                            <Autocomplete
                                                id="address"
                                                name="address"
                                                apiKey={process.env.REACT_APP_APIKEY}
                                                className="w-full border search_input rounded-lg plusJakara_regular ps-3 py-[14px]"
                                                ref={autocompleteRef2}
                                                placeholder="Address here..."
                                                options={{
                                                    types: ['address'],
                                                }}
                                                onPlaceSelected={(place) => handlePlaceSelected(place)}
                                                onBlur={validation.handleBlur}
                                            />
                                            {validation.touched.address && validation.errors.address && (
                                                <FormFeedback type="invalid" className="plusJakara_regular text-xs">
                                                    {validation.errors.address}
                                                </FormFeedback>
                                            )}
                                        </> :
                                        <Input
                                            type="text"
                                            className="form-control w-full rounded-3 text_secondary2 text-sm plusJakara_medium"
                                            name="country"
                                            id="country"
                                            onChange={(e) => setLocation(e.target.value)}
                                            style={{ padding: '14px 16px', }}
                                            placeholder="Country"
                                            value={location}
                                        />}
                                    <button
                                        type="button"
                                        style={{ border: '1.5px solid #FF8E00' }}
                                        className="rounded-3 plusJakara_medium text_primary2 w-full py-[12px] flex justify-center gap-2"
                                        onClick={() => setShowModal(true)}
                                    >
                                        <TbCurrentLocation size={20} color='#F6921E' className="input-icon" />
                                        Set Location on Map
                                    </button>
                                </div>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="country"
                                        id="country"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Country"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.country || ""}
                                        invalid={
                                            validation.touched.country && validation.errors.country ? true : false
                                        }
                                    />
                                    {validation.touched.country && validation.errors.country ? (
                                        <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="city"
                                        id="city"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="City"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.city || ""}
                                        invalid={
                                            validation.touched.city && validation.errors.city ? true : false
                                        }
                                    />
                                    {validation.touched.city && validation.errors.city ? (
                                        <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="state"
                                        id="state"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="State"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.state || ""}
                                        invalid={
                                            validation.touched.state && validation.errors.state ? true : false
                                        }
                                    />
                                    {validation.touched.state && validation.errors.state ? (
                                        <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="zipCode"
                                        id="zipCode"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Zip"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.zipCode || ""}
                                        invalid={
                                            validation.touched.zipCode && validation.errors.zipCode ? true : false
                                        }
                                    />
                                    {validation.touched.zipCode && validation.errors.zipCode ? (
                                        <FormFeedback type="invalid">{validation.errors.zipCode}</FormFeedback>
                                    ) : null}
                                </div>
                                {/* {isLoaded ? (
                                    <div style={{ overflow: 'hidden', borderRadius: "20px" }}>
                                        <GoogleMap
                                            mapContainerStyle={{ width: '100%', height: '10rem' }}
                                            center={{
                                                lat: staticLat,
                                                lng: staticLng,
                                            }}
                                            zoom={16}
                                            apiKey={process.env.REACT_APP_APIKEY}
                                        >
                                            <MarkerF
                                                icon={{
                                                    url: mapmarker,
                                                    scaledSize: new window.google.maps.Size(35, 35),
                                                    className: 'custom-marker-icon',
                                                }}
                                                position={{ lat: staticLat, lng: staticLng }}
                                            />
                                        </GoogleMap>
                                    </div>
                                ) : null} */}
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <div className="flex gap-3 flex-col">
                                <h5 className="text_primary plusJakara_semibold">Contact Information</h5>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="text"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="fullName"
                                        id="fullName"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Full Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.fullName || ""}
                                        invalid={
                                            validation.touched.fullName && validation.errors.fullName ? true : false
                                        }
                                    />
                                    {validation.touched.fullName && validation.errors.fullName ? (
                                        <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="d-flex tab-content w-full justify-content-center flex-column align-items-center gap-1">
                                    <PhoneInput
                                        country={"us"}
                                        placeholder="Phone Number"
                                        disabled
                                        value={validation.values.phone}
                                        onChange={handlePhoneChange}
                                        // style={{ border: '1px solid #D4D4D4' }}
                                        onBlur={validation.handleBlur}
                                        // inputStyle={{  }}
                                        className="phon_inp text_secondarydark rounded-5 plusJakara_medium"
                                        disableSearchIcon={true}
                                    />
                                    {validation.touched.phone && validation.errors.phone && (
                                        <FormFeedback type="invalid" className="d-block">{validation.errors.phone}</FormFeedback>
                                    )}
                                </div>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="email"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="email"
                                        id="email"
                                        disabled
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Email Address"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                </div>
                                {/* <div className="flex flex-col w-full">
                                    <Input
                                        type="date"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="date"
                                        id="date"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Date"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.date || ""}
                                        invalid={
                                            validation.touched.date && validation.errors.date ? true : false
                                        }
                                    />
                                    {validation.touched.date && validation.errors.date ? (
                                        <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="flex flex-col w-full">
                                    <Input
                                        type="time"
                                        className="form-control rounded-3 text_secondary2 plusJakara_medium"
                                        name="time"
                                        id="time"
                                        style={{ padding: '14px 16px', }}
                                        placeholder="Zip"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.time || ""}
                                        invalid={
                                            validation.touched.time && validation.errors.time ? true : false
                                        }
                                    />
                                    {validation.touched.time && validation.errors.time ? (
                                        <FormFeedback type="invalid">{validation.errors.time}</FormFeedback>
                                    ) : null}
                                </div> */}
                                <div className="flex justify-center mt-2 w-full">
                                    <button type='submit' disabled={isLoading || fileLoading} className="bg_primary text-[16px] py-3 w-full rounded-3 plusJakara_medium text_white">
                                        {isLoading ? <Spinner size="sm" /> :
                                            "Save Changes"}</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>

            <Modal
                zIndex={999}
                width={600}
                open={showModal}
                footer={null}
                onCancel={() => setShowModal(false)}
                centered
            >
                <h5 className="plusJakara_medium mt-3 mb-2 text_primary">Select Location</h5>

                {isLoaded && (
                    <div className="rounded-3 overflow-hidden" style={{ width: '100%', height: '400px' }}>
                        <GoogleMap
                            center={{ lat: latLng?.lat, lng: latLng?.lng }}
                            zoom={10}
                            mapContainerStyle={{ width: '100%', height: '100%' }}
                            onLoad={onLoad}
                        >
                            {latLng && (
                                <Marker
                                    icon={{
                                        url: mapmarker,
                                        scaledSize: new window.google.maps.Size(35, 35),
                                    }}
                                    position={{ lat: latLng.lat, lng: latLng.lng }}
                                    draggable={true}
                                    onDragEnd={(e) => {
                                        const draggedLatLng = {
                                            lat: e.latLng.lat(),
                                            lng: e.latLng.lng(),
                                        };
                                        setLatLng(draggedLatLng);
                                    }}
                                />
                            )}
                        </GoogleMap>
                    </div>
                )}

                <div className="mt-4">
                    <button
                        className="py-3 px-4 rounded-5 bg_primary text_white plusJakara_medium w-full"
                        onClick={() => handleSetLocation(latLng)}
                    >
                        Set Location
                    </button>
                </div>
            </Modal>
        </ main >
    );
};

export default Profile;
