/* eslint-disable no-unused-vars */
import React from 'react';
import { SlLocationPin } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { cleaning5 } from '../icons/icon';

const EstimateItem = ({ category, showView = true }) => {
    const navigate = useNavigate()
    const categories = [1, 2, 3, 4, 5, 6];

    return (
        <div style={{ border: '0.83px solid #FFFFFF' }} className="bg_white border flex gap-3 p-3 flex-col xl:flex-row xl:items-center shadow-sm w-full rounded-4 overflow-hidden relative">
            <img src={cleaning5} onClick={() => navigate('/all-estimates/2345607965', { state: { estimateDetail: category } })} style={{ maxWidth: '12rem', maxHeight: "12rem", height: '100%', width: '100%', objectFit: 'cover', }} className='rounded-4 cursor-pointer' alt="" />
            <div className='flex gap-3 flex-col items-center w-full'>
                <div className="flex justify-between flex-wrap flex-lg-nowrap items-start w-full gap-1">
                    <div className="flex flex-col w-full gap-1">
                        <div onClick={() => navigate('/all-estimates/2345607965', { state: { estimateDetail: category } })} className="flex flex-col gap-1 cursor-pointer w-full">
                            <div className="flex gap-1 items-end">
                                <span className="text_secondary2 plusJakara_semibold mb-0">Estimate No:</span>
                                <span className="text_black plusJakara_semibold text-sm mb-0">#607965</span>
                            </div>
                            <h5 className="plusJakara_semibold line-clamp-1 mb-0">Lanudry Services</h5>
                            <div className="flex gap-2 items-center">
                                <SlLocationPin />
                                <span className="text_secondary plusJakara_regular">1995 Broadway, New York</span>
                            </div>
                            <div className="flex flex-col my-2 w-full">
                                <span className="text_secondary text-sm plusJakara_regular">Time 09:00AM</span>
                                <span className="text_secondary text-sm plusJakara_regular">Date Wed, 20 May 2024</span>
                            </div>
                        </div>
                        <div className="flex gap-2 items-center justify-between w-full flex-wrap">
                            <h5 className="text_black plusJakara_semibold mb-0">$3000</h5>
                            <div className="flex gap-2 my-2 items-center flex-wrap">
                                {showView ? <>
                                    <button
                                        style={{ border: '1px solid #003F7D' }}
                                        className="flex justify-center text_primary rounded-5 px-4 py-2 plusJakara_medium text-xs"
                                    >Cancel</button>
                                    <button
                                        className="flex justify-center bg_primary text_white rounded-5 px-4 py-2 plusJakara_medium text-xs"
                                    >Confirm</button>
                                </> : <h5 className="text_primary plusJakara_semibold">Waiting for Estimate</h5>}
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-auto flex flex-col items-end justify-between">
                        <button
                            className="flex justify-center items-center bg_light"
                            style={{ width: '40px', height: "40px", objectFit: 'cover', borderRadius: "50%" }}
                        >
                            <FiHeart className='text_secondary' size={20} />
                        </button>
                    </div> */}
                </div>
            </div>
        </div >
    )
}

export default EstimateItem