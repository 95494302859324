/* eslint-disable no-unused-vars */
import React from 'react';
import { FaStar } from "react-icons/fa6";
import { FiHeart } from 'react-icons/fi';
import { SlLocationPin } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { cleaning5 } from '../icons/icon';

const InvoiceItem = ({ category, showView = true }) => {
    const navigate = useNavigate()
    const categories = [1, 2, 3, 4, 5, 6];

    return (
        <div style={{ border: '0.83px solid #FFFFFF' }} className="bg_white border cursor-pointer flex gap-3 p-3 flex-col xl:flex-row xl:items-center shadow-sm w-full rounded-4 overflow-hidden relative">
            <img src={cleaning5} style={{ maxWidth: '12rem', maxHeight: "12rem", height: '100%', width: '100%', objectFit: 'cover', }} className='rounded-4' alt="" />
            <div className='flex gap-3 flex-col items-center w-full'>
                <div className="flex justify-between flex-wrap flex-lg-nowrap items-start w-full gap-1">
                    <div className="flex flex-col gap-1">
                        <h5 className="plusJakara_semibold line-clamp-1 mb-0">Lanudry Services</h5>
                        <div className="flex gap-2 items-center">
                            <SlLocationPin />
                            <span className="text_secondary plusJakara_regular">1995 Broadway, New York</span>
                        </div>
                        <div className="flex gap-1 items-center flex-wrap">
                            {categories?.map((item, i) => (
                                <>
                                    <span key={i} className="text_secondary2 text-sm plusJakara_regular">Double Wash</span>
                                    {i < categories.length - 1 && <div style={{ height: "15px", width: '1.5px' }} className='bg_secondary2'> </div>}
                                </>
                            ))}
                        </div>
                        <div className="flex gap-2 my-2 items-center justify-start flex-wrap">
                            <button
                                style={{ backgroundColor: '#EAF0FE', color: "#747399" }}
                                className="flex justify-center rounded-3 px-4 py-2 plusJakara_medium text-xs"
                            >Washing</button>
                            <button
                                style={{ backgroundColor: '#E9F7EF', color: "#00A506" }}
                                className="flex justify-center rounded-3 px-4 py-2 plusJakara_medium text-xs"
                            >Dry Cleaning</button>
                        </div>
                    </div>
                    {/* <div className="w-auto">
                        <button
                            className="flex justify-center items-center bg_light"
                            style={{ width: '40px', height: "40px", objectFit: 'cover', borderRadius: "50%" }}
                        >
                            <FiHeart className='text_secondary' size={20} />
                        </button>
                    </div> */}
                </div>
                {/* <img src={category?.image || cat1} style={{ width: '100px', height: 'auto' }} alt="" /> */}
            </div>
        </div >
    )
}

export default InvoiceItem