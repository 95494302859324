/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import { message, Modal } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";
import { FaArrowLeft, FaCircleCheck } from "react-icons/fa6";
import "react-phone-input-2/lib/style.css";
import ReactCodeInput from 'react-verification-code-input';
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row
} from "reactstrap";
import * as Yup from "yup";
import { forgotPassword, updateUserPassword } from "../api/signup";

const ResetPassword = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [forgotData, setforgotData] = useState(state?.forgotData || null)
  const dispatch = useDispatch()
  const [value, setValue] = useState('');
  const [timer, setTimer] = useState(50);
  const [showResendLink, setShowResendLink] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  console.log(forgotData);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setShowResendLink(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const handleResendCode = async () => {
    if (forgotData) {
      await forgotPassword(forgotData?.phone)
        .then((res) => {
          if (res.data?.success === true) {
            message.success('Code sent successfully')
            setforgotData({
              ...forgotData,
              token: res.data.token,
            })
            setTimer(50);
            setShowResendLink(false);
          }
        }).catch((err) => {
          message.error(err.response.data.message);
        })
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(15, 'Maximum 15 characters allowed')
        .required("Please Enter Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your password')
    }),
    onSubmit: async (values, { resetForm }) => {
      setisLoading(true);
      await updateUserPassword(values?.password, forgotData?.token, value)
        .then((res) => {
          console.log(res);
          if (res.data.success === true) {
            navigate("/login");
            setisLoading(false)
            message.success(res.data.message)
          } else {
            setisLoading(false)
          }
        }).catch((err) => {
          message.error(err.response.data.message)
          setisLoading(false)
        })
      setisLoading(false);
      setmodalOpen(true)
    }
  });

  const handleConfirm = () => {
    navigate("/login");
  }


  return (
    <React.Fragment>
      <Container fluid style={{ backgroundColor: '#F5F5F5', paddingTop: "5rem", paddingBottom: "1rem" }} className="flex justify-center min-vh-100 items-center w-full">
        <Card className="bg_white rounded-4 max-w-3xl d-flex align-items-center flex-column py-5 max-lg:px-8 lg:px-12">
          <div className="absolute top-10" style={{ left: '25px', }}>
            <Link to={-1}>
              <div style={{ width: '50px', height: "50px" }} className="bg_light flex justify-center items-center text_primary rounded-5">
                <FaArrowLeft size={24} />
              </div>
            </Link>
          </div>
          <h1 className="plusJakara_semibold text-2xl lg:text-3xl text-center text_primary">Create A New Password</h1>
          <h6 className="text_secondary2 max-w-lg plusJakara_regular text-center">Your new password should be strong and secure.
            Please follow the guidelines</h6>
          <Form
            style={{ maxWidth: '600px', width: '100%' }}
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            className=" mt-4 auth_form">
            <Row>
              <div className="mt-4 flex justify-center">
                <div className="flex flex-col gap-1 w-full">
                  <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="password">
                    Enter Verification Code
                  </Label>
                  <ReactCodeInput
                    fields={6}
                    value={value}
                    onChange={(e) => setValue(e)}
                    className="w-100"
                  />
                </div>
              </div>

              <div className="login_main p-4">
                {!showResendLink &&
                  <h6 className="resend_buton text_secondary2 text-center">Resend Code in <span className="text_dark inter_medium"> {timer}s</span></h6>}
                <div className="mt-3">
                  {showResendLink ? (
                    <h6 className="counter_con text-center flex-wrap">
                      Didn't get a code?
                      <span
                        className="text_primary2 underline ml-md-2 inter_medium"
                        onClick={handleResendCode}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Resend Code
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mb-4 position-relative">
                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="password">
                  Password
                </Label>
                <Input
                  type={showPassword ? "text" : "password"}
                  className="form-control rounded-3 relative"
                  name="password"
                  id="password"
                  style={{ padding: '14px 16px', }}
                  placeholder="Enter your password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password ? true : false
                  }
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'absolute',
                    right: '40px',
                    top: '47px',
                    zIndex: 99,
                    cursor: "pointer"
                  }}
                >
                  {!showPassword ? <EyeOff size={20} color="#C4C4C4" /> : <Eye size={20} color="#C4C4C4" />}
                </span>
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                ) : null}
              </div>

              <div className="mb-3 position-relative">
                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="confirmPassword">
                  Confirm Password
                </Label>
                <span
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{
                    position: 'absolute',
                    top: '47px',
                    right: '40px',
                    zIndex: 99,
                    cursor: "pointer"
                  }}
                >
                  {!showConfirmPassword ? <EyeOff size={20} color="#C4C4C4" /> : <Eye size={20} color="#C4C4C4" />}
                </span>
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control rounded-3"
                  name="confirmPassword"
                  id="confirmPassword"
                  style={{ padding: '14px 16px', }}
                  placeholder="Please Confirm your Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirmPassword || ""}
                  invalid={
                    validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                  }
                />
                {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                  <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                ) : null}
              </div>


              <Col lg={12} className="mb-0 mt-3">
                <div className="d-grid">
                  <button className="py-3 rounded-5 bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                    {isLoading ? <Spinner size="sm"></Spinner> :
                      "Reset Password"}
                  </button>
                </div>
              </Col>

              <Col className="text-center mt-3">
                <div className="flex gap-1 justify-center items-center w-full">
                  <h6 className="text_primary mb-0">
                    Remembered your password?
                  </h6>{" "}
                  <Link
                    to="/login"
                    className="text_primary2 mb-0 plusJakara_medium"
                  >
                    Sign In
                  </Link>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>

      <Modal
        centered
        open={modalOpen}
        footer={null}
        closeIcon={false}
        width={400}
        onCancel={() => setmodalOpen(false)}
      >
        <div className="flex w-full gap-4 items-center flex-col">
          <FaCircleCheck color="#00C566" className="mb-3" size={80} />
          <h4 className="text_black text-center mb-0 plusJakara_semibold">Changed Successfully</h4>
          <span className="text_secondary2 text-center plusJakara_regular">Your password has been changed successfully.</span>
          <button
            onClick={handleConfirm}
            className="py-3 rounded-5 w-full bg_primary plusJakara_medium text_white"
            disabled={isLoading}
          >
            {isLoading ? <Spinner size="sm" /> : "Sign In"}
          </button>
        </div>
      </Modal>

    </React.Fragment >
  );
};
export default ResetPassword;
