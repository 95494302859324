/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Blogs from './homeComponents2/blogs';
import BusinessWork from './homeComponents2/businessWork';
import Categories from './homeComponents2/categories';
import HomeHeader2 from './homeComponents2/homeHeader2';
import HomeServices from './homeComponents2/homeServices';
import ProviderPortfolio from './homeComponents2/providerPortfolio';
import ShopServices from './homeComponents2/shopServices';


const Home2 = () => {

    const handleCategorySelect = (category) => {
    };

    return (
        <React.Fragment>
            <HomeHeader2 />
            <Categories navigateOnCategoryClick={true} onSelectCategory={handleCategorySelect} />
            <HomeServices />
            <ProviderPortfolio />
            <BusinessWork />
            <ShopServices />
            <Blogs />
        </React.Fragment >
    );
}

export default Home2;