import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { dots } from '../../icons/icon'

const HeaderNavigation = ({ page, serviceName, subPage, subPageAvail = false, mainPage }) => {
    return (
        <div style={{ background: '#FAFAFA', marginTop: '5rem' }}>
            <Container className='py-5 flex justify-between items-center'>
                <div className="flex flex-col gap-2">
                    <div className="flex items-center">
                        <Link to='/' className="text_secondary text-xl no-underline plusJakara_medium">Home / </Link>
                        {subPageAvail === true ?
                            <Link to={mainPage} className="text_secondary no-underline text-xl mb-0 plusJakara_medium ms-1"> {page} </Link>
                            : <h5 className="text_primary mb-0 plusJakara_medium ms-1"> {page} </h5>}
                        {subPageAvail &&
                            <h5 className="text_primary mb-0 plusJakara_medium ms-1">/ {subPage} </h5>
                        }
                    </div>
                    <div className="flex items-center">
                        <h3 className="text_black plusJakara_semibold">{serviceName} </h3>
                    </div>
                </div>
                <div className="hidden md:flex flex-col gap-1">
                    {/* <img src={dots} style={{ height: '50px', width: 'auto' }} alt="" /> */}
                    <img src={dots} style={{ height: '50px', width: 'auto', }} alt="" />
                </div>
            </Container>
        </div>
    )
}

export default HeaderNavigation