/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CircularProgress, Button } from "@mui/material";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "reactstrap";
import axiosInstance from "../../redux/axiosInstance";
import { setUserData } from "../../redux/loginForm";

const RedirectPage = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [returnHome, setReturnHome] = useState(false);
    const navigate = useNavigate();
    const account_Id = searchParams.get("account_id");
    const accessToken = useSelector((state) => state.auth?.accessToken);

    const getProfile = async () => {
        try {
            const res = await axiosInstance.get("users/me");
            dispatch(setUserData(res?.data?.user));
        } catch (error) {
            message.error(error?.response?.data?.message);
            console.error("Error updating user:", error.response.data);
        }
    };

    const handleVerifyUser = async () => {
        const token = accessToken;
        const accountId = account_Id;
        const body = { token, accountId };
        try {
            const response = await axiosInstance.post("payment/verifyAccount", body);
            if (response.data.success === true) {
                console.log(response, 'respomnse');
                getProfile();
                navigate('/business-profile?selected=connect-stripe', { replace: true });
                setReturnHome(false);
                // message.success("Account Connected successfully!");
                window.close()
            } else {
                setReturnHome(true);
                message.error("Verification failed.");
            }
        } catch (error) {
            setReturnHome(true);
            console.error("Error verifying account:", error);
        }
    };

    useEffect(() => {
        if (account_Id) {
            handleVerifyUser();
        }
    }, [account_Id]);

    return (
        <React.Fragment>
            <Container fluid className="flex justify-center items-center h-screen">
                {(!returnHome || account_Id) ? (
                    <div className="flex gap-3 w-full items-center justify-center">
                        <CircularProgress size={45} thickness={4} style={{ color: "#003F7D" }} />
                        <h4 className="plusJakara_semibold mb-0 text_primary">Loading</h4>
                    </div>
                ) : (
                    <div className="flex flex-col items-center text-center">
                        <h4 className="text-red-500 plusJakara_semibold mb-4">
                            Your account did not link
                        </h4>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/', { replace: true })}
                            style={{ padding: "10px 20px", borderRadius: "8px" }}
                        >
                            Go to Home
                        </Button>
                    </div>
                )}
            </Container>
        </React.Fragment>
    );
};

export default RedirectPage;