/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Col, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../style/swiper.css";
// import * as Yup from "yup"
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { FaArrowLeft, FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoIosCheckmarkCircle, IoMdCheckboxOutline } from "react-icons/io";
import { MdEmail, MdLocationOn, MdOutlineShoppingCart } from "react-icons/md";
import { TbProgress } from "react-icons/tb";
import { useSelector } from "react-redux";
import { Form, FormFeedback, Input, Label } from "reactstrap";
import * as Yup from "yup";
import DTable from "../DataTable/DTable";
import { getEmployeeTaskSummary } from "../api/employee";
import { sendReminder } from "../api/estimateApi";
import { completeOrder, getSpeceficDetail } from "../api/order";
import { avataruser } from "../icons/icon";
import { encryptData } from "../redux/localStorageSecure";
import AssignEmployee from "./common/assignEmployee";
import HeaderNavigation from "./common/headerNavigation";
import LabourMaterialTableDesign from "./estimate/labourMaterialTableDesign";
import { MapClick } from "../common/mapClick";

const OrderDetail = ({ showButtons = true }) => {
    const { state } = useLocation();
    const { searchParams } = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showReminder, setshowReminder] = useState(false);
    const [selectedEmployee, setselectedEmployee] = useState(null);
    const [selectedEmployeeSummary, setselectedEmployeeSummary] = useState([]);
    const [modalOpen, setmodalOpen] = useState(false);
    const [completeLoading, setcompleteLoading] = useState(false)
    const [modalOpen2, setmodalOpen2] = useState(false);
    const userData = useSelector((state) => state.auth?.userData)
    const [selectedItem, setselectedItem] = useState(null);
    const usertype = useSelector((state) => state.auth?.userData?.type);
    const [orderData, setOrderData] = useState(state?.orderData || null);
    const [grandTotal, setGrandTotal] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!orderData && id) {
            getSpeceficDetail("order", id)
                .then((res) => {
                    if (res?.order) {
                        setOrderData(res.order);
                    }
                })
                .catch((err) => {
                    message.error(err.response.data.message);
                });
        }
    }, [id]);

    const orderSteps = [
        {
            title: "Order Started",
            subtitle: "Order has been Started",
            icon: <TbProgress size={24} />,
            isComplete: true,
        },
        {
            title: "Order Assign",
            subtitle: "Order Assigned to Employees",
            icon: <MdOutlineShoppingCart size={24} />,
            isComplete: orderData?.assign_users?.length > 0,
        },
        {
            title: "Completed from Employees",
            subtitle: "Employee has completed the order",
            icon: <TbProgress size={24} />,
            isComplete: orderData?.assign_users?.some((i) => i?.status === "complete"),
        },
        {
            title: "Order Completed",
            subtitle: "Order has been completed",
            icon: <IoMdCheckboxOutline size={24} />,
            isComplete: orderData?.status === "complete",
        },
    ];

    const getTime = (start, end) => {
        if (!end) {
            return "...";
        }
        const checkIn = moment(start);
        const checkOut = moment(end);
        const duration = moment.duration(checkOut.diff(checkIn));
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        const timeDiff = `${days > 0 ? days + "d " : ""}${String(hours).padStart(
            2,
            "0"
        )}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        return timeDiff;
    };


    const handleSubmit = async (row) => {
        setcompleteLoading(true)
        await completeOrder(row?._id).then((res) => {
            if (res.success === true) {
                message.success("Order completed successfully");
                setcompleteLoading(false)
                navigate(-1)
            } else {
                message.error("Failed to Order completiing");
                setcompleteLoading(false)
            }
        }).catch((err) => {
            console.error("Error Order completiing:", err);
            message.error(err.response.data.message)
            setcompleteLoading(false)
        })
    }

    const columns3 = [
        {
            name: 'Task Name',
            sortable: true,
            minWidth: '350px',
            maxWidth: '350px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>{row?.labourName}</span>)
        },
        {
            name: 'Units',
            sortable: true,
            maxWidth: '110px',
            minWidth: '110px',
            cell: row => (<span className='plusJakara_medium'>{row?.labourUnit + ' ' + row?.laboutUnitType}</span>)
        },
        {
            name: 'Price',
            sortable: true,
            maxWidth: '110px',
            minWidth: '110px',
            // maxWidth: '250px',
            selector: row => '$ ' + row?.labourPrice
        },
        {
            name: 'Total',
            sortable: true,
            maxWidth: '110px',
            minWidth: '110px',
            cell: row => (<span className='plusJakara_medium'>${parseInt(row?.labourPrice) * parseInt(row?.labourUnit)}</span>)
        },
        {
            name: 'Dates',
            sortable: true,
            maxWidth: '200px',
            minWidth: '200px',
            // maxWidth: '250px',
            cell: row => (<span className='plusJakara_medium'>{moment(row?.startDate).format('MMM DD,YY') + ' to ' + moment(row?.endDate).format('MMM DD,YY')}</span>)
        },
    ]
    const columns4 = [
        {
            name: 'Date',
            sortable: true,
            minWidth: '200px',
            maxWidth: '250px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>{moment(row?.checkIn).format("YYYY-MM-DD")}</span>)
        },
        {
            name: 'Time In',
            sortable: true,
            maxWidth: '250px',
            minWidth: '200px',
            // maxWidth: '250px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>  {row?.checkIn ? moment(row?.checkIn).format("HH:mm:ss") : "..."}</span>)
        },
        {
            name: 'Time Out',
            sortable: true,
            maxWidth: '250px',
            minWidth: '200px',
            // maxWidth: '250px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>  {row?.checkOut ? moment(row?.checkOut).format("HH:mm:ss") : "..."}</span>)
        },
        {
            name: 'Total',
            sortable: true,
            maxWidth: '250px',
            minWidth: '200px',
            cell: row => {
                return (
                    <span className='plusJakara_medium text-[16px]'>{getTime(row?.checkIn, row?.checkOut)}</span>
                );
            }
        }
    ]

    const handleAssignOrder = (row) => {
        setmodalOpen(true);
        setselectedItem(row);
    };
    const handleSelectEmployee = async (row) => {
        setselectedEmployee(row);
        setmodalOpen2(true);
        await getEmployeeTaskSummary(orderData?._id, row?.user?._id)
            .then((res) => {
                if (res?.success) {
                    setselectedEmployeeSummary(res?.checkIns);
                } else {
                    setselectedEmployeeSummary([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (orderData?.service?.features) {
            const total = orderData?.service?.features?.reduce(
                (sum, feature) => sum + (feature.totalPrice || 0),
                0
            );
            setGrandTotal(total);
        }
    }, [orderData]);

    const workInProgress = orderData?.assign_users?.find(
        (item, i) => item.status === "pending"
    );

    const handleCreateInvoice = (row) => {
        navigate(`/orders/invoice/${row?._id}`, { state: { invoiceData: row } });
    };

    const calculateTotal = () => {
        const tax =
            (orderData?.service?.totalPrice * Number(orderData?.service?.tax)) / 100;
        const taxedPrice = orderData?.service?.totalPrice + tax;
        return (taxedPrice - orderData?.service?.dis_price)?.toFixed(2);
    };

    const handleNavigate = (item) => {
        const params = new URLSearchParams(searchParams);
        const data = {
            user: usertype === "company" ? item?.user : item?.to_id,
        };
        params.set("detail-user", encryptData(data));
        navigate(`/chat?${params.toString()}`);
    };

    const validationSchema = Yup.object().shape({
        reminder: Yup.string().required("Please enter a Message"),
    });
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            reminder: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            const data = {
                type: "order",
                reminder: values.reminder,
                order: orderData?._id,
                to_id: orderData?.user?._id,
            };
            setIsLoading(true);
            await sendReminder({ data: data })
                .then((res) => {
                    if (res?.success === true) {
                        message.success("Reminder sent successfully");
                        setIsLoading(false);
                        setshowReminder(false);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    setshowReminder(false);
                });
        },
    });

    return (
        <main className={`${usertype === "company" ? "p-5" : "pb-5"} w-full`}>
            {usertype !== "company" && (
                <HeaderNavigation
                    mainPage={"/orders?selected=all"}
                    subPage={`Order Detail`}
                    subPageAvail={true}
                    page={`Orders`}
                    serviceName={orderData?.service?.name || orderData?.estimate?.name}
                />
            )}
            {!orderData ? (
                <div className="flex justify-center w-full items-center my-5">
                    <CircularProgress size={24} style={{ color: "black" }} />
                </div>
            ) : (
                <Container
                    fluid={usertype === "company"}
                    className="bg_white rounded-3 p-4 w-full"
                >
                    <div className="flex gap-4 items-center justify-between w-full mb-4">
                        <div className="flex flex-col gap-3 w-full">
                            {usertype === "company" && (
                                <div className="flex gap-4 mb-3 items-center w-full">
                                    <button
                                        onClick={() => navigate(-1)}
                                        className="bg_primary rounded-3 p-2"
                                    >
                                        <FaArrowLeft className="text_white" />
                                    </button>
                                    <h4 className="text_primary mb-0 plusJakara_semibold">
                                        Order Detail
                                    </h4>
                                </div>
                            )}
                            <div className="flex gap-3 items-start w-full">
                                <img
                                    src={
                                        (orderData?.user?.profilePicture ||
                                            orderData?.to_id?.profilePicture) || avataruser
                                    }
                                    style={{
                                        width: "120px",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: "12px",
                                    }}
                                    alt=""
                                />
                                <div className="flex flex-col w-full">
                                    <h5 className="text_black plusJakara_semibold">
                                        {orderData?.user?.name || orderData?.to_id?.name}
                                    </h5>
                                    <div className="flex gap-2 items-center">
                                        <FaLocationDot className="text_primary" />
                                        <span
                                            onClick={() =>
                                                MapClick(orderData?.user?.location?.coordinates[1] || orderData?.to_id?.location?.coordinates[1],
                                                    orderData?.user?.location?.coordinates[0] || orderData?.to_id?.location?.coordinates[0]
                                                )}
                                            className="text_secondary2 cursor-pointer hover:underline whitespace-nowrap plusJakara_medium">
                                            {orderData?.user?.address || orderData?.to_id?.address}
                                        </span>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <FaPhone className="text_primary" />
                                        <span className="text_secondary2 plusJakara_medium">
                                            {orderData?.user?.phone || orderData?.to_id?.phone}
                                        </span>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <MdEmail className="text_primary" />
                                        <span className="text_secondary2 plusJakara_medium">
                                            {orderData?.user?.email || orderData?.to_id?.email}
                                        </span>
                                    </div>
                                    <button
                                        onClick={() => handleNavigate(orderData)}
                                        className="bg_primary mt-2 w-fit text_white plusJakara_regular text-xs px-4 py-2 rounded-2"
                                    >
                                        Message
                                    </button>
                                </div>
                            </div>
                        </div>
                        {usertype === 'company' && (
                            <div className="flex flex-col gap-2">
                                {(userData?.sub_type !== 'free') && (
                                    userData?.sub_type === 'basic' ? (
                                        orderData?.status === 'complete' ? (
                                            <button
                                                disabled
                                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <span className="text_black whitespace-nowrap text-sm plusJakara_regular">Complete</span>
                                            </button>
                                        ) : (
                                            <button
                                                className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                                onClick={() => handleCreateInvoice(orderData)}
                                            >
                                                <span className="text_white whitespace-nowrap text-sm plusJakara_regular">Create Invoice</span>
                                            </button>
                                        )
                                    ) : userData?.sub_type === 'standard' ? (
                                        orderData?.status === 'complete' ? (
                                            <button
                                                disabled
                                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <span className="text_black whitespace-nowrap text-sm plusJakara_regular">Complete</span>
                                            </button>
                                        ) : orderData?.invoice ? (
                                            <button
                                                disabled
                                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <span className="whitespace-nowrap text-sm plusJakara_regular">Invoice Sent</span>
                                            </button>) : (
                                            <button
                                                className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                                onClick={() => handleCreateInvoice(orderData)}
                                            >
                                                <span className="text_white whitespace-nowrap text-sm plusJakara_regular">Create Invoice</span>
                                            </button>
                                        )
                                    ) : (
                                        orderData?.status === 'complete' ? (
                                            <button
                                                disabled
                                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <span className="text_black whitespace-nowrap text-sm plusJakara_regular">Complete</span>
                                            </button>
                                        ) : orderData?.estimate ? (
                                            orderData?.assign_users.length === 0 ? (
                                                <button
                                                    className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                                    style={{ minWidth: '120px' }}
                                                    onClick={() => handleAssignOrder(orderData)}
                                                >
                                                    <span className="text_white whitespace-nowrap text-sm plusJakara_regular">Assign Order</span>
                                                </button>
                                            ) : orderData?.invoice ? (
                                                <button
                                                    disabled
                                                    className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                                    style={{ minWidth: '120px' }}
                                                >
                                                    <span className="whitespace-nowrap text-sm plusJakara_regular">Invoice Sent</span>
                                                </button>
                                            ) : orderData?.assign_users.find((item) => item.status === 'pending') ? (
                                                <button
                                                    disabled
                                                    className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
                                                    style={{ minWidth: '120px' }}
                                                >
                                                    <span className="text_black text-sm plusJakara_regular">Employees are working on Order</span>
                                                </button>
                                            ) : (
                                                <button
                                                    className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                                    style={{ minWidth: '120px' }}
                                                    onClick={() => handleCreateInvoice(orderData)}
                                                >
                                                    <span className="text_white whitespace-nowrap text-sm plusJakara_regular">Create Invoice</span>
                                                </button>
                                            )
                                        ) : orderData?.invoice ? (
                                            <button
                                                disabled
                                                className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                            >
                                                <span className="whitespace-nowrap text-sm plusJakara_regular">Invoice Sent</span>
                                            </button>
                                        ) : (
                                            <button
                                                className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
                                                style={{ minWidth: '120px' }}
                                                onClick={() => handleCreateInvoice(orderData)}
                                            >
                                                <span className="text_white whitespace-nowrap text-sm plusJakara_regular">Create Invoice</span>
                                            </button>
                                        )
                                    )
                                )}
                                {orderData?.status !== "complete" && (
                                    <button
                                        style={{ border: "1px solid #003F7D" }}
                                        onClick={() => setshowReminder(true)}
                                        className="text_primary rounded-2 whitespace-nowrap text-sm p-2 w-full plusJakara_medium"
                                    >
                                        Send reminder
                                    </button>
                                )}
                            </div>
                        )}
                        {usertype === 'customer' &&
                            (orderData?.user?.sub_type === 'free') && (
                                orderData?.status !== 'complete' ? (
                                    <button
                                        disabled={completeLoading}
                                        onClick={() => handleSubmit(orderData)}
                                        className="flex gap-1 items-center justify-center rounded-2 bg_primary px-3 py-2"
                                    >
                                        <span className="text_white whitespace-nowrap plusJakara_regular">
                                            {orderData?._id && completeLoading ? (
                                                <CircularProgress size={12} className="text_white" style={{ color: 'white' }} />
                                            ) : (
                                                'Complete Order'
                                            )}
                                        </span>
                                    </button>
                                ) : (
                                    <button
                                        disabled
                                        className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] text_black px-2 py-2"
                                        style={{ minWidth: '120px' }}
                                    >
                                        <span className="whitespace-nowrap text-xs plusJakara_regular">Order completed</span>
                                    </button>
                                )
                            )}
                    </div>
                    <hr style={{ color: "#828282" }} />
                    <div className="flex mt-4 flex-wrap gap-3 flex-col flex-md-nowrap justify-between w-full">
                        <div className="flex gap-3 items-center justify-between w-full">
                            <Col xs={24} md={11} xl={20}>
                                <div className="flex justify-between gap-5 items-start flex-wrap">
                                    <div className="flex flex-col gap-2">
                                        <h6 className="text_secondary2 plusJakara_semibold">
                                            Order Status
                                        </h6>
                                        <div
                                            className="px-2 py-1 w-fit text-center rounded-2"
                                            style={{ backgroundColor: "#E6E9F4", color: "#5A607F" }}
                                        >
                                            <span className="plusJakara_bold">
                                                {orderData?.status === "pending"
                                                    ? "On Going"
                                                    : orderData?.status}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <h6 className="text_secondary2 plusJakara_semibold">
                                            Order No
                                        </h6>
                                        <div
                                            className="px-2 py-1 w-fit text-center rounded-2"
                                            style={{ backgroundColor: "#f6911e1b" }}
                                        >
                                            <span className="plusJakara_medium text_primary2">
                                                #{orderData?.order_id}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <h6 className="text_secondary2 plusJakara_semibold">
                                            Booking Date
                                        </h6>
                                        <span className="text_black plusJakara_bold">
                                            {moment(orderData?.bookingDate).format("ddd DD MMM YYYY")}
                                        </span>
                                    </div>
                                    {!orderData?.estimate && (
                                        <div className="flex flex-col gap-2">
                                            <h6 className="text_secondary2 plusJakara_semibold">
                                                Order Price
                                            </h6>
                                            <div
                                                className="flex gap-2 items-end px-2 py-1 w-fit text-center rounded-2"
                                                style={{ backgroundColor: "#003e7d24" }}
                                            >
                                                {orderData?.service?.dis_price && (
                                                    <span
                                                        className="plusJakara_medium text-sm mb-0 text_secondary2"
                                                        style={{ textDecoration: "line-through" }}
                                                    >
                                                        ${orderData?.service?.totalPrice}
                                                    </span>
                                                )}
                                                <h6 className="plusJakara_semibold whitespace-nowrap mb-0 text_primary">
                                                    ${calculateTotal()}
                                                </h6>
                                                <span
                                                    className="mb-0 whitespace-nowrap plusJakara_medium text-sm"
                                                    style={{ color: "#555" }}
                                                >
                                                    (Includes {orderData?.service?.tax}% tax
                                                    {orderData?.service?.dis_price &&
                                                        `, ${orderData?.service.dis_price}$ discount`}
                                                    )
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </div>
                        <hr style={{ color: "#828282" }} />
                        <Col xs={24} md={11} xl={18}>
                            {orderData?.estimate && (
                                <div className="flex flex-col mb-5 gap-3">
                                    <h4 className="text_black plusJakara_semibold">
                                        Assigned Employees
                                    </h4>
                                    <Col xs={24} sm={18} md={12}>
                                        <div className="flex flex-col gap-2 items-start w-full">
                                            {orderData?.assign_users?.length > 0 ? (
                                                orderData?.assign_users?.map((item, i) => (
                                                    <div
                                                        key={i}
                                                        className="flex justify-between p-3 rounded-3 shadow-sm border gap-3 w-full items-center"
                                                    >
                                                        <div className="flex gap-3 items-start">
                                                            <img
                                                                src={item?.user?.profilePicture || avataruser}
                                                                style={{
                                                                    borderRadius: "50%",
                                                                    objectFit: "cover",
                                                                    width: "45px",
                                                                    height: "45px",
                                                                }}
                                                                alt=""
                                                            />
                                                            <div className="flex flex-col items-start">
                                                                <h1 className="plusJakara_semibold mb-0 text-lg text_primary">
                                                                    {item?.user?.name}
                                                                </h1>
                                                                <h6 className="plusJakara_regular text_secondary">
                                                                    {item?.task[0]?.labourName},...
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={() => handleSelectEmployee(item)}
                                                            className="bg_primary text_white h-fit plusJakara_medium rounded-2 px-3 py-2 text-sm"
                                                        >
                                                            View Tasks
                                                        </button>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="w-full text-center">
                                                    <span className="plusJakara_medium text_primary">
                                                        Not Found
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </div>
                            )}
                            {orderData?.estimate && (
                                <div className="flex flex-col mb-5 gap-3">
                                    <h4 className="text_black plusJakara_semibold">
                                        Order Progress
                                    </h4>
                                    <div className="flex flex-col w-full gap-4">
                                        {orderSteps.map((step, index) => (
                                            <Col xs={24} xl={12} key={index}>
                                                <div className="flex justify-between items-center w-full">
                                                    <div className="flex gap-3 w-full items-center">
                                                        <div
                                                            className="bg_light rounded-5 flex justify-center items-center"
                                                            style={{ width: "60px", height: "60px" }}
                                                        >
                                                            <span className={step.isComplete ? "text_primary" : "text_secondary2"}>
                                                                {step.icon}
                                                            </span>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <h5 className={`${step.isComplete ? "text_primary" : "text_secondary2"} plusJakara_semibold`}>
                                                                {step.title}
                                                            </h5>
                                                            <h6 className={`${step.isComplete ? "text_primary" : "text_secondary2"} line-clamp-1 plusJakara_regular`}>
                                                                {step.subtitle}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <IoIosCheckmarkCircle
                                                        size={34}
                                                        className={step.isComplete ? "text_primary" : "text_secondary2"}
                                                    />
                                                </div>
                                            </Col>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {orderData?.service && (
                                <div className="flex flex-col mb-4 gap-2 w-full">
                                    <h5 className="plusJakara_semibold whitespace-nowrap mb-0 text_primary">
                                        Available Cities:
                                    </h5>
                                    <div className="flex gap-2 w-full">
                                        <MdLocationOn className="text_secondary2" size={20} />
                                        <div className="flex gap-1 items-center w-full flex-wrap">
                                            {orderData?.service?.cities?.map((item, i) => (
                                                <h6
                                                    key={i}
                                                    className="text_secondary2 mb-0 plusJakara_medium"
                                                >
                                                    {item?.city}
                                                    {i < orderData?.service.cities.length - 1 && (
                                                        <span> ,</span>
                                                    )}
                                                </h6>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-col">
                                <h5 className="text_primary mb-3 plusJakara_semibold">
                                    Description
                                </h5>
                                <h6 className="text_secondary plusJakara_regular">
                                    {orderData?.estimate?.description ||
                                        orderData?.service?.description}
                                </h6>
                            </div>
                        </Col>
                    </div>
                    {/* <hr style={{ color: "#828282" }} /> */}
                    {orderData?.estimate ? (
                        <div className="flex mb-4 flex-col w-full gap-1">
                            <Label
                                className="form-label text_secondary2 plusJakara_medium"
                                htmlFor="scheduling"
                            >
                                Scheduling
                            </Label>
                            <div className="flex justify-between flex-wrap gap-3 w-full">
                                {orderData?.estimate?.labour?.map((category, index) => (
                                    <div
                                        key={index}
                                        className="w-full md:w-[48%] bg_light border rounded-3 px-3 py-2 flex justify-between items-center"
                                    >
                                        <div className="flex flex-col w-full gap-1">
                                            <h6 className="text_secondary2 mb-0 w-full text-sm plusJakara_semibold">
                                                LABOUR
                                            </h6>
                                            <h6 className="text_primary mb-0 w-full plusJakara_bold">
                                                {category?.labourName}
                                            </h6>
                                        </div>
                                        <div className="flex gap-2 items-center w-full justify-end">
                                            <span className="text_secondary2 text-sm whitespace-nowrap plusJakara_medium">
                                                {moment(category?.startDate).format("ddd DD MMM YYYY")}
                                            </span>
                                            <span className="text_black text-sm plusJakara_bold">
                                                TO
                                            </span>
                                            <span className="text_secondary2 text-sm whitespace-nowrap plusJakara_medium">
                                                {moment(category?.endDate).format("ddd DD MMM YYYY")}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                    {
                        orderData?.estimate && (
                            <LabourMaterialTableDesign
                                labour={orderData?.estimate?.labour}
                                material={orderData?.estimate?.material}
                                dis_price={orderData?.estimate?.dis_price}
                                tax={orderData?.estimate?.tax}
                            />
                        )
                    }
                </Container>
            )}
            <AssignEmployee
                setmodalOpen={setmodalOpen}
                modalOpen={modalOpen}
                assignUser={selectedItem}
            />

            <Modal
                centered
                open={modalOpen2}
                footer={null}
                width={900}
                zIndex={9999}
                onCancel={() => setmodalOpen2(false)}
            >
                <div
                    className="flex w-full gap-2 items-center flex-col"
                    style={{ maxHeight: "80vh", overflowY: "auto" }}
                >
                    <h4 className="text_primary text-center mb-4 plusJakara_bold">
                        Task Details
                    </h4>
                    <div className="flex flex-col gap-2 w-full mt-4">
                        <h5 className="text_primary plusJakara_semibold">Assigned Tasks</h5>
                        <DTable columns={columns3} data={orderData?.assign_users[0]?.task} />
                    </div>
                    <div className="flex flex-col gap-2 w-full mt-4">
                        <h5 className="text_primary plusJakara_semibold">Time Summary</h5>
                        <DTable columns={columns4} data={selectedEmployeeSummary} />
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                open={showReminder}
                footer={null}
                maskClosable={false}
                zIndex={9999}
                onCancel={() => setshowReminder(false)}
            >
                <div>
                    <h4 className="text-red-500 text-center plusJakara_semibold">
                        Reminder
                    </h4>
                    <h6 className="text_secondary2 text-center mb-4 plusJakara_medium">
                        Send reminder to Customer
                    </h6>
                    <Form
                        style={{ maxWidth: "600px", width: "100%" }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        className=" mt-4 auth_form"
                    >
                        <div className="flex flex-col gap-2 w-full">
                            <div className="my-3 px-0">
                                <Input
                                    type="textarea"
                                    className="form-control w-full rounded-3"
                                    name="reminder"
                                    id="reminder"
                                    rows='4'
                                    style={{ padding: "12px 16px" }}
                                    placeholder="Enter message..."
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.reminder || ""}
                                    invalid={
                                        validation.touched.reminder && validation.errors.reminder
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.reminder && validation.errors.reminder ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.reminder}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="d-grid w-full">
                                <button
                                    className="py-[12px] rounded-3 bg_primary plusJakara_medium text_white"
                                    disabled={isLoading}
                                    type="submit"
                                >
                                    {isLoading ? (
                                        <CircularProgress size={20} className="text_white" />
                                    ) : (
                                        "Send Reminder"
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </main>
    );
};

export default OrderDetail;
