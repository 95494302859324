/* eslint-disable no-unused-vars */
import React from 'react';
import { FaStar } from "react-icons/fa6";
import { FiHeart } from 'react-icons/fi';
import { SlLocationPin } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { cleaning5 } from '../icons/icon';

const ProposalItem = ({ category, showView = true }) => {
    const navigate = useNavigate()
    const categories = [1, 2, 3, 4, 5, 6];

    return (
        <div style={{ border: '0.83px solid #FFFFFF' }} className="bg_white border flex gap-3 p-3 flex-col xl:flex-row xl:items-center shadow-sm w-full rounded-4 overflow-hidden relative">
            <img src={cleaning5} onClick={() => navigate('/all-proposals/2345607965', { state: { proposalDetail: category } })} style={{ maxWidth: '12rem', maxHeight: "12rem", height: '100%', width: '100%', objectFit: 'cover', }} className='rounded-4 cursor-pointer' alt="" />
            <div className='flex gap-3 flex-col items-center w-full'>
                <div className="flex justify-between flex-wrap flex-lg-nowrap items-start w-full gap-1">
                    <div className="flex flex-col w-full gap-1">
                        <div onClick={() => navigate('/all-proposals/2345607965', { state: { proposalDetail: category } })} className="flex flex-col gap-1 cursor-pointer w-full">
                            <h5 className="plusJakara_semibold line-clamp-1 mb-0">Lanudry Services</h5>
                            <div className="flex gap-2 items-center">
                                <SlLocationPin />
                                <span className="text_secondary plusJakara_regular">1995 Broadway, New York</span>
                            </div>
                            {/* <div className="flex items-center gap-2">
                                <h6 className="text_black plusJakara_medium mb-0">4.5</h6>
                                <div className="flex gap-1 items-center">
                                    <FaStar color='#006666' size={16} />
                                    <FaStar color='#006666' size={16} />
                                    <FaStar color='#006666' size={16} />
                                    <FaStar color='#006666' size={16} />
                                    <FaStar color='#006666' size={16} />
                                </div>
                                <span className="text_black plusJakara_regular mb-0">(7 Reviews)</span>
                            </div> */}
                            <div className="flex flex-col my-2 w-full">
                                <span className="text_secondary text-sm plusJakara_regular">Time 09:00AM</span>
                                <span className="text_secondary text-sm plusJakara_regular">Date Wed, 20 May 2024</span>
                            </div>
                        </div>
                        <div className="flex gap-2 items-center justify-between w-full flex-wrap">
                            <div className="flex gap-2 my-2 items-center flex-wrap">
                                <h5 className="text_primary plusJakara_semibold">Waiting for Estimate</h5>
                            </div>
                            {/* <h5 className="text_black plusJakara_semibold mb-0">$3000</h5> */}
                        </div>
                    </div>
                    {/* <div className="w-auto flex flex-col items-end justify-between">
                        <button
                            className="flex justify-center items-center bg_light"
                            style={{ width: '40px', height: "40px", objectFit: 'cover', borderRadius: "50%" }}
                        >
                            <FiHeart className='text_secondary' size={20} />
                        </button>
                    </div> */}
                </div>
            </div>
        </div >
    )
}

export default ProposalItem