import React from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa6';
import { cleaning12 } from '../../icons/icon';
import { useNavigate } from 'react-router-dom';

const ShopServices = () => {
    const navigate = useNavigate()


    return (
        <div className='py-5'>
            <Container className='py-5'>
                <div className="grid2 w-100">
                    <div className="relative w-full">
                        <img src={cleaning12} className='relative' style={{ maxHeight: "650px", height: '100%', maxWidth: '500px', width: '100%', objectFit: "cover", borderRadius: '24px', zIndex: 2 }} alt="" />
                        <div className="absolute bg_lightprimary top-6 left-6" style={{ maxHeight: "650px", height: '100%', maxWidth: '500px', width: '100%', objectFit: "cover", borderRadius: '24px', zIndex: 1 }}></div>
                    </div>
                    <div className="flex flex-col gap-4 justify-start">
                        <div className="flex gap-2 justify-start flex-col w-full items-start">
                            <span className="plusJakara_semibold text-4xl lg:text-4xl text-center text_black">Looking to book a </span>
                            <span className="plusJakara_semibold text-4xl lg:text-4xl text_primary">fixed price Service</span>
                        </div>
                        <span className="plusJakara_regular text-lg text_lightsecondary">
                            Plumbing, Handyman, House Cleaning and More...
                        </span>
                        <div className="flex flex-col gap-3 w-full items-start">
                            <div className="flex gap-4 items-start justify-start">
                                <div style={{ marginTop: '12px' }} className='h-2 w-2 bg_primary rounded-full'></div>
                                <div className="flex flex-col gap-2 w-full items-start">
                                    <h4 className="text_primary mb-0 plusJakara_semibold">Request service</h4>
                                    <span className="plusJakara_regular text_lightsecondary">
                                        Easily request a customized cleaning service based on your home’s specific needs.
                                    </span>
                                </div>
                            </div>
                            <div className="flex gap-4 items-start justify-start">
                                <div style={{ marginTop: '12px' }} className='h-2 w-2 bg_primary rounded-full'></div>
                                <div className="flex flex-col gap-2 w-full items-start">
                                    <h4 className="text_primary mb-0 plusJakara_semibold">Book A Time</h4>
                                    <span className="plusJakara_regular text_lightsecondary">
                                        Schedule your preferred time for a professional cleaning service at your convenience.
                                    </span>
                                </div>
                            </div>
                            <div className="flex gap-4 items-start justify-start">
                                <div style={{ marginTop: '12px' }} className='h-2 w-2 bg_primary rounded-full'></div>
                                <div className="flex flex-col gap-2 w-full items-start">
                                    <h4 className="text_primary mb-0 plusJakara_semibold">Pay Online</h4>
                                    <span className="plusJakara_regular text_lightsecondary">
                                        Securely pay online for your home cleaning service with no hassle.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button onClick={() => navigate('/all-services')} className="bg_primary w-fit flex gap-3 items-center mt-3 px-5 ms-4 py-3 rounded-3 text_white plusJakara_medium">Shop Services <FaArrowRight size={20} /> </button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default ShopServices