/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import { apiRequest } from '../../api/auth_api';
import { CircularProgress } from '@mui/material';
import { message } from 'antd';
import { useFormik } from "formik";
import { Eye, EyeOff } from 'react-feather';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row
} from "reactstrap";
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import HeaderNavigation from './common/headerNavigation';
import { changePassword } from '../api/signup';


const ChangePassword = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showoldPassword, setShowoldPassword] = useState(false);
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setisLoading] = useState(false)
    const admindata = JSON.parse(window.localStorage.getItem('login_farevet_formData'))

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            oldPassword: '',
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string()
                .required("Please Enter Password"),
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .max(15, 'Maximum 15 characters allowed')
                .required("Please Enter Password"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Please confirm your password')
        }),

        onSubmit: async (values, { resetForm }) => {
            const data = {
                oldPassword: values.oldPassword,
                newPassword: values.password,
            };
            setisLoading(true);
            await changePassword({ data: data }).then((res) => {
                if (res?.success === true) {
                    setisLoading(false);
                    message.success('Password changed successfully');
                    navigate(-1)
                    resetForm()
                }
            }).catch((err) => {
                message.error(err?.response?.data?.message);
                setisLoading(false);
            })
        }
    });

    return (
        <main className={`${usertype !== 'customer' ? 'p-5' : 'pb-5'} w-full`}>
            {usertype === 'customer' &&
                <HeaderNavigation page='Profile' serviceName='Change Password' />}
            <Container className={`bg_white p-4 rounded-3 ${usertype === 'company' ? '' : 'mt-5'}`}>
                {usertype !== 'customer' && (
                    <div className="flex gap-4 items-center justify-between w-full mb-4">
                        <div className="flex flex-col gap-3 w-full">
                            <div className="flex gap-4 mb-3 items-center w-full">
                                <button
                                    onClick={() => navigate(-1)}
                                    className="bg_primary rounded-3 p-2"
                                >
                                    <FaArrowLeft className='text_white' />
                                </button>
                                <h4 className="text_primary mb-0 plusJakara_semibold">Change Password</h4>
                            </div>
                        </div>
                    </div>)}
                <div className='flex justify-center w-full flex-col items-center'>
                    <Form
                        style={{ maxWidth: '800px', width: '100%' }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        className=" mt-4 auth_form">
                        <Row>
                            <div className="mb-3 position-relative">
                                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="oldPassword">
                                    Current Password
                                </Label>
                                <Input
                                    type={showoldPassword ? "text" : "password"}
                                    className="form-control rounded-3 relative"
                                    name="oldPassword"
                                    id="oldPassword"
                                    style={{ padding: '12px 16px', }}
                                    // placeholder="Enter your Current Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.oldPassword || ""}
                                    invalid={
                                        validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                                    }
                                />
                                <span
                                    onClick={() => setShowoldPassword(!showoldPassword)}
                                    style={{
                                        position: 'absolute',
                                        right: '40px',
                                        top: '47px',
                                        zIndex: 99,
                                        cursor: "pointer"
                                    }}
                                >
                                    {!showoldPassword ? <EyeOff size={20} color="#C4C4C4" /> : <Eye size={20} color="#C4C4C4" />}
                                </span>
                                {validation.touched.oldPassword && validation.errors.oldPassword ? (
                                    <FormFeedback type="invalid">{validation.errors.oldPassword}</FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3 position-relative">
                                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="password">
                                    Password
                                </Label>
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control rounded-3 relative"
                                    name="password"
                                    id="password"
                                    style={{ padding: '12px 16px', }}
                                    // placeholder="Enter your New password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.password || ""}
                                    invalid={
                                        validation.touched.password && validation.errors.password ? true : false
                                    }
                                />
                                <span
                                    onClick={() => setShowPassword(!showPassword)}
                                    style={{
                                        position: 'absolute',
                                        right: '40px',
                                        top: '47px',
                                        zIndex: 99,
                                        cursor: "pointer"
                                    }}
                                >
                                    {!showPassword ? <EyeOff size={20} color="#C4C4C4" /> : <Eye size={20} color="#C4C4C4" />}
                                </span>
                                {validation.touched.password && validation.errors.password ? (
                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3 position-relative">
                                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="confirmPassword">
                                    Confirm Password
                                </Label>
                                <Input
                                    type={showConfirmPassword ? "text" : "password"}
                                    className="form-control rounded-3"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    style={{ padding: '12px 16px', }}
                                    // placeholder="Please Confirm your Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.confirmPassword || ""}
                                    invalid={
                                        validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                    }
                                />
                                <span
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    style={{
                                        position: 'absolute',
                                        right: '40px',
                                        top: '47px',
                                        zIndex: 99,
                                        cursor: "pointer"
                                    }}
                                >
                                    {!showConfirmPassword ? <EyeOff size={20} color="#C4C4C4" /> : <Eye size={20} color="#C4C4C4" />}
                                </span>
                                {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                    <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                                ) : null}
                            </div>


                            <Col lg={12} className="mb-0 mt-3">
                                <div className="d-grid">
                                    <button className="py-3 rounded-5 bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                                        {isLoading ? <CircularProgress size={18} style={{ color: '#fff' }} /> :
                                            "Change Password"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </main>
    )
}

export default ChangePassword