import Skeleton from '@mui/material/Skeleton';

const PostLoader2 = ({ loader = true }) => (
  <div className={`w-full ${loader ? 'gridDashboard' : 'overflow-hidden flex gap-3'} p-3`}>
    <div className={`flex flex-col gap-2 ${!loader ? 'w-[160px]' : 'w-full'}`}>
      <Skeleton variant="rectangular" className='rounded-lg' width={!loader && 150} height={200} />
      <div className="flex flex-col gap-1 w-full">
        <Skeleton height={20} />
        <Skeleton height={20} />
        <Skeleton width="60%" height={20} />
      </div>
    </div>
    <div className={`flex flex-col gap-2 ${!loader ? 'w-[160px]' : 'w-full'}`}>
      <Skeleton variant="rectangular" className='rounded-lg' width={!loader && 150} height={200} />
      <div className="flex flex-col gap-1 w-full">
        <Skeleton height={20} />
        <Skeleton height={20} />
        <Skeleton width="60%" height={20} />
      </div>
    </div>
    <div className={`flex flex-col gap-2 ${!loader ? 'w-[160px]' : 'w-full'}`}>
      <Skeleton variant="rectangular" className='rounded-lg' width={!loader && 150} height={200} />
      <div className="flex flex-col gap-1 w-full">
        <Skeleton height={20} />
        <Skeleton height={20} />
        <Skeleton width="60%" height={20} />
      </div>
    </div>
    <div className={`flex flex-col gap-2 ${!loader ? 'w-[160px]' : 'w-full'}`}>
      <Skeleton variant="rectangular" className='rounded-lg' width={!loader && 150} height={200} />
      <div className="flex flex-col gap-1 w-full">
        <Skeleton height={20} />
        <Skeleton height={20} />
        <Skeleton width="60%" height={20} />
      </div>
    </div>
  </div>
);

export default PostLoader2;
