import { Box, Disc, Home, Layers } from "react-feather";
import blog1 from "../assets/blog/01.jpg";
import blog2 from "../assets/blog/02.jpg";
import blog3 from "../assets/blog/03.jpg";
import blog4 from "../assets/blog/04.jpg";
import blog5 from "../assets/blog/05.jpg";
import blog6 from "../assets/blog/06.jpg";
import blog7 from "../assets/blog/07.jpg";
import blog8 from "../assets/blog/08.jpg";
import apple from '../assets/png/apple.png';
import appstore from '../assets/png/appstore.png';
import avatar1 from '../assets/png/avatar1.png';
import cleaningbg from '../assets/png/cleaningbg.png';
import cleaninglogo from '../assets/png/cleaninglogo.png';
import google from '../assets/png/google.png';
import mobles from '../assets/png/mobiles.png';
import continuelogo from '../assets/png/Or.png';
import playstore from '../assets/png/playstore.png';
import nopage from '../assets/png/error with text.png'
import work1 from '../assets/png/work1.png';
import work2 from '../assets/png/work2.png';
import work3 from '../assets/png/work3.png';
// import facebook from '../assets/png/facebook.png'
import applelogo from '../assets/png/applelogo.png';
import avatar from '../assets/png/avatar .png';
import cat1 from '../assets/png/cat1.png';
import chat from '../assets/png/chat.png';
import cleaning2 from '../assets/png/cleaning2.png';
import cleaning5 from '../assets/png/cleaning5.jpg';
import cleaningbg3 from '../assets/png/cleaningbg3.png';
import dots from '../assets/png/dots.png';
import facebook from '../assets/png/facebook .png';
import googlelogo from '../assets/png/googlelogo.png';
import instagram from '../assets/png/instagram.png';
import joinus from '../assets/png/joinus.png';
import linkedin from '../assets/png/linkedin.png';
import marker from '../assets/png/marker.png';
import notify from '../assets/png/notify.png';
import offer1 from '../assets/png/offer1.png';
import offer2 from '../assets/png/offer2.png';
import offer3 from '../assets/png/offer3.png';
import offer4 from '../assets/png/offer4.png';
import playstorelogo from '../assets/png/playstorelogo.png';
import secure from '../assets/png/secure.png';
import support from '../assets/png/support.png';
import twitter from '../assets/png/twitter.png';
import upload from '../assets/png/upload.png';
import verify from '../assets/png/verify.png';
import cleaning1 from '../assets/png/cleaning1.jpg';
import logout from '../assets/png/log out.png';
import providerlogo from '../assets/png/providerlogo.png';
import cleaning12 from '../assets/png/cleaning12.png';
import businessinvoice from '../assets/png/businessinvoice.png';
import businessmanagment from '../assets/png/businessmanagment.png';
import businessestimate from '../assets/png/businessestimate.png';
import businesscreation from '../assets/png/businesscreation.png';
import businessschedule from '../assets/png/businessschedule.png';
import businesscommunication from '../assets/png/businesscommunication.png';
import mapmarker from '../assets/png/mapmarker.png';
import labor1 from '../assets/png/labor1.png';
import labor2 from '../assets/png/labor2.png';
import material1 from '../assets/png/material1.png';
import material2 from '../assets/png/material2.png';
import avataruser from '../assets/png/avataruser.png';
import coveravatar from '../assets/png/coveravatar.png';

const menuItems = [
  {
    icon: <Home size={20} />,
    label: "Home",
    path: "/",
    exact: true,
  },
  {
    label: "Patient",
    icon: <Box size={20} />,
    submenu: [
      {
        label: "Patient List",
        path: "/list-patient",
        icon: <Disc size={20} />,
        exact: false,
      },
      {
        label: "Checked Patient",
        path: "/checked-patient",
        icon: <Disc size={20} />,
        exact: false,
      },
    ],
  },
  {
    label: "Patient Info",
    icon: <Layers size={20} />,
    submenu: [
      {
        label: "Manage Patient",
        icon: <Disc size={20} />,
        path: "/manage-patient",
        exact: false,
      },
    ],
  },

];


export {
  apple, applelogo, material1, coveravatar, material2, labor1, labor2, mapmarker, businesscommunication, businesscreation, businessestimate, businessinvoice, businessmanagment, businessschedule, appstore, cleaning12, avatar, avatar1, blog1, blog2, blog3, blog4, blog5, logout,
  blog6, blog7, providerlogo, nopage, cleaning1, avataruser,
  blog8, cat1, chat, cleaning2, cleaning5, cleaningbg, cleaningbg3, cleaninglogo, continuelogo, dots, facebook, google, googlelogo, instagram, joinus, linkedin, marker, menuItems, mobles, notify, offer1, offer2, offer3, offer4, playstore, playstorelogo, secure, support, twitter, upload, verify, work1, work2, work3
};
