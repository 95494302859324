/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { customerSideServices } from '../api/service';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
import Categories from './homeComponents2/categories';
import { useSocket } from './messages/socketProvider';
import ServiceItem2 from './serviceItem2';

const AllServices = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [services, setServices] = useState([])
    const socket = useSocket();

    useEffect(() => {
        if (socket) {
            socket.emit('seen-service', {});
        }
    }, [socket])

    const fetchServices = async () => {
        setLoading(true);
        await customerSideServices({ data: { category: selectedCategory?._id } })
            .then((res) => {
                setLoading(false);
                setServices(res.success ? res.services : []);
            })
            .catch(() => {
                setLoading(false);
                setServices([]);
            });
    };

    useEffect(() => {
        fetchServices();
    }, [selectedCategory]);

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Services' serviceName={`${selectedCategory ? selectedCategory?.name : 'All'} Services`} />
            <Categories onSelectCategory={setSelectedCategory} selectedCategory={selectedCategory} />
            <Container>
                <h6 className="text-3xl lg:text-4xl plusJakara_bold text_black">Fixed <span className="text-3xl lg:text-4xl plusJakara_bold text_primary"> Services</span> </h6>
                {loading ? (
                    <div className='my-5 flex justify-center w-full items-center'>
                        <CircularProgress size={24} className='text_primary' style={{ color: "#000" }} />
                    </div>) :
                    (!services || services.length === 0) ? (
                        <div className='flex justify-center items-center w-full my-5'>
                            <span className="plusJakara_medium md:text-lg">No Service Found</span>
                        </div>
                    ) : <div className="grid5 mb-3 py-4">
                        {(services.map((category, i) => (
                            <ServiceItem2 key={i} category={category} />
                        )))}
                    </div>}
            </Container>
        </ main>
    );
};

export default AllServices;
