/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Col, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../style/swiper.css';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { FaArrowLeft, FaLocationDot, FaPhone } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Label } from 'reactstrap';
import { createOrder, getSpeceficDetail } from '../api/order';
import HeaderNavigation from './common/headerNavigation';
import LabourMaterialTableDesign from './estimate/labourMaterialTableDesign';
import { MapClick } from '../common/mapClick';

const EstimateDetailProvider = () => {
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const usertype = useSelector((state) => state.auth?.userData?.type);
    const [estimateData, setEstimateData] = useState(state?.estimateData || null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!estimateData && id) {
            getSpeceficDetail('estimate', id)
                .then((res) => {
                    if (res?.estimate) {
                        setEstimateData(res.estimate);
                    }
                })
                .catch((err) => {
                    message.error(err.response.data.message);
                });
        }
    }, [id]);

    const handleSubmit = async (row) => {
        const data = {
            bookingDate: row?.date,
            to_id: row?.user?._id || row?.user,
            estimate: row?._id,
            type: (row?.user?.sub_type === 'free' || row?.user?.sub_type === 'basic') ? 'order' : (row?.totalPrice >= 1000 ? "contract" : "order"),
        };
        setIsLoading(true);
        try {
            const res = await createOrder({ data });
            if (res.success) {
                message.success('Order created successfully');
                navigate(-1);
            } else {
                message.error('Failed to create order');
            }
        } catch (err) {
            message.error(err.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <main className={`${usertype === 'company' ? 'p-5' : 'pb-5'} w-full`}>
            {usertype !== 'company' && (
                <HeaderNavigation
                    mainPage={'/estimates'}
                    subPage="Estimate Detail"
                    subPageAvail={true}
                    page="Estimates"
                    serviceName="House Cleaning Service"
                />
            )}
            {!estimateData ? (
                <div className="flex justify-center w-full items-center my-5">
                    <CircularProgress size={24} style={{ color: 'black' }} />
                </div>
            ) : (
                <Container fluid={usertype === 'company'} className="bg_white rounded-3 p-4 w-full">
                    <div className="flex gap-4 items-center justify-between w-full mb-4">
                        <div className="flex flex-col gap-3 w-full">
                            {usertype === 'company' && (
                                <div className="flex gap-4 mb-3 items-center w-full">
                                    <button onClick={() => navigate(-1)} className="bg_primary rounded-3 p-2">
                                        <FaArrowLeft className="text_white" />
                                    </button>
                                    <h4 className="text_primary mb-0 plusJakara_semibold">Estimate Detail</h4>
                                </div>
                            )}
                            <div className="flex gap-3 items-start w-full">
                                <img
                                    src={
                                        usertype !== 'customer'
                                            ? estimateData?.to_id?.profilePicture
                                            : estimateData?.user?.profilePicture
                                    }
                                    style={{ width: '120px', height: '100px', objectFit: 'cover', borderRadius: '12px' }}
                                    alt=""
                                />
                                <div className="flex flex-col w-full">
                                    <h5 className="text_black plusJakara_semibold">
                                        {usertype !== 'customer' ? estimateData?.to_id?.name : estimateData?.user?.name}
                                    </h5>
                                    <div className="flex gap-2 items-center">
                                        <FaLocationDot className="text_primary" />
                                        <span
                                            onClick={() =>
                                                MapClick(usertype !== 'customer' ? estimateData?.to_id?.location?.coordinates[1] : estimateData?.user?.location?.coordinates[1],
                                                    usertype !== 'customer' ? estimateData?.to_id?.location?.coordinates[0] : estimateData?.user?.location?.coordinates[0]
                                                )}
                                            className="text_secondary2 cursor-pointer hover:underline whitespace-nowrap plusJakara_medium">
                                            {usertype !== 'customer' ? estimateData?.to_id?.address : estimateData?.user?.address}
                                        </span>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <FaPhone className="text_primary" />
                                        <span className="text_secondary2 plusJakara_medium">
                                            {usertype !== 'customer' ? estimateData?.to_id?.phone : estimateData?.user?.phone}
                                        </span>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <MdEmail className="text_primary" />
                                        <span className="text_secondary2 plusJakara_medium">
                                            {usertype !== 'customer' ? estimateData?.to_id?.email : estimateData?.user?.email}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {usertype !== 'company' && (
                                <div className="flex gap-3 items-center justify-end w-full">
                                    <button
                                        disabled={isLoading}
                                        onClick={() => handleSubmit(estimateData)}
                                        style={{ maxWidth: '150px', width: '100%', padding: '10px 24px' }}
                                        className="bg_primary flex justify-center text_white rounded-3 plusJakara_medium"
                                    >
                                        {isLoading ? (
                                            <CircularProgress size={12} className="text_white" style={{ color: 'white' }} />
                                        ) : (
                                            'Confirm'
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <hr style={{ color: '#828282' }} />
                    <div className="flex mt-4 flex-wrap gap-3 flex-col flex-md-nowrap justify-between w-full">
                        <div className="flex gap-3 items-center justify-between w-full">
                            <Col xs={24} md={11} xl={14}>
                                <div className="flex justify-between gap-5 items-center flex-wrap">
                                    <div className="flex flex-col gap-2">
                                        <h6 className="text_secondary2 plusJakara_semibold">Estimate Status</h6>
                                        <div className="px-2 py-1 w-fit text-center rounded-2" style={{ backgroundColor: '#E6E9F4', color: '#5A607F' }}>
                                            <span className="plusJakara_bold">
                                                {usertype === 'company' ? 'Waiting for Customer Approval' : 'Pending'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <h6 className="text_secondary2 plusJakara_semibold">Estimate Start Date</h6>
                                        <span className="text_black plusJakara_bold">
                                            {moment(estimateData?.startDate).format('ddd DD MMM YYYY')}
                                        </span>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <h6 className="text_secondary2 plusJakara_semibold">Estimate End Date</h6>
                                        <span className="text_black plusJakara_bold">
                                            {moment(estimateData?.endDate).format('ddd DD MMM YYYY')}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </div>
                        <hr style={{ color: '#828282' }} />
                        <div className="flex flex-col mb-3">
                            <h5 className="text_primary plusJakara_semibold">Project Location</h5>
                            <div className="flex gap-2 items-center">
                                <FaLocationDot className="text_primary" />
                                <h6
                                    onClick={() =>
                                        MapClick(estimateData?.location?.lat,
                                            estimateData?.location?.lng
                                        )}
                                    className="text_secondary mb-0 cursor-pointer hover:underline text-sm plusJakara_medium">{(estimateData?.location?.address)}</h6>
                            </div>
                        </div>
                        <Col xs={24} md={11} xl={18}>
                            <div className="flex flex-col">
                                <h5 className="text_primary mb-3 plusJakara_semibold">Description</h5>
                                <h6 className="text_secondary plusJakara_regular">{estimateData?.description}</h6>
                            </div>
                        </Col>
                    </div>
                    <hr style={{ color: '#828282' }} />
                    <div className="flex mb-4 flex-col w-full gap-1">
                        <Label className="form-label text_secondary2 plusJakara_medium" htmlFor="scheduling">
                            Scheduling
                        </Label>
                        <div className="flex justify-between flex-wrap gap-3 w-full">
                            {estimateData?.labour?.map((category, index) => (
                                <div
                                    key={index}
                                    className="w-full md:w-[48%] bg_light border rounded-3 px-3 py-2 flex justify-between items-center"
                                >
                                    <div className="flex flex-col w-full gap-1">
                                        <h6 className="text_secondary2 mb-0 w-full text-sm plusJakara_semibold">LABOUR</h6>
                                        <h6 className="text_primary mb-0 w-full plusJakara_bold">{category.labourName}</h6>
                                    </div>
                                    <div className="flex gap-2 items-center w-full justify-end">
                                        <span className="text_secondary2 text-sm whitespace-nowrap plusJakara_medium">
                                            {moment(category?.startDate).format('DD MMM')}
                                        </span>
                                        -
                                        <span className="text_secondary2 text-sm whitespace-nowrap plusJakara_medium">
                                            {moment(category?.endDate).format('DD MMM')}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <hr style={{ color: '#828282' }} />
                    <LabourMaterialTableDesign
                        labour={estimateData?.labour}
                        material={estimateData?.material}
                        dis_price={estimateData?.dis_price}
                        tax={estimateData?.tax}
                    />
                </Container>
            )}
        </main>
    );
};

export default EstimateDetailProvider;