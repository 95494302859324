/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Container } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import { Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { Switch } from 'antd';
import { Modal, Select, Button } from 'antd';
import { FaFilter } from 'react-icons/fa6';
import { X } from 'react-feather';
import HeaderNavigation from './common/headerNavigation';
import ProviderPortfolioCard from './homeComponents2/providerPortfolioCard';
import PostLoader2 from './common/PostLoader2';
import { getProviders } from '../api/provider';
import { mapmarker } from '../icons/icon';
import { getCategory } from '../api/category';

const NearbyServices = () => {
    const { state } = useLocation()
    const libraries = ["places", "maps"];
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
        libraries: libraries,
    });

    const userData = useSelector((state) => state?.auth?.userData);
    const latlng = useSelector((state) => state?.auth?.latlng);
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [isPopular, setIsPopular] = useState(false);

    const [providers, setProviders] = useState([]);
    const [searchQuery, setSearchQuery] = useState(state?.searchQuery || '');
    const [coordinates, setCoordinates] = useState({
        lat: userData?.location?.coordinates?.[1] || latlng?.latitude,
        lng: userData?.location?.coordinates?.[0] || latlng?.longitude,
    });

    const [selectedCategoryId, setSelectedCategoryId] = useState(location.state?.category);
    const [selectedRating, setSelectedRating] = useState(null);
    const [selectedMapSearch, setSelectedMapSearch] = useState(coordinates);
    const [categories, setcategories] = useState([]);
    const navigate = useNavigate();

    const handleFetchCategories = async () => {
        await getCategory()
            .then((res) => {
                setcategories(res.success ? res.categories : []);
            })
            .catch((err) =>
                console.log(err)
            );
    };

    useEffect(() => {
        debouncedFetchProviders();
    }, [searchQuery]);

    useEffect(() => {
        handleFetchCategories();
    }, []);

    const fetchProviders = async () => {
        setLoading(true);
        const body = {
            category: selectedCategoryId,
            rating: selectedRating,
            searchQuery: searchQuery,
            lat: selectedMapSearch?.lat,
            lng: selectedMapSearch?.lng,
            popular: isPopular,
        };
        try {
            const res = await getProviders({ data: body });
            setProviders(res.success ? res.users : []);
        } catch (err) {
            setProviders([]);
        } finally {
            setLoading(false);
        }
    };

    const debouncedFetchProviders = useCallback(debounce(fetchProviders, 300), [searchQuery]);

    const handleClear = async () => {
        const res = await getProviders({ data: {} });
        setProviders(res.success ? res.users : []);
        setSelectedMapSearch(null)
        setSelectedRating(null)
        setSelectedCategoryId('');
        setSearchQuery('');
        setIsPopular(false)
        setCoordinates({})
        setSelectedRating(null);
    };

    useEffect(() => {
        fetchProviders();
    }, [searchQuery]);

    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setSelectedMapSearch({ lat: newLat, lng: newLng });
    };

    const handleSubmitFilters = () => {
        fetchProviders();
        setShow(false);
    };

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Nearby Service Providers' serviceName={`Service Providers`} />
            <Container className='py-5'>
                {isLoaded ? (
                    <div style={{ overflow: 'hidden', borderRadius: "20px" }}>
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '20rem' }}
                            center={coordinates}
                            zoom={16}
                        >
                            <MarkerF
                                icon={{
                                    url: mapmarker,
                                    scaledSize: new window.google.maps.Size(30, 30),
                                }}
                                position={selectedMapSearch}
                                draggable={true}
                                onDragEnd={handleMarkerDragEnd}
                            />
                        </GoogleMap>
                    </div>
                ) : null}

                <div className="flex flex-col gap-2 mt-5 w-full">
                    <div className="flex gap-2 items-center">
                        <span className="text_secondary2 text-sm plusJakara_regular mb-0">{providers.length || 0} results</span>
                    </div>
                    <div className="flex gap-3 items-center justify-start w-full">
                        <div className="flex gap-2 w-full items-center">
                            <div className="relative w-full" style={{ maxWidth: '500px', width: '100%' }}>
                                <div className="absolute z-10" style={{ padding: "14px" }}>
                                    <FiSearch size={20} />
                                </div>
                                <Input
                                    type="text"
                                    className="form-control w-fit relative rounded-3 text_secondary2 plusJakara_medium"
                                    style={{ padding: '12px 40px', maxWidth: '500px', width: '100%' }}
                                    placeholder="Search by name, city, state, etc"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                            <button style={{ padding: '12px 24px', }} className='flex gap-3 plusJakara_regular bg_primary text_white rounded-3 items-center' onClick={() => setShow(true)}>
                                <FaFilter style={{ width: "1.1rem" }} /> Filter
                            </button>
                        </div>
                        {(searchQuery || selectedCategoryId || selectedRating || isPopular) && (
                            <button className='flex gap-1 plusJakara_regular whitespace-nowrap text-sm border rounded-3 items-center py-2 px-3' onClick={handleClear}>
                                <X style={{ width: "1.1rem" }} /> Clear Filter
                            </button>
                        )}
                    </div>
                </div>

                {loading ? (
                    <PostLoader2 />
                ) : (
                    (!providers || providers.length === 0) ? (
                        <div className='flex flex-col my-5 items-center'>
                            <span className='plusJakara_medium text_secondary2 md:text-[16px]'>No Service provider Found</span>
                        </div>
                    ) : (
                        <div className="gridDashboard mt-5">
                            {providers.map((item, i) => (
                                <ProviderPortfolioCard category={item} key={i} />
                            ))}
                        </div>
                    )
                )}
            </Container>

            <Modal centered open={show} onCancel={() => setShow(false)} footer={null}>
                <h5 className='plusJakara_semibold mb-3 text_black'>Filter the Providers</h5>
                <div className="flex flex-col gap-3 w-full mt-3">
                    <div className="flex flex-col gap-1 w-full">
                        <h6 className='plusJakara_semibold mb-0 text_primary'>Drag the Market to set location</h6>
                        {isLoaded ? (
                            <div style={{ overflow: 'hidden', borderRadius: "20px" }}>
                                <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: '10rem' }}
                                    center={coordinates}
                                    zoom={16}
                                >
                                    <MarkerF
                                        icon={{
                                            url: mapmarker,
                                            scaledSize: new window.google.maps.Size(30, 30),
                                        }}
                                        position={selectedMapSearch}
                                        draggable={true}
                                        onDragEnd={handleMarkerDragEnd}
                                    />
                                </GoogleMap>
                            </div>
                        ) : null}
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <h6 className='plusJakara_semibold mb-0 text_primary'>Select Category</h6>
                        <Select
                            placeholder="Select Category"
                            className="w-full plusJakara_regular"
                            size='large'
                            onChange={(value) => setSelectedCategoryId(value)}
                            value={selectedCategoryId}
                        >
                            {categories.map((category) => (
                                <Select.Option value={category?._id}>{category?.name}</Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                        <h6 className='plusJakara_semibold mb-0 text_primary'>Select Rating</h6>
                        <Select
                            placeholder="Select Rating"
                            className="w-full plusJakara_regular"
                            size='large'
                            onChange={(value) => setSelectedRating(value)}
                            value={selectedRating}
                        >
                            {[1, 2, 3, 4, 5].map(rating => (
                                <Select.Option key={rating} value={rating}>{rating} Star</Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="flex justify-between items-center gap-1 w-full">
                        <h6 className='plusJakara_semibold mb-0 text_primary'>Popular Providers</h6>
                        <Switch
                            checked={isPopular}
                            onChange={(checked) => setIsPopular(checked)}
                            checkedChildren="Yes"
                            className='w-fit'
                            unCheckedChildren="No"
                        />
                    </div>
                    <div className="flex justify-end w-full mt-3">
                        <button className="px-4 text-sm bg_primary text_white rounded-2 plusJakara_medium py-2" type="primary" onClick={handleSubmitFilters}>Apply Filters</button>
                    </div>
                </div>
            </Modal>
        </main>
    );
};

export default NearbyServices;
