/* eslint-disable no-unused-vars */
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { MdMenu } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    avatar,
    chat,
    logout
} from "../icons/icon";
import NotificationDropdown from "../pages/common/NotificationDropdown";
import { setLogin, setUserData, setUserType } from "../redux/loginForm";
import { useSocket } from "../pages/messages/socketProvider";

const NavHeader = ({ broken, setToggled, toggled }) => {
    const navigate = useNavigate();
    const socket = useSocket()
    const [count, setcount] = useState(0)
    const pagesCount = useSelector((state) => state.services?.pagesCount);
    const usertype = useSelector((state) => state.auth?.type);
    const userData = useSelector((state) => state.auth?.userData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (socket) {
            socket.on("conversation-count", (data) => {
                setcount(data?.count)
            });
        }
    }, [socket])

    const handleLogout = () => {
        console.log('Logging out');
        dispatch(setLogin(false));
        dispatch(setUserType(''));
        dispatch(setUserData(null));
        message.success('Successfully logged out');
        navigate('/', { replace: true });
    };


    const menu = (
        <div className="py-1 bg_white border rounded flex flex-col items-center shadow-sm">
            <button
                style={{ padding: '8px 16px', minWidth: '130px' }} className="w-full text-start text_black border-b border-b-slate-300 hover:text-[#003F7D] plusJakara_medium">
                Profile
            </button>
            <button
                onClick={handleLogout}
                style={{ padding: '8px 16px', minWidth: '130px' }} className="w-full text-start text_black hover:text-[#003F7D] plusJakara_medium">
                Logout
            </button>
        </div>
    );

    return (
        <>
            <Navbar
                bg="white"
                expand="lg"
                sticky="top"
                className="p-3 shadow-sm w-[100%]"
                id="navbar"
            >
                <Container fluid className="w-full">
                    <div className="flex items-center gap-3 md:w-1/2">
                        {broken && (
                            <button
                                className="sb-button"
                                onClick={() => setToggled(!toggled)}
                            >
                                <MdMenu size={28} />
                            </button>
                        )}
                        <div onClick={() => navigate('/business-profile')} className="flex cursor-pointer gap-2 items-center w-fit">
                            <div style={{ minWidth: '45px' }}>
                                <img src={userData?.profilePicture || avatar} style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }} alt="" />
                            </div>
                            <div className="hidden sm:flex flex-col w-full">
                                <h5 className="plusJakara_semibold mb-0 text_black">Welcome,<span className="plusJakara_regular"> {userData?.name || 'Not Found'}</span></h5>
                            </div>
                        </div>
                    </div>
                    <Nav className="ms-auto me-4 flex">
                        <div className="flex gap-3 justify-center items-center">
                            <NotificationDropdown />
                            <button onClick={() => navigate('/chat')}>
                                <div className="relative">
                                    {pagesCount?.conversation > 0 && (
                                        <span
                                            className={`rounded-5 text-sm text_white ${pagesCount?.conversation > 9 ? 'px-1 py-[2px]' : 'px-[6px]'} plusJakara_medium`}
                                            style={{ position: 'absolute', zIndex: 99, top: '0px', right: '-4px', backgroundColor: '#003F7D' }}
                                        >
                                            {pagesCount?.conversation > 9 ? '+9' : pagesCount?.conversation}
                                        </span>
                                    )}
                                    <img src={chat} style={{ width: "45px", height: 'auto' }} className="relative" alt="" />
                                </div>
                            </button>
                            <button onClick={handleLogout}>
                                <img src={logout} style={{ height: '45px', width: '45px', borderRadius: '50a%' }} alt="" />
                            </button>
                        </div>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default NavHeader;
