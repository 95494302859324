/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import HeaderNavigation from './common/headerNavigation';
import { getStaticPage } from '../api/category';
import { CircularProgress } from '@mui/material';

const EstimateStatic = () => {
    const [loading, setLoading] = useState(false);
    const [privacy, setPrivacy] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await getStaticPage('EstimateStatic')
            if (res?.data) {
                setLoading(false);
                setPrivacy(res?.data?.EstimateStatic);
            } else {
                setLoading(false);
                setPrivacy([]);
            }
        }
        catch (error) {
            setLoading(false);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Estimate' serviceName='Estimate Instructions' />
            <Container className="my-5" fluid="xxl">
                {loading ? (
                    <main className="my-5 d-flex w-100 justify-content-center align-items-center">
                        <CircularProgress size={24} className="text_dark" />
                    </main>
                ) : !privacy || privacy.length === 0 ? (
                    <main className="my-5 d-flex w-100 justify-content-center align-items-center">
                        <span className="text_secondary plusJakara_medium">No Content Found</span>
                    </main>
                ) : (
                    <div className="bg_white rounded-3 p-4 ">
                        <div className="plusJakara_regular" dangerouslySetInnerHTML={{ __html: privacy?.des }}></div>
                    </div>
                )}
            </Container>
        </main>
    );
}

export default EstimateStatic;
