/* eslint-disable no-unused-vars */
import { Col, message, Row } from 'antd';
import html2pdf from 'html2pdf.js';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa6';
import { PiMapPinLineFill } from "react-icons/pi";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { updateContractSign } from '../../api/estimateApi';
import { uploadFile } from '../../api/uploadFile';
import '../../style/main.css';
import LabourMaterialTableDesign from '../estimate/labourMaterialTableDesign';
import HeaderNavigation from './headerNavigation';
import { MapClick } from '../../common/mapClick';

const ViewContract = ({ showButtons = true }) => {
    const { state } = useLocation();
    const viewContract = state?.viewContract || null;
    const additionalKey = state?.additionalKey || null;
    const [signatureBase64, setSignatureBase64] = useState(null)
    const [signatureBase642, setSignatureBase642] = useState(null)
    const navigate = useNavigate();
    const usertype = useSelector((state) => state.auth?.userData?.type);
    const [loading, setLoading] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageUrl2, setImageUrl2] = useState(null);
    const userData = useSelector((state) => state.auth?.userData);
    const sigCanvas = useRef({});
    const sigCanvas2 = useRef({});

    const renderSignatureSection = (name, email, phone, address, role) => (
        <div className="flex flex-col gap-3 h-full items-start w-full">
            <div className="border flex justify-center w-full items-center p-4">
                <div className="flex flex-col gap-1 items-center justify-center w-full">
                    <h6 className="text_primary mb-0 plusJakara_semibold">{name}</h6>
                    <h6 className="text_secondary mb-0 whitespace-nowrap plusJakara_medium">{phone}</h6>
                    <h6 className="text_secondary mb-0 whitespace-nowrap plusJakara_medium">{email}</h6>
                    <h6 className="text_secondary mb-0 text-center plusJakara_medium">{address}</h6>
                    <span className="text_secondary whitespace-nowrap plusJakara_regular">(the "{role === 'customer' ? 'Client' : 'Contractor'}")</span>
                </div>
            </div>
        </div>
    );

    const saveSignature = async () => {
        // if (!signatureBase64) {
        //     message.warning('Please upload a signature before Create Contract.');
        //     return;
        // } else {
        if (!sigCanvas.current.isEmpty()) {
            const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            setSignatureBase64(dataURL); // Storing the Base64 image
            const blob = await (await fetch(dataURL)).blob(); // Converting Base64 to Blob
            const formData = new FormData();
            formData.append('image', blob, 'signature.png');
            setFileLoading(true);
            try {
                const res = await uploadFile({ data: formData });
                if (res.image) {
                    setImageUrl(res.image); // Setting the uploaded image URL
                    message.success('Signature uploaded successfully!');
                }
                setFileLoading(false);
            } catch (error) {
                message.error('Failed to upload signature');
                setFileLoading(false);
            }
        } else {
            message.warning('Please provide a signature.');
        }
        // }
    };
    const saveSignature2 = async () => {
        if (!sigCanvas2.current.isEmpty()) {
            const dataURL = sigCanvas2.current.getTrimmedCanvas().toDataURL('image/png');
            setSignatureBase642(dataURL);
            const blob = await (await fetch(dataURL)).blob();
            const formData = new FormData();
            formData.append('image', blob, 'signature.png');
            setFileLoading(true);
            try {
                const res = await uploadFile({ data: formData });
                if (res.image) {
                    setImageUrl2(res.image);
                    message.success('Signature uploaded successfully!');
                }
                setFileLoading(false);
            } catch (error) {
                message.error('Failed to upload signature');
                setFileLoading(false);
            }
        } else {
            message.warning('Please provide a signature.');
        }
    };

    const handleCreateContract = async () => {
        const data = {
            sign_employee: imageUrl,
            sign_customer: imageUrl2,
        }
        setLoading(true)
        await updateContractSign({ data: data }, viewContract?._id, usertype === 'customer' ? 'customer' : 'empolyee')
            .then((res) => {
                if (res.success === true) {
                    setLoading(false)
                    message.success("Contract signed successfully");
                    navigate('/contracts');
                } else {
                    setLoading(false)
                    message.error("Failed to sign contract");
                }
            }).catch((err) => {
                message.error(err.response.data.message)
                setLoading(false)
            })
    }

    useEffect(() => {
        if (viewContract?.sign_employee) {
            setImageUrl(viewContract?.sign_employee)
        }
        if (viewContract?.sign_customer) {
            setImageUrl2(viewContract?.sign_customer)
        }
    }, [viewContract])


    const downloadInvoiceAsPDF = () => {
        const invoiceContent = document.getElementById('invoice-content');
        const options = {
            filename: `Contract_${viewContract?.order_id || 'download'}.pdf`,
            jsPDF: { format: 'a4' },
            html2canvas: { scale: 2 },
        };
        html2pdf().set(options).from(invoiceContent).save();
    };

    return (
        <main className={`${usertype === 'company' ? 'p-5' : 'pb-5'} w-full`}>
            {usertype !== 'company' &&
                <HeaderNavigation mainPage={'/contracts'} subPage={`Service Agreement`} subPageAvail={true} page={`Contract`} serviceName='Service Agreement' />}
            <Container fluid={usertype === 'company'} className='bg_white rounded-3 p-4 w-full'>
                <div className="flex justify-between w-full items-center">
                    <div className="flex flex-col gap-3 w-full">
                        {usertype === 'company' &&
                            <>
                                <div className="flex gap-4 mb-3 items-center w-full">
                                    <button
                                        onClick={() => navigate(-1)}
                                        className="bg_primary rounded-3 p-2"
                                    >
                                        <FaArrowLeft className='text_white' />
                                    </button>
                                    <h4 className="text_primary mb-0 plusJakara_semibold">Service Agreement</h4>
                                </div>
                            </>
                        }
                    </div>
                    <button onClick={downloadInvoiceAsPDF} style={{ maxWidth: '240px', width: '100%' }} className="bg_primary rounded-5 px-3 whitespace-nowrap py-[12px] plusJakara_medium text_white">
                        Download Contract
                    </button>
                </div>
                <hr style={{ color: "#828282" }} />
                <div id="invoice-content" className='p-4'>
                    <div className="flex mt-4 flex-wrap gap-3 flex-col flex-md-nowrap justify-between w-full">
                        <div className="flex flex-col w-full mb-4">
                            <h5 className="text_primary mb-1 plusJakara_semibold">GENERAL SERVICE AGREEMENT</h5>
                            <span className="text_secondary whitespace-nowrap plusJakara_regular">
                                The GENERAL SERVICE AGREEMENT (the "Agreement") is dated this day 20, Aug of Monday.
                            </span>
                        </div>

                        <Row gutter={16}>
                            {usertype === 'company' ?
                                <>
                                    <Col xs={24} sm={12} lg={8}>
                                        {renderSignatureSection(viewContract?.user?.name, viewContract?.user?.email, viewContract?.user?.phone, viewContract?.user?.address, 'customer')}
                                    </Col>
                                    <Col xs={24} sm={12} lg={8}>
                                        {renderSignatureSection(userData?.name, userData?.email, userData?.phone, userData?.address, 'provider')}
                                    </Col>
                                </>
                                : <>
                                    <Col xs={24} sm={12} lg={8}>
                                        {renderSignatureSection(userData?.name, userData?.email, userData?.phone, userData?.address, 'customer')}
                                    </Col>
                                    <Col xs={24} sm={12} lg={8}>
                                        {renderSignatureSection(viewContract?.to_id?.name, viewContract?.to_id?.email, viewContract?.to_id?.phone, viewContract?.to_id?.address, 'provider')}
                                    </Col>
                                </>}
                        </Row>
                        <hr style={{ color: "#828282" }} />
                        <div className="flex gap-2 items-center">
                            <PiMapPinLineFill className='text_primary' size={20} />
                            <span
                                onClick={() =>
                                    MapClick((usertype === 'company' ? viewContract?.user?.location?.coordinates[1] : viewContract?.to_id?.location?.coordinates[1] || viewContract?.estimate?.location?.lat),
                                        (usertype === 'company' ? viewContract?.user?.location?.coordinates[0] : viewContract?.to_id?.location?.coordinates[0] || viewContract?.estimate?.location?.lng)
                                    )}
                                className="text_secondary cursor-pointer hover:underline plusJakara_regular">{viewContract?.address || viewContract?.estimate?.location?.address}</span>
                        </div>
                        <div className="flex flex-col gap-1 w-full">
                            <h5 className="text_primary mb-0 plusJakara_semibold">Description</h5>
                            <p className="text_secondary plusJakara_regular">
                                {viewContract?.description || viewContract?.estimate?.description}
                            </p>
                        </div>
                        <LabourMaterialTableDesign
                            labour={viewContract?.estimate?.labour}
                            material={viewContract?.estimate?.material}
                            dis_price={viewContract?.estimate?.dis_price}
                            tax={viewContract?.estimate?.tax}
                        />
                        <hr style={{ color: "#828282" }} />
                        <h6 className="text_secondary plusJakara_regular" style={{ lineHeight: 1.5 }}>This contract outlines the terms and conditions for professional home cleaning services provided by <span className="plusJakara_semibold text_primary">{userData?.name}</span> to the Client at the specified residence. The services include routine cleaning tasks such as dusting, vacuuming, mopping, bathroom sanitation, and kitchen cleaning. Additional services like window cleaning, deep cleaning, and appliance care can be arranged upon request for an additional fee.

                            The cleaning schedule will be agreed upon by both parties and may occur weekly, Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sunt modi dolorum voluptate illo, facilis perferendis veniam alias vel quod, illum numquam? Provident, consequuntur. Ab repudiandae minus harum exercitationem, beatae molestiae. bi-weekly, or monthly depending on the Client’s preference. <br />  <br /> Services will be provided on the agreed dates between [start time] and [end time], with flexibility for minor adjustments. Both the Service Provider and the Client are expected to maintain open communication in case of any changes to the schedule or service requirements.

                            Payment for services is due upon completion or as otherwise agreed in advance, such as through a monthly billing system. <br /> <br /> The Client agrees to pay [agreed amount] per cleaning session or [total sum] for a recurring schedule. Payment can be made via cash, credit card, or bank transfer. A late fee of [percentage/amount] may be applied to any payment not received within [X] days of the due date.

                            Both parties agree to maintain a safe working environment. The Client is responsible for securing valuables, while the Service Provider will ensure staff follow all safety and privacy protocols. Any dissatisfaction with the service must be communicated within 24 hours of service completion, allowing the Service Provider the opportunity to resolve the issue promptly.</h6>
                    </div>
                    <Row gutter={16} className="w-full justify-between items-end my-4">
                        <Col xs={24} md={12}>
                            <h5 className="text_primary mb-3 plusJakara_semibold">
                                {signatureBase642 && 'Create'} Signature
                            </h5>
                            <div className="flex items-end w-full justify-between">
                                <div className="flex flex-col gap-2 items-center">
                                    {imageUrl2 ?
                                        <img src={imageUrl2} style={{ width: '120px', height: 'auto' }} alt="" />
                                        :
                                        usertype === 'customer' &&
                                        <SignatureCanvas
                                            ref={sigCanvas2}
                                            penColor="green"
                                            canvasProps={{ width: 200, height: 100, className: 'sigCanvas2' }}
                                        />}
                                    <div style={{ width: '150px', height: '1px', backgroundColor: '#000', }}></div>
                                    <h6 className="text_secondary mb-0 whitespace-nowrap plusJakara_regular">(the "Client")</h6>
                                </div>
                                <div className="flex flex-col gap-2 items-center">
                                    {imageUrl ?
                                        <img src={imageUrl} style={{ width: '120px', height: 'auto' }} alt="" />
                                        :
                                        usertype === 'company' &&
                                        <SignatureCanvas
                                            ref={sigCanvas}
                                            penColor="green"
                                            canvasProps={{ width: 200, height: 100, className: 'sigCanvas' }}
                                        />}
                                    <div style={{ width: '150px', height: '1px', backgroundColor: '#000', }}></div>
                                    <h6 className="text_secondary mb-0 whitespace-nowrap plusJakara_regular">(the "Contractor")</h6>
                                </div>
                            </div>
                        </Col>
                        {!additionalKey && (
                            <Col xs={24} md={12} className="flex justify-end w-full">
                                {usertype === 'company' ? (
                                    !signatureBase64 ? (
                                        <button
                                            onClick={saveSignature}
                                            style={{ minWidth: '150px', padding: '12px' }}
                                            className="bg_primary h-fit rounded-3 bg_primary text_white plusJakara_medium"
                                        >
                                            Save Signature
                                        </button>
                                    ) : (
                                        <button
                                            onClick={handleCreateContract}
                                            disabled={loading || fileLoading}
                                            style={{ minWidth: '150px', padding: '12px' }}
                                            className="bg_primary h-fit rounded-3 bg_primary text_white plusJakara_medium"
                                        >
                                            {loading ? <Spinner size="sm" color="#fff" /> : 'Submit Contract'}
                                        </button>
                                    )
                                ) : (
                                    !signatureBase642 ? (
                                        <button
                                            onClick={saveSignature2}
                                            style={{ minWidth: '150px', padding: '12px' }}
                                            className="bg_primary h-fit rounded-3 bg_primary text_white plusJakara_medium"
                                        >
                                            Save Signature
                                        </button>
                                    ) : (
                                        <button
                                            onClick={handleCreateContract}
                                            disabled={loading || fileLoading}
                                            style={{ minWidth: '150px', padding: '12px' }}
                                            className="bg_primary h-fit rounded-3 bg_primary text_white plusJakara_medium"
                                        >
                                            {loading ? <Spinner size="sm" color="#fff" /> : 'Submit Contract'}
                                        </button>
                                    )
                                )}
                            </Col>
                        )}
                    </Row>
                </div>
            </Container>
        </main >
    );
};

export default ViewContract;
