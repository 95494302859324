/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa6';
import { IoLocationSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getProviders } from '../../api/provider';
import { avatar1, cleaning2 } from '../../icons/icon';
import { encryptData } from '../../redux/localStorageSecure';
import '../../style/swiper.css';
import ProviderPortfolioCard from './providerPortfolioCard';

const ProviderPortfolio = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const userData = useSelector((state) => state?.auth?.userData)
    const [loading, setloading] = useState([])
    const [providers, setProviders] = useState([])

    const fetchproviders = async () => {
        setloading(true)
        const body = {
            lat: userData?.location?.coordinates?.[1],
            lng: userData?.location?.coordinates?.[0],
        };
        await getProviders({ data: {} })
            .then((res) => {
                if (res.success === true) {
                    setProviders(res?.users);
                    setloading(false)
                } else {
                    setloading(false)
                    setProviders([])
                }
            }).catch((err) => {
                setloading(false)
                setProviders([])
            })
    }

    useEffect(() => {
        fetchproviders()
    }, [])

    const handleNavigate = (item) => {
        const params = new URLSearchParams(searchParams)
        const data = {
            user: item,
        }
        params.set('detail-user', encryptData(data))
        navigate(`/chat?${params.toString()}`)
    }

    return (
        <Container className='pb-5 pt-4'>
            {loading ? (
                <div className='my-5 flex justify-center w-full items-center'>
                    <CircularProgress size={24} className='text_primary' style={{ color: "#000" }} />
                </div>) :
                (!providers || providers.length === 0) ? (
                    <div className='flex justify-center items-center w-full my-5'>
                        <span className="plusJakara_medium md:text-lg">No Service Provider Found</span>
                    </div>
                ) :
                    <div className="items_swiper w-full relative">
                        <div className="flex justify-between absolute -top-10 w-full flex-wrap gap-3 items-start">
                            <h6 className="text-3xl lg:text-4xl mb-0 plusJakara_bold text_black">Service Providers <br /> <h6 className="text-2xl lg:text-3xl plusJakara_bold text_primary"> Portfolio</h6> </h6>
                            <button onClick={() => navigate('/nearby-services')} className="text_black text-xl mt-2 plusJakara_medium">See all</button>
                        </div>
                        <Swiper
                            freeMode={true}
                            modules={[FreeMode, Autoplay, Navigation, Pagination]}
                            // navigation={true}
                            pagination={{ clickable: true }}
                            className="mySwiper d-flex w-100 gap-3 relative"
                            slidesPerView={"auto"}
                        >
                            {providers?.map((category, i) => (
                                <SwiperSlide
                                    key={i}
                                    style={{ maxWidth: '25rem', height: 'auto', }}
                                    className='pe-4 relative cursor-pointer gap-2'
                                >
                                    <ProviderPortfolioCard category={category} key={i} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>}
        </Container >
    );
};

export default ProviderPortfolio;