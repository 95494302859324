/* eslint-disable no-unused-vars */
import { Col, message, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Container } from 'react-bootstrap';
import { TiLocation } from "react-icons/ti";
import { useLocation, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { avatar1, marker } from '../icons/icon';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';


const ProposalDetail = ({ showButtons = true }) => {
    const { state } = useLocation()
    const staticLat = 40.730610;
    const staticLng = -73.935242;
    const [modalOpen, setmodalOpen] = useState(false)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
    });
    const categories = [1, 2, 3, 4];
    const navigate = useNavigate()

    const handleCancel = () => {
        message.success('Estimate confirmed Successfully')
        setmodalOpen(false)
        navigate(-1)
    }
    const handleCancel2 = () => {
        message.error('Estimate Cancelled Successfully')
        setmodalOpen(false)
        navigate(-1)
    }
    const handleConfirm = () => {
        message.success('Contract sent Successfully')
        setmodalOpen(false)
        navigate(-1)
    }

    return (
        <main className='pb-5'>
            <HeaderNavigation subPage={`Proposal Detail`} subPageAvail={true} page={`Proposal`} serviceName='House Cleaning Service' />
            <Container className='py-5'>
                <div className="flex mb-5 flex-wrap gap-3 flex-md-nowrap justify-start w-full">
                    <Col xs={24} md={11} xl={8}>
                        <img src={avatar1} className='rounded-5' style={{ width: '400px', height: "250px", objectFit: 'cover' }} alt="" />
                    </Col>
                    <Col xs={24} md={11} xl={16} className="flex gap-3 flex-col w-full justify-start">
                        <h3 className="plusJakara_semibold text_primary line-clamp-1 mb-0">House Cleaning Service</h3>
                        <h4 className="plusJakara_semibold line-clamp-1 mb-0">$ 120</h4>
                        <div className="flex gap-2 mb-3 items-center">
                            <TiLocation size={24} className='text_primary' />
                            <h6 className="text_secondary2 mb-0 plusJakara_regular">1995 Broadway, New York</h6>
                        </div>
                        <div style={{ backgroundColor: '#F8ECDF', color: '#FF8E00' }} className="rounded-5 plusJakara_regular w-fit px-2 py-1 flex justify-center items-center">E#001</div>
                    </Col>
                </div>
                <Row gutter={16} className='justify-around gap-4'>
                    <Col xs={24} lg={10}>
                        <div className="flex justify-between mb-4 items-center w-full">
                            <div className="flex flex-col">
                                <h6 className="text_secondary2 plusJakara_regular">Date</h6>
                                <h5 className="text_dark plusJakara_medium">May 20, 2024</h5>
                            </div>
                            <div className="flex flex-col">
                                <h6 className="text_secondary2 plusJakara_regular">Time</h6>
                                <h5 className="text_dark plusJakara_medium">09:00 AM</h5>
                            </div>
                        </div>
                        <div className="flex flex-col mb-4 gap-2">
                            <h5 className="text_primary plusJakara_semibold">Description</h5>
                            <h6 className="text_secondary plusJakara_regular">Our Deep Cleaning Service provides an extensive and thorough cleaning of your home or office. We go beyond regular cleaning to ensure every nook and cranny is spotless. </h6>
                        </div>
                        <div className="flex flex-col mb-4 gap-2">
                            <h5 className="text_primary plusJakara_semibold">Labour Details</h5>
                            <div className="w-full rounded-2 bg_light overflow-hidden"
                                style={{ border: '0.5px solid #DAE1E9' }}>
                                <table className="w-full table-auto overflow-auto">
                                    <thead>
                                        <tr className='' style={{ borderBottom: '1px solid #DAE1E9' }}>
                                            <th style={{ padding: '10px' }}>Name</th>
                                            <th style={{ padding: '10px' }}>Unit</th>
                                            <th style={{ padding: '10px' }}>Price</th>
                                            <th style={{ padding: '10px' }}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categories?.map((item, key) => (
                                            <tr key={key} style={{ borderBottom: '1px solid #DAE1E9' }}>
                                                <td style={{ padding: '10px' }}>{item?.name || 'abuzar'}</td>
                                                <td style={{ padding: '10px' }}>{item?.unit || '1'}</td>
                                                <td style={{ padding: '10px' }}>{item?.price || '$5'}</td>
                                                <td style={{ padding: '10px' }}>{item?.total || '$5'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-col mb-4 gap-2">
                            <h5 className="text_primary plusJakara_semibold">Material Details</h5>
                            <div className="w-full rounded-2 bg_light overflow-hidden"
                                style={{ border: '0.5px solid #DAE1E9' }}>
                                <table className="w-full table-auto overflow-auto">
                                    <thead>
                                        <tr className='' style={{ borderBottom: '1px solid #DAE1E9' }}>
                                            <th style={{ padding: '10px' }}>Paint</th>
                                            <th style={{ padding: '10px' }}>Sement</th>
                                            <th style={{ padding: '10px' }}>Sanitary</th>
                                            <th style={{ padding: '10px' }}>Bulb</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categories?.map((item, key) => (
                                            <tr key={key} style={{ borderBottom: '1px solid #DAE1E9' }}>
                                                <td style={{ padding: '10px' }}>{item?.name || '$5'}</td>
                                                <td style={{ padding: '10px' }}>{item?.unit || '$5'}</td>
                                                <td style={{ padding: '10px' }}>{item?.price || '$5'}</td>
                                                <td style={{ padding: '10px' }}>{item?.total || '$5'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="bg_light border rounded-2 p-3 flex justify-between w-full items-center gap-2">
                            <h6 className="mb-0 text_dark plusJakara_medium">Total Price</h6>
                            <h6 className="mb-0 text_primary plusJakara_medium">$ 50</h6>
                        </div>
                    </Col>
                    <Col xs={24} lg={10}>
                        <div className="flex flex-col mb-5 gap-3 w-full">
                            <h5 className="text_primary plusJakara_semibold">Employee Location</h5>
                            {isLoaded ? (
                                <div style={{ overflow: 'hidden', borderRadius: "20px", width: '100%' }}>
                                    <GoogleMap
                                        mapContainerStyle={{ width: '100%', height: '15rem' }}
                                        center={{
                                            lat: staticLat,
                                            lng: staticLng,
                                        }}
                                        zoom={16}
                                        apiKey={process.env.REACT_APP_APIKEY}
                                    >
                                        <MarkerF
                                            icon={{
                                                url: marker,
                                                scaledSize: new window.google.maps.Size(35, 35),
                                                className: 'custom-marker-icon',
                                            }}
                                            position={{ lat: staticLat, lng: staticLng }}
                                        />
                                    </GoogleMap>
                                </div>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </Container>


            <Modal
                centered
                open={modalOpen}
                footer={null}
                closeIcon={false}
                width={400}
                onCancel={() => setmodalOpen(false)}
            >
                <div className="flex w-full gap-2 items-center flex-col">
                    <h4 className="text_primary text-center mb-0 plusJakara_bold">Create Contract</h4>
                    <h5 className="text_secondary2 text-center plusJakara_regular">Do you want to create the Contract for this estimate</h5>
                    <div className="flex gap-3 mt-2 items-center justify-between w-full">
                        <button
                            onClick={handleCancel}
                            style={{ border: '2.06px solid #003F7D' }}
                            className="py-3 rounded-5 w-full bg_white plusJakara_medium text_primary"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleConfirm}
                            className="py-3 rounded-5 w-full bg_primary plusJakara_medium text_white"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>

        </ main >
    );
};
export default ProposalDetail;
