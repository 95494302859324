/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { ThemeProvider } from 'react-bootstrap';
import { useGeolocated } from 'react-geolocated';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './App.css';
import './app.scss';
import UserLayout from './components/layout/layout';
import { useSocket } from './components/pages/messages/socketProvider';
import { setCategories, setPagesCount } from './components/redux/services';
import Routing from './components/routes/routes';
import SidebarMenu from './components/sidebar/sidebar';
import './components/style/main.css';
import { getCurrentLatLng, setIsMobileLogin, setUserData, setUserType } from './components/redux/loginForm';
import { getCategory } from './components/api/category';

function App() {
  const location = useLocation();
  const socket = useSocket();
  const dispatch = useDispatch(null);
  const [toggled, setToggled] = useState(false);
  const [typeFromMobile, settypeFromMobile] = useState('')
  const [broken, setBroken] = useState(false);
  const theme = useSelector((state) => state.themeDart.theme);
  const usertype = useSelector((state) => state.auth?.userData?.type)
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  useEffect(() => {
    if (socket) {
      socket.on('badge-counts', (data) => {
        dispatch(setPagesCount(data))
      });
    }
  }, [socket])

  useEffect(() => {
    if (socket) {
      socket.on("update-user", (data) => {
        dispatch(setUserData(data?.user));
      });
    }
  }, [socket]);

  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
  });

  useEffect(() => {
    if (type) {
      settypeFromMobile(type)
      dispatch(setIsMobileLogin(true));
      dispatch(setUserType(type));
    }
  }, [type]);

  useEffect(() => {
    if (coords?.latitude && coords?.longitude) {
      dispatch(getCurrentLatLng(coords))
    }
  }, [coords]);

  const handleFetchCategories = async () => {
    await getCategory()
      .then((res) => {
        if (res.success === true) {
          dispatch(setCategories(res.categories))
        }
      })
      .catch((err) => {
      })
  };

  useEffect(() => {
    handleFetchCategories()
  }, [])

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', theme);
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    global.BASEURL = `http://192.168.18.134:5200/api`;
    global.BASEURL2 = `http://192.168.18.134:5200`;
    global.TOKEN = window.localStorage.getItem('react_template_token');
    window.scroll(0, 0);
  }, [location]);

  return (
    <ThemeProvider theme={{ mode: theme }}>
      {(usertype === 'company' || usertype === 'employee') && (
        <SidebarMenu toggled={toggled} setBroken={setBroken} broken={broken} setToggled={setToggled}>
          <UserLayout toggled={toggled} setBroken={setBroken} broken={broken} setToggled={setToggled}>
            <Routing />
          </UserLayout>
        </SidebarMenu>
      )}
      {usertype !== 'company' && usertype !== 'employee' && (
        <UserLayout>
          <Routing />
        </UserLayout>
      )}
    </ThemeProvider>
  );
}

export default App;
