import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../style/swiper.css';
import ServiceItem2 from '../serviceItem2';
import { customerSideServices } from '../../api/service';
import { CircularProgress } from '@mui/material';

const HomeServices = () => {
    const navigate = useNavigate()
    const [loading, setloading] = useState([])
    const [services, setservices] = useState([])

    const fetchServices = async () => {
        setloading(true)
        await customerSideServices({ data: {} })
            .then((res) => {
                if (res.success === true) {
                    setservices(res.services)
                    setloading(false)
                } else {
                    setloading(false)
                    setservices([])
                }
            }).catch((err) => {
                setloading(false)
                setservices([])
            })
    }

    useEffect(() => {
        fetchServices()
    }, [])

    return (
        <Container className='pb-5'>
            <div className="flex justify-between w-full flex-wrap gap-3 items-center">
                <h6 className="text-3xl lg:text-4xl mb-0 plusJakara_bold text_black">Fixed <span className="text-3xl lg:text-4xl plusJakara_bold text_primary"> Services</span> </h6>
                <button onClick={() => navigate('/all-services')} className="flex gap-1 text_primary2 items-center mb-0 text-xl hover:text-[#003F7D] transition-all plusJakara_medium">
                    <span className="plusJakara_medium">Explore More</span>
                    <MdOutlineKeyboardArrowRight size={24} />
                </button>
            </div>
            {loading ? (
                <div className='my-5 flex justify-center w-full items-center'>
                    <CircularProgress size={24} className='text_primary' style={{ color: "#000" }} />
                </div>) :
                (!services || services.length === 0) ? (
                    <div className='flex justify-center items-center w-full my-5'>
                        <span className="plusJakara_medium md:text-lg">No Service Found</span>
                    </div>
                ) : <div className="grid5 mb-3 py-4">
                    {(services.map((category, i) => (
                        <ServiceItem2 key={i} category={category} />
                    )))}
                </div>}
        </Container >
    );
};

export default HomeServices;
