import axiosInstance from "../redux/axiosInstance";

export const getProviders = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`users/nearBy/companies`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

