/* eslint-disable no-unused-vars */
import moment from 'moment';
import React from 'react';
import { IoIosTimer } from 'react-icons/io';
import { IoPricetagOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const BlogItem = ({ category, showView = true }) => {
    const navigate = useNavigate()

    return (
        <div onClick={() => navigate(`/all-blogs/${category?._id}`, { state: { blogData: category } })} className="bg_white flex flex-col w-full rounded-2 overflow-hidden border relative">
            <div className="relative overflow-hidden">
                <img src={category?.image} style={{ height: '12rem', cursor: 'pointer', width: '100%', objectFit: 'cover' }} className='relative img_hover' alt="" />
            </div>
            <div
                className='p-3 flex gap-2 flex-col w-full'>
                <div className="flex items-center justify-between w-full">
                    <div className="flex gap-1 items-center">
                        <IoIosTimer className='text_secondary2' />
                        <span className="text_secondary2 plusJakara_regular">{moment(category?.createdAt).format('ddd DD MMM YYYY')}</span>
                    </div>
                    <div className="flex gap-1 items-center">
                        <IoPricetagOutline className='text_secondary2' />
                        <span className="text_secondary2 plusJakara_regular">{category?.cat?.name}</span>
                    </div>
                </div>
                <div className="flex flex-col">
                    <h5 className="plusJakara_semibold mb-4 transition-all cursor-pointer hover:text-[#F6921E] line-clamp-2">{category?.title}</h5>
                    <span className="text_secondary2 line-clamp-2" dangerouslySetInnerHTML={{ __html: category?.description }} />
                </div>
            </div>
        </div>
    )
}

export default BlogItem