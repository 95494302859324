/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row
} from "reactstrap";

import { useFormik } from "formik";
import { Spinner } from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { cleaninglogo } from "../icons/icon";
import { setCode, setUserData } from "../redux/loginForm";
import { message } from "antd";
import { checkEmail, checkPhone, sendCode } from "../api/signup";

const SignupPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const usertype = useSelector((state) => state.auth?.type)
  const [isLoading, setisLoading] = useState(false)
  const [phone, setphone] = useState('')
  const [phoneMessage, setphoneMessage] = useState('')
  const [invalidPhone, setinvalidPhone] = useState(false)
  const [email, setemail] = useState('')
  const [emailMessage, setemailMessage] = useState('')
  const [invalidEmail, setinvalidEmail] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: "",
      // lastName: "",
      // email: "",
      password: "",
      confirmPassword: "",
      // phone: ""
    },
    validationSchema: Yup.object({
      fullName:
        Yup.string("Enter your Full Name")
          .required("Full Name is required")
          .min('4', 'Full Name must be at least 4 characters'),
      // lastName: Yup.string("Enter your Last Name").required("Last Name is required"),
      // email: Yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
      // phone: Yup.string()
      //   .matches(/^\+?[0-9]{10,15}$/, "Phone number is not valid")
      //   .min(10, "Phone number must be at least 10 digits")
      //   .max(15, "Phone number must be at most 15 digits")
      //   .required("Phone number is required"),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(15, 'Maximum 15 characters allowed')
        .required("Please Enter Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your password')
    }),

    onSubmit: async (values, { resetForm }) => {
      const data = {
        email: email,
        password: values.password,
        name: values.fullName,
        phone: '+' + phone,
      };

      // let emailValid = true;
      // let phoneValid = true;

      // // Validate email
      // try {
      //   const emailRes = await checkEmail(data.email);
      //   if (!emailRes.success) {
      //     validation.setFieldError('email', 'Email Already Exists');
      //     emailValid = false;
      //   }
      // } catch (err) {
      //   validation.setFieldError('email', 'Email Already Exists');
      //   emailValid = false;
      // }

      // // Validate phone
      // try {
      //   const phoneRes = await checkPhone(data.phone);
      //   if (!phoneRes.success) {
      //     validation.setFieldError('phone', 'Phone Already Exists');
      //     phoneValid = false;
      //   }
      // } catch (err) {
      //   validation.setFieldError('phone', 'Phone Already Exists');
      //   phoneValid = false;
      // }
      // if (emailValid && phoneValid) {
      try {
        setisLoading(true);
        const sendCodeRes = await sendCode(data.phone);
        if (sendCodeRes.status === 200) {
          message.success(sendCodeRes.data.message);
          dispatch(setCode(sendCodeRes.data?.verificationCode));
          dispatch(setUserData(data));
          navigate('/code-verify', { replace: true });
        }
      } catch (err) {
        message.error('Error sending code: ' + err.message);
      } finally {
        setisLoading(false);
      }
      // }
    }
  });

  const emailChange = (e) => {
    const email = e.target.value.trim();
    setemail(email);
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setinvalidEmail(true);
      setemailMessage('Please enter a valid email format');
    } else {
      setemail(email)
    }
  }

  const handleEmailBlur = async (e) => {
    validation.handleBlur(e);
    const email = e.target.value.trim();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email) {
      setinvalidEmail(true)
      setemailMessage('Email must not be empty')
      return;
    } else if (!emailPattern.test(email)) {
      setinvalidEmail(true);
      setemailMessage('Please enter a valid email format');
      return;
    } else {
      try {
        const res = await checkEmail(email);
        if (res.success === true) {
          setinvalidEmail(false)
          setemail(email)
        } else {
          setinvalidEmail(true)
          setemailMessage('Email already exists')
          setemail(email)
          return
        }
      } catch (err) {
        setinvalidEmail(true)
        setemailMessage('Email already exists')
        setemail(email)
        return
      }
    };
  };

  const handlePhoneBlur = async (e) => {
    const phone = e.target.value.replace(/[^0-9]/g, '');
    if (!phone || phone.length < 8) {
      setinvalidPhone(true);
      setphoneMessage(phone ? 'Phone number must be at least 8 digits' : 'Please enter your phone number');
      return;
    } else {
      try {
        const res = await checkPhone('+' + phone);
        if (res.success === true) {
          setinvalidPhone(false);
          setphone(phone);
        } else {
          setinvalidPhone(true);
          setphoneMessage('Phone already exists');
          setphone(phone);
          return;
        }
      } catch (err) {
        setinvalidPhone(true);
        setphoneMessage('Phone already exists');
        setphone(phone);
        return;
      }
    }
  };

  return (
    <React.Fragment>
      <Container fluid style={{ backgroundColor: '#F5F5F5', paddingTop: "6rem", paddingBottom: "2rem" }} className="flex auth_bg_img min-vh-100 justify-center items-center w-full">
        <Card className="bg_white rounded-3 max-w-2xl d-flex align-items-center flex-column pt-3 pb-5 max-lg:px-8 lg:px-20">
          <img src={cleaninglogo} style={{ height: '120px', width: 'auto' }} alt="" />
          <h1 className="plusJakara_semibold text-2xl lg:text-3xl text-center text_primary">Join CleaningWeb Team!</h1>
          <h6 className="text_secondary2 max-w-lg plusJakara_regular text-center">We're thrilled to have you here. Our platform is designed to help you manage and promote your services efficiently.  </h6>
          <Form
            style={{ maxWidth: '600px', width: '100%' }}
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            className=" mt-4 auth_form">
            <Row>
              <div className="mb-3">
                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="fullName">
                  Full Name {usertype === 'company' && ' / Business Name'}
                </Label>
                <Input
                  type="text"
                  className="form-control rounded-3 text_secondary2 plusJakara_medium"
                  name="fullName"
                  id="fullName"
                  style={{ padding: '12px 16px', }}
                  placeholder={usertype === 'company' ? 'Business Name' : "Full Name"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.fullName || ""}
                  invalid={
                    validation.touched.fullName && validation.errors.fullName ? true : false
                  }
                />
                {validation.touched.fullName && validation.errors.fullName ? (
                  <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                ) : null}
              </div>
              <div className="w-100 mb-3">
                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="phone">
                  Phone Number
                </Label>
                <div className="d-flex tab-content w-full justify-content-center flex-column align-items-center gap-1">
                  <PhoneInput
                    country={"us"}
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(value) => setphone(value)}
                    onBlur={handlePhoneBlur}
                    className="phon_inp text_secondarydark inter_medium"
                    disableSearchIcon={true}
                  />
                  {invalidPhone ? (
                    <FormFeedback type="invalid" className="d-block">{phoneMessage}</FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="email">
                  Email
                </Label>
                <Input
                  type="text"
                  className="form-control rounded-3"
                  name="email"
                  id="email"
                  style={{ padding: '12px 16px', }}
                  placeholder="Enter your Email"
                  onChange={emailChange}
                  onBlur={handleEmailBlur}
                  value={email}
                  invalid={invalidEmail}
                />
                {invalidEmail ? (
                  <FormFeedback type="invalid">{emailMessage}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3 position-relative">
                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="password">
                  Password
                </Label>
                <Input
                  type={showPassword ? "text" : "password"}
                  className="form-control rounded-3 relative"
                  name="password"
                  id="password"
                  style={{ padding: '12px 16px', }}
                  placeholder="Enter your password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password ? true : false
                  }
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'absolute',
                    right: '40px',
                    top: '47px',
                    zIndex: 99,
                    cursor: "pointer"
                  }}
                >
                  {!showPassword ? <EyeOff size={20} color="#C4C4C4" /> : <Eye size={20} color="#C4C4C4" />}
                </span>
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                ) : null}
              </div>

              <div className="mb-3 position-relative">
                <Label className="form-label text_secondary2 plusJakara_regular" htmlFor="confirmPassword">
                  Confirm Password
                </Label>
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control rounded-3"
                  name="confirmPassword"
                  id="confirmPassword"
                  style={{ padding: '12px 16px', }}
                  placeholder="Please Confirm your Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirmPassword || ""}
                  invalid={
                    validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                  }
                />
                <span
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{
                    position: 'absolute',
                    right: '40px',
                    top: '47px',
                    zIndex: 99,
                    cursor: "pointer"
                  }}
                >
                  {!showConfirmPassword ? <EyeOff size={20} color="#C4C4C4" /> : <Eye size={20} color="#C4C4C4" />}
                </span>
                {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                  <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                ) : null}
              </div>


              <Col lg={12} className="mb-0 mt-3">
                <div className="d-grid">
                  <button className="py-3 rounded-5 bg_primary plusJakara_medium text_white" disabled={isLoading || invalidEmail || invalidPhone} type="submit">
                    {isLoading ? <Spinner size="sm"></Spinner> :
                      "Sign Up"}
                  </button>
                </div>
              </Col>

              <Col className="text-center mt-3">
                <div className="flex gap-1 justify-center items-center w-full">
                  <h6 className="text_primary mb-0">
                    You already have an account?
                  </h6>{" "}
                  <Link
                    to="/login"
                    className="text_primary2 mb-0 plusJakara_medium"
                  >
                    Sign In
                  </Link>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container >
    </React.Fragment >
  );
};
export default SignupPage;
