import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../style/swiper.css';
import BlogItem from '../blogItem';
import { getBlogs } from '../../api/blogs';
import PostLoader2 from '../common/PostLoader2';

const Blogs = () => {
    const navigate = useNavigate()
    const [blogData, setblogData] = useState([])
    const [loading, setloading] = useState(false)

    useEffect(() => {
        setloading(true)
        const fetchData = async () => {
            await getBlogs('all', 1)
                .then((res) => {
                    if (res) {
                        setloading(false)
                        setblogData(res?.blogs);
                    } else {
                        setloading(false)
                        setblogData([])
                    }
                }).catch((err) => {
                    setloading(false)
                    console.log(err);
                })
        }
        fetchData();
    }, [])

    return (
        <Container className='py-5'>
            <div className="flex justify-between w-full flex-wrap gap-3 items-center">
                <h6 className="text-3xl lg:text-4xl mb-0 plusJakara_semibold text_black">Read our daily Blogs</h6>
                <button onClick={() => navigate('/all-blogs')} className="flex gap-1 text_primary2 items-center mb-0 text-xl hover:text-[#003F7D] transition-all plusJakara_medium">
                    <span className="plusJakara_medium">Explore More</span>
                    <MdOutlineKeyboardArrowRight size={24} />
                </button>
            </div>
            {loading ? (
                <PostLoader2 />
            ) :
                (!blogData || blogData.length === 0) ? (
                    <div className='flex flex-col my-5 items-center'>
                        <span className='plusJakara_medium text_secondary2 md:text-[16px]'>No Blog Found</span>
                    </div>
                ) : (
                    <div className="grid5 mb-3 py-5">
                        {blogData?.map((category, i) => (
                            <BlogItem category={category} />
                        ))}
                    </div>
                )}
        </Container>
    );
};

export default Blogs;
