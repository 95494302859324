import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Autoplay, FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import '../../style/swiper.css';
import CategoryItem from '../categoryItem';
import { getCategory } from '../../api/category';
import { CircularProgress } from '@mui/material';

const Categories = ({ onSelectCategory, selectedCategory, navigateOnCategoryClick }) => {
    const [loading, setloading] = useState(false);
    const [categories, setcategories] = useState([]);
    const navigate = useNavigate();

    const handleFetchCategories = async () => {
        setloading(true);
        await getCategory()
            .then((res) => {
                setloading(false);
                setcategories(res.success ? res.categories : []);
            })
            .catch(() => setloading(false));
    };

    const handleCategoryClick = (category) => {
        onSelectCategory(category);
        if (navigateOnCategoryClick) {
            navigate('/nearby-services', { state: { category: category._id } });
        }
    };

    useEffect(() => {
        handleFetchCategories();
    }, []);

    return (
        <Container style={{ paddingTop: '2rem' }}>
            {loading ? (
                <div className='my-5 flex justify-center w-full items-center'>
                    <CircularProgress size={24} className='text_primary' style={{ color: "#000" }} />
                </div>
            ) : (
                categories.length === 0 ? (
                    <div className='flex justify-center items-center w-full my-5'>
                        <span className="plusJakara_medium md:text-lg">No Category Found</span>
                    </div>
                ) : (
                    <div className="items_swiper p-2 relative">
                        <h6 className="text-2xl lg:text-3xl w-fit absolute mb-0 plusJakara_bold text_primary">Categories</h6>
                        <Swiper
                            freeMode={true}
                            modules={[FreeMode, Autoplay, Navigation]}
                            navigation={true}
                            pagination={{ clickable: true }}
                            className="mySwiper d-flex w-100 gap-3 relative"
                            slidesPerView={"auto"}
                        >
                            {categories.map((category) => (
                                <SwiperSlide
                                    key={category._id}
                                    onClick={() => handleCategoryClick(category)}
                                    style={{ maxWidth: '10rem', height: 'auto' }}
                                    className='pe-4 relative gap-2'
                                >
                                    <CategoryItem selectedCategory={selectedCategory} category={category} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                )
            )}
        </Container>
    );
};

export default Categories;
